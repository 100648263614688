import Vue from 'vue';
import axios from 'axios';
import router from '../router/index.js';
import Vuelidate from 'vuelidate';
import VueMq from 'vue-mq';
import VueScrollTo from 'vue-scrollto';
import VueAwesomeSwiper from 'vue-awesome-swiper';
// import VueAnalytics from 'vue-analytics'

// ****************************************
// ページコンポーネント
// ****************************************
import App from './App.vue';

// ****************************************
// プラグイン
// ****************************************
// axios
Vue.prototype.$axios = axios;
// vuelidate
Vue.use(Vuelidate);
// mq
Vue.use(VueMq, {
    breakpoints: {
        sp: 600,
        spl: 769,
        tb: 1025,
        pc: Infinity,
    },
    defaultBreakpoint: 'sp',
});
// vue scroll to
Vue.use(VueScrollTo, {
    offset: -40,
});
// vue awesome swiper
Vue.use(VueAwesomeSwiper);
// プロダクションヒント
Vue.config.productionTip = false;

// ****************************************
// ルートコンポーネントの登録
// ****************************************
new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
