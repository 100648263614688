import firebase from 'firebase/app';
import 'firebase/firebase-functions';

// DB接続情報（プロジェクトで発行された情報）
var config = {
    apiKey: 'AIzaSyCTTRbuoraHiUoUiasPXwcVTYjNFT3ElJc',
    authDomain: 'agri-flyer.firebaseapp.com',
    projectId: 'agri-flyer',
    storageBucket: 'agri-flyer.appspot.com',
    messagingSenderId: '550394645525',
    appId: '1:550394645525:web:4910984ce1a09acf67921c',
    measurementId: 'G-QXYHL9H39K',
};
// Firestore をインスタンス化
firebase.initializeApp(config);
export const functions = firebase.functions();
