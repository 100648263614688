var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("section", { staticClass: "content-box" }, [
      _c("div", { staticClass: "content-box_inner" }, [
        _c("div", { staticClass: "heading" }, [
          _c("div", { staticClass: "heading_inner" }, [
            _vm.$mq === "pc"
              ? _c("div", { staticClass: "heading_title-sub" }, [
                  _c("object", {
                    attrs: { data: "static/img/img_pagetitle-spec.svg" }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._m(0)
          ])
        ]),
        _vm._v(" "),
        _vm._m(1)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading_title" }, [
      _c("p", [
        _vm._v(
          "\n                            スペック\n                        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spec" }, [
      _c("div", { staticClass: "spec_inner" }, [
        _c("div", { staticClass: "lead" }, [
          _c("p", { staticClass: "lead_text--01" }, [
            _vm._v(
              "\n                            自動車開発で培った高い技術力が"
            ),
            _c("br"),
            _vm._v(
              "かつてない高性能・高耐久性を実現しました\n                        "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "lead_text--02" }, [
            _vm._v(
              "\n                            Agri Flyerは農水協の認定機体です\n                        "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "images" }, [
          _c("div", { staticClass: "images_img" }, [
            _c("object", {
              attrs: { data: "static/img/img_aflogo-simbol-nega.svg" }
            })
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }