var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "section",
      { staticClass: "content-box content-box--01", attrs: { id: "01" } },
      [
        _c("div", { staticClass: "heading" }, [
          _c("h2", { staticClass: "shade" }, [
            _vm._v(
              "\n                「スマート農業」で省力化と生産性の向上を実現。"
            ),
            _vm.$mq === "tb" || _vm.$mq === "pc" ? _c("br") : _vm._e(),
            _vm._v(
              "\n                政府主導での実証実験スタート\n            "
            )
          ])
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "heading spacer" }, [
          _c("h3", [
            _vm._v("\n                「スマート農業」実現のための"),
            _vm.$mq === "sp" || _vm.$mq === "spl" || _vm.$mq === "tb"
              ? _c("br")
              : _vm._e(),
            _vm._v("先端技術の開発と実装\n            ")
          ])
        ]),
        _vm._v(" "),
        _vm.$mq === "sp" || _vm.$mq === "spl"
          ? _c("div", { staticClass: "images--02_img" }, [
              _c("object", {
                attrs: {
                  type: "image/svg+xml",
                  data: "static/img/chart_arrow-sp.svg"
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$mq === "tb" || _vm.$mq === "pc"
          ? _c("div", { staticClass: "images--02_img spacer" }, [
              _c("object", {
                attrs: {
                  type: "image/svg+xml",
                  data: "static/img/chart_arrow-wide.svg"
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "heading" }, [
          _c("h4", [
            _c("span", { staticClass: "halftone" }, [
              _vm._v("政府主導での実証実験の骨子では")
            ]),
            _vm.$mq === "tb" || _vm.$mq === "pc" ? _c("br") : _vm._e(),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "“要素技術を生産から出荷まで体系的に組み立て一気通貫での技術実証を実現。"
              ),
              _vm.$mq === "tb" || _vm.$mq === "pc" ? _c("br") : _vm._e(),
              _vm._v(
                "\n                    速やかな現場への普及までを総合的に推進する”"
              )
            ]),
            _c("br"),
            _c("span", { staticClass: "halftone" }, [_vm._v("としています")])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("section", { staticClass: "content-box content-box--01" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "row-box--stretch spacer" }, [
        _vm.$mq === "sp" || _vm.$mq === "spl"
          ? _c("div", { staticClass: "images--02_img" }, [
              _c("object", {
                attrs: {
                  type: "image/svg+xml",
                  data: "static/img/chart_5years-sp.svg"
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$mq === "tb" || _vm.$mq === "pc"
          ? _c("div", { staticClass: "images--02_img" }, [
              _c("object", {
                attrs: {
                  type: "image/svg+xml",
                  data: "static/img/chart_5years.svg"
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(2)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row-box--stretch mg-top04" }, [
        _vm._m(3),
        _vm._v(" "),
        _vm.$mq === "sp" || _vm.$mq === "spl"
          ? _c("div", { staticClass: "images--02_img" }, [
              _c("object", {
                attrs: {
                  type: "image/svg+xml",
                  data: "static/img/chart_market-sp.svg"
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$mq === "tb" || _vm.$mq === "pc"
          ? _c("div", { staticClass: "images--02_img" }, [
              _c("object", {
                attrs: {
                  type: "image/svg+xml",
                  data: "static/img/chart_market-wide.svg"
                }
              })
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "compare mg-top04" }, [
        _c("h3", [_vm._v("比較してみよう")]),
        _vm._v(" "),
        _c("div", { staticClass: "row-box--stretch" }, [
          _c("div", { staticClass: "col-box" }, [
            _c("div", { staticClass: "index blue" }, [
              _vm._v(
                "\n                        散布用ドローン\n                    "
              )
            ]),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _c("ul", { staticClass: "plus" }, [
              _c("li", [
                _c("span", [
                  _vm._v("機体の値段が安い"),
                  _vm.$mq === "tb" ? _c("br") : _vm._e(),
                  _vm._v("（自費で購入可能）")
                ])
              ]),
              _vm._v(" "),
              _vm._m(5),
              _vm._v(" "),
              _vm._m(6),
              _vm._v(" "),
              _vm._m(7),
              _vm._v(" "),
              _vm._m(8),
              _vm._v(" "),
              _vm._m(9),
              _vm._v(" "),
              _vm._m(10)
            ]),
            _vm._v(" "),
            _vm._m(11)
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-box" }, [
            _c("div", { staticClass: "index gray" }, [
              _vm._v(
                "\n                        散布用ヘリコプター\n                    "
              )
            ]),
            _vm._v(" "),
            _vm._m(12),
            _vm._v(" "),
            _vm._m(13),
            _vm._v(" "),
            _c("ul", { staticClass: "minus" }, [
              _c("li", [_vm._v("操作に熟練が必要")]),
              _vm._v(" "),
              _vm._m(14),
              _vm._v(" "),
              _vm._m(15),
              _vm._v(" "),
              _c("li", [_vm._v("飛行騒音が大きい")]),
              _vm._v(" "),
              _c("li", [_vm._v("狭い圃場には難しい")]),
              _vm._v(" "),
              _c("li", [
                _vm._v("機体が大型で"),
                _vm.$mq === "tb" ? _c("br") : _vm._e(),
                _vm._v("重量があるため人手が必要")
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "quote-box wide spacer" }, [
          _vm._m(16),
          _vm._v("\n                どちらが得か？はケースによるものの、"),
          _vm.$mq === "pc" ? _c("br") : _vm._e(),
          _c("span", [_vm._v("圃場面積5ha以上")]),
          _vm._v("への散布を考えた場合、"),
          _vm.$mq === "pc" ? _c("br") : _vm._e(),
          _c("span", [_vm._v("ドローン導入")]),
          _vm._v("の"),
          _c("span", [_vm._v("メリットが大きい")]),
          _vm._v("とされています！\n                "),
          _vm._m(17)
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "content-box content-box--01" }, [
      _vm._m(18),
      _vm._v(" "),
      _vm._m(19),
      _vm._v(" "),
      _vm._m(20),
      _vm._v(" "),
      _vm._m(21),
      _vm._v(" "),
      _vm._m(22),
      _vm._v(" "),
      _c("div", { staticClass: "row-box tmbup-box" }, [
        _vm._m(23),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n                アグリフライヤーのように、安価で高性能、"),
          _vm.$mq === "pc" ? _c("br") : _vm._e(),
          _vm._v("高耐久性を持ったドローンの登場により、"),
          _vm.$mq === "pc" ? _c("br") : _vm._e(),
          _vm._v("今後大幅な散布面積の増加が見込まれています！\n            ")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "description" }, [
        _vm._v(
          "\n            無人航空機による散布面積は、22年度から総面積を伸ばしてはいるものの、その変化は比較的緩やかです。"
        ),
        _vm.$mq === "pc" ? _c("br") : _vm._e(),
        _vm._v(
          "\n            また、作業別の延面積を見ても、29年度は大幅に増加しているものの、日本全国を合算しても、未だ1万haとなっています。"
        ),
        _vm.$mq === "pc" ? _c("br") : _vm._e(),
        _vm._v(
          "\n            しかし、安価で高性能なドローンの普及が、これらに大きな変化をもたらすことを期待されています。\n        "
        )
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "content-box content-box--01" }, [
      _vm._m(24),
      _vm._v(" "),
      _c("div", { staticClass: "row-box--stretch spacer" }, [
        _vm.$mq === "sp" || _vm.$mq === "spl"
          ? _c("div", { staticClass: "images--02_img" }, [
              _c("object", {
                attrs: {
                  type: "image/svg+xml",
                  data: "static/img/chart_outlook-sp.svg"
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$mq === "tb" || _vm.$mq === "pc"
          ? _c("div", { staticClass: "images--02_img" }, [
              _c("object", {
                attrs: {
                  type: "image/svg+xml",
                  data: "static/img/chart_outlook.svg"
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(25)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row-box tmbup-box" }, [
        _vm._m(26),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                登録台数・オペレータの認定者数は２０１７年から２０１８年にかけて３倍に増加。"
          ),
          _vm.$mq === "pc" ? _c("br") : _vm._e(),
          _vm._v(
            "ドローンでの散布の需要は今後、大きく増加すると考えられます！\n            "
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-box--between" }, [
      _c("div", { staticClass: "images--02_img fullwidth" }, [
        _c("object", {
          staticClass: "fullwidth",
          attrs: {
            type: "image/svg+xml",
            data: "static/img/chart_sfarm-illust.svg"
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "quote-box col-box" }, [
        _c("div", { staticClass: "images--pos_left sp" }, [
          _c("object", {
            attrs: {
              type: "image/svg+xml",
              data: "static/img/img_quotation-start-sp.svg"
            }
          })
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                    最先端のロボット技術や人工知能（AI）を活用する「スマート（賢い）農業」の実用化に向けた動きが加速している。政府は2019年度から国内約50か所のモデル農場で、自動運転トラクターを使って畑を耕すなどの実証実験に乗り出す。農家の高齢化や担い手不足が深刻化する中、農業再生の切り札になるかが注目される。（2019.2.3\n                    読売新聞より）\n                "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "images--pos_right" }, [
          _c("object", {
            attrs: {
              type: "image/svg+xml",
              data: "static/img/img_quotation-end-sp.svg"
            }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading_section" }, [
      _c("h2", [_vm._v("防除の現状と今後の展望　〜その１〜")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-box col-article single-right" }, [
      _c("h3", [_vm._v("５年後にはドローンによる空散が主流に")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                    5年後には、現在主流となる散布用ヘリの利用は大きくその比率を変えないものの、人がタンクを背負っての散布、地上散布車による散布が大きく減るものと予想されます。これは、農家の高齢化の影響、運転技術の担い手が不足することに起因する変化と考えることができます。\n                "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                    散布用ドローンは、肉体的な負担を軽減し、また機体操作のみで散布は半自動で行うため、大きくそのシェアを伸ばし、面積比にして半分のシェアまで拡大するものと予想されています。\n                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-box col-article single-left" }, [
      _c("h3", [_vm._v("散布用ヘリからドローンの利用へ")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "散布用ドローンの市場規模も同様に大きく成長するものと予想されています。"
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                    機体・サービスを含む経済的な市場規模として、2024年には5,073億円までに成長し、機体の登録台数もその数を3,500台を越えて伸ばす見込みです。\n                "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                    農業分野、水稲用途での開拓を中心に今後、急速な普及・発展が期待されています。\n                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "photo" }, [
      _c("img", {
        attrs: { src: "static/img/img_compare-drone.png", alt: "ドローン" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _vm._v("１台購入すれば"),
        _c("br"),
        _vm._v("自分の好きなタイミングで散布できる")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _vm._v("機体が小型で機動性が高く、"),
        _c("br"),
        _vm._v("狭い圃場や複雑な地形の散布も可能")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("span", [_vm._v("飛行騒音が小さい")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _vm._v("機体重量が軽いため一人でも"),
        _c("br"),
        _vm._v("取り扱いが可能（監視員は別途必要）")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [_vm._v("操縦が簡単で安全性の高い機能が豊富")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("span", [_vm._v("粒状肥料散布も可能")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "minus" }, [
      _c("li", [_vm._v("1回の飛行時間が短い")]),
      _vm._v(" "),
      _c("li", [_vm._v("所有者がメンテナンスする必要がある")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "photo" }, [
      _c("img", {
        attrs: { src: "static/img/img_compare-heli.png", alt: "ヘリコプター" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "plus" }, [
      _c("li", [
        _c("span", [
          _vm._v("業者へ委託すれば、作業料金が"),
          _c("br"),
          _vm._v("単位面積のためわかりやすい")
        ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("span", [
          _vm._v("散布範囲さえ指定すれば、"),
          _c("br"),
          _vm._v("業者が散布してくれるので手間が無く楽")
        ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("span", [
          _vm._v("1回の飛行時間が長く、"),
          _c("br"),
          _vm._v("広範囲の散布が可能")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v("機体が高価なため、"),
      _c("br"),
      _vm._v("通常は散布を業者へ委託")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v("業者委託のため"),
      _c("br"),
      _vm._v("自由なタイミングで散布できない")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--pos_left" }, [
      _c("object", {
        attrs: {
          type: "image/svg+xml",
          data: "static/img/img_quotation-start-color.svg"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--pos_right" }, [
      _c("object", {
        attrs: {
          type: "image/svg+xml",
          data: "static/img/img_quotation-end-color.svg"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading_section" }, [
      _c("h2", [_vm._v("防除の現状と今後の展望　〜その２〜")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h3", { staticClass: "table-title" }, [
        _vm._v("無人航空機による散布等の面積")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-container" }, [
      _c("table", { staticClass: "table-area" }, [
        _c("caption", [
          _vm._v("\n                    （単位：ha）\n                ")
        ]),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", [
            _c("th"),
            _vm._v(" "),
            _c("th", [_vm._v("平成２２年度")]),
            _vm._v(" "),
            _c("th", [_vm._v("平成２３年度")]),
            _vm._v(" "),
            _c("th", [_vm._v("平成２４年度")]),
            _vm._v(" "),
            _c("th", [_vm._v("平成２５年度")]),
            _vm._v(" "),
            _c("th", [_vm._v("平成２６年度")]),
            _vm._v(" "),
            _c("th", [_vm._v("平成２７年度")]),
            _vm._v(" "),
            _c("th", [_vm._v("平成２８年度")])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [_vm._v("水稲防除")]),
            _vm._v(" "),
            _c("td", [_vm._v("838,156")]),
            _vm._v(" "),
            _c("td", [_vm._v("851,822")]),
            _vm._v(" "),
            _c("td", [_vm._v("884,308")]),
            _vm._v(" "),
            _c("td", [_vm._v("931,095")]),
            _vm._v(" "),
            _c("td", [_vm._v("928,786")]),
            _vm._v(" "),
            _c("td", [_vm._v("923,365")]),
            _vm._v(" "),
            _c("td", [_vm._v("910,927")])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [_vm._v("麦類防除")]),
            _vm._v(" "),
            _c("td", [_vm._v("60,730")]),
            _vm._v(" "),
            _c("td", [_vm._v("61,351")]),
            _vm._v(" "),
            _c("td", [_vm._v("63,626")]),
            _vm._v(" "),
            _c("td", [_vm._v("57,152")]),
            _vm._v(" "),
            _c("td", [_vm._v("60,954")]),
            _vm._v(" "),
            _c("td", [_vm._v("70,989")]),
            _vm._v(" "),
            _c("td", [_vm._v("65,932")])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [_vm._v("大豆防除")]),
            _vm._v(" "),
            _c("td", [_vm._v("57,905")]),
            _vm._v(" "),
            _c("td", [_vm._v("51,409")]),
            _vm._v(" "),
            _c("td", [_vm._v("52,906")]),
            _vm._v(" "),
            _c("td", [_vm._v("52,258")]),
            _vm._v(" "),
            _c("td", [_vm._v("52,748")]),
            _vm._v(" "),
            _c("td", [_vm._v("56,059")]),
            _vm._v(" "),
            _c("td", [_vm._v("59,118")])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [_vm._v("その他")]),
            _vm._v(" "),
            _c("td", [_vm._v("6,459")]),
            _vm._v(" "),
            _c("td", [_vm._v("6,556")]),
            _vm._v(" "),
            _c("td", [_vm._v("6,202")]),
            _vm._v(" "),
            _c("td", [_vm._v("6,779")]),
            _vm._v(" "),
            _c("td", [_vm._v("7,348")]),
            _vm._v(" "),
            _c("td", [_vm._v("7,565")]),
            _vm._v(" "),
            _c("td", [_vm._v("10,729")])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [_vm._v("合計")]),
            _vm._v(" "),
            _c("td", [_vm._v("963,250")]),
            _vm._v(" "),
            _c("td", [_vm._v("971,138")]),
            _vm._v(" "),
            _c("td", [_vm._v("1,007,042")]),
            _vm._v(" "),
            _c("td", [_vm._v("1,047,284")]),
            _vm._v(" "),
            _c("td", [_vm._v("1,049,836")]),
            _vm._v(" "),
            _c("td", [_vm._v("1,057,978")]),
            _vm._v(" "),
            _c("td", [_vm._v("1,046,706")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading spacer" }, [
      _c("h3", { staticClass: "table-title" }, [
        _vm._v("ドローンによる散布等の延べ面積")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-container" }, [
      _c("div", { staticClass: "row-box--stretch" }, [
        _c("table", { staticClass: "table-area" }, [
          _c("caption", [
            _vm._v(
              "\n                        （単位：ha）\n                    "
            )
          ]),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("th"),
              _vm._v(" "),
              _c("th", [_vm._v("平成２８年度")]),
              _vm._v(" "),
              _c("th", [_vm._v("平成２９年度（速報値）")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("水稲防除")]),
              _vm._v(" "),
              _c("td", [_vm._v("586")]),
              _vm._v(" "),
              _c("td", [_vm._v("7,000")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("麦類防除")]),
              _vm._v(" "),
              _c("td", [_vm._v("0")]),
              _vm._v(" "),
              _c("td", [_vm._v("700")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("大豆防除")]),
              _vm._v(" "),
              _c("td", [_vm._v("97")]),
              _vm._v(" "),
              _c("td", [_vm._v("500")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("その他")]),
              _vm._v(" "),
              _c("td", [_vm._v("1")]),
              _vm._v(" "),
              _c("td", [_vm._v("100")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("合計")]),
              _vm._v(" "),
              _c("td", [_vm._v("684")]),
              _vm._v(" "),
              _c("td", [_vm._v("8,300")])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "table-area" }, [
          _c("caption", [
            _vm._v(
              "\n                        （単位：ha）\n                    "
            )
          ]),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v("地域")]),
              _vm._v(" "),
              _c("th", [_vm._v("平成２８年度")]),
              _vm._v(" "),
              _c("th", [_vm._v("平成２９年度（速報値）")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("北海道・東北")]),
              _vm._v(" "),
              _c("td", [_vm._v("2")]),
              _vm._v(" "),
              _c("td", [_vm._v("1,900")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("関東")]),
              _vm._v(" "),
              _c("td", [_vm._v("97")]),
              _vm._v(" "),
              _c("td", [_vm._v("400")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("東海")]),
              _vm._v(" "),
              _c("td", [_vm._v("0")]),
              _vm._v(" "),
              _c("td", [_vm._v("400")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("北陸")]),
              _vm._v(" "),
              _c("td", [_vm._v("138")]),
              _vm._v(" "),
              _c("td", [_vm._v("1,900")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("近畿")]),
              _vm._v(" "),
              _c("td", [_vm._v("28")]),
              _vm._v(" "),
              _c("td", [_vm._v("50")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("中国・四国")]),
              _vm._v(" "),
              _c("td", [_vm._v("342")]),
              _vm._v(" "),
              _c("td", [_vm._v("2,300")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("九州")]),
              _vm._v(" "),
              _c("td", [_vm._v("77")]),
              _vm._v(" "),
              _c("td", [_vm._v("1,400")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("合計")]),
              _vm._v(" "),
              _c("td", [_vm._v("684")]),
              _vm._v(" "),
              _c("td", [_vm._v("8,350")])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--02_img" }, [
      _c("object", {
        attrs: {
          type: "image/svg+xml",
          data: "static/img/icon_tmbup-large.svg"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading_section" }, [
      _c("h2", [_vm._v("防除の現状と今後の展望　〜その３〜")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-box col-article single-right" }, [
      _c("h3", [_vm._v("ドローンの普及と今後の展望")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                    ドローンの登録台数は2017年３月〜2018年２月までの約1年間で約3倍もの登録台数となりました。それに伴い、オペレータの認定者数も約3倍となり、今後の需要の拡大に合わせ、登録台数、オペレータ数もますます増加していくものと予想されています。\n                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--02_img" }, [
      _c("object", {
        attrs: {
          type: "image/svg+xml",
          data: "static/img/icon_tmbup-large.svg"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }