<template>
    <section>
        <a id="head"></a>
        <section class="content-box">
            <div class="heading">
                <div class="heading_inner">
                    <div class="heading_title-sub" v-if="$mq === 'pc'">
                        <object data="static/img/img_pagetitle-feature.svg"></object>
                    </div>
                    <div class="heading_title">
                        <p>
                            アグリフライヤーの特長
                        </p>
                    </div>
                </div>
            </div>
            <div class="agri-flyer">
                <div class="markers">
                    <a
                        class="marker marker--01"
                        value="高耐久モーター"
                        @mouseover="mouseOver"
                        @mouseleave="mouseLeave"
                    >
                        <img src="static/img/btn_baloon-nom.png" alt="高耐久モーター" />
                    </a>
                    <a
                        class="marker marker--02"
                        value="折畳ローター"
                        @mouseover="mouseOver"
                        @mouseleave="mouseLeave"
                    >
                        <img src="static/img/btn_baloon-nom.png" alt="折畳ローター" />
                    </a>
                    <a
                        class="marker marker--03"
                        value="散布ノズル"
                        @mouseover="mouseOver"
                        @mouseleave="mouseLeave"
                    >
                        <img src="static/img/btn_baloon-nom.png" alt="散布ノズル" />
                    </a>
                    <a
                        class="marker marker--04"
                        value="農薬タンク、バッテリー収納部"
                        @mouseover="mouseOver"
                        @mouseleave="mouseLeave"
                    >
                        <img
                            src="static/img/btn_baloon-nom.png"
                            alt="農薬タンク、バッテリー収納部"
                        />
                    </a>
                    <a
                        class="marker marker--05"
                        value="ポンプユニット"
                        @mouseover="mouseOver"
                        @mouseleave="mouseLeave"
                    >
                        <img src="static/img/btn_baloon-nom.png" alt="ポンプユニット" />
                    </a>
                    <a
                        class="marker marker--06"
                        value="流量センサー"
                        @mouseover="mouseOver"
                        @mouseleave="mouseLeave"
                    >
                        <img src="static/img/btn_baloon-nom.png" alt="流量センサー" />
                    </a>
                    <a
                        class="marker marker--07"
                        value="ESC内臓マグネシウム合金フレーム"
                        @mouseover="mouseOver"
                        @mouseleave="mouseLeave"
                    >
                        <img
                            src="static/img/btn_baloon-nom.png"
                            alt="ESC内臓マグネシウム合金フレーム"
                        />
                    </a>
                    <a
                        class="marker marker--08"
                        value="高輝度LED"
                        @mouseover="mouseOver"
                        @mouseleave="mouseLeave"
                    >
                        <img src="static/img/btn_baloon-nom.png" alt="高輝度LED" />
                    </a>
                </div>
                <div class="images">
                    <div class="images_img">
                        <img
                            src="static/img/bgimg_kv-feature-drone.png"
                            alt="Agri-Flyer：イラスト"
                        />
                    </div>
                </div>
            </div>
            <div class="caption">
                <div class="caption_text">
                    <p>{{ captionText }}</p>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
export default {
    data() {
        return {
            captionText: '',
            markerFlag: 1,
            // Swiper設定
            swiperOption: {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                spaceBetween: 0,
                loop: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                // navigation: {
                // 	nextEl: '.swiper-button-next',
                // 	prevEl: '.swiper-button-prev',
                // }
            },
        };
    },
    methods: {
        mouseOver(e) {
            this.captionText = e.target.getAttribute('value');
        },
        mouseLeave(e) {
            this.captionText = '';
        },
    },
};
</script>

<!-- ==================================================
CSS Style
=================================================== -->
<style scoped lang="scss">
// ****************************************
// SP Layout
// ****************************************
@media screen and (max-width: 599px) {
.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 340px;
    background: url('../static/img/bgimg_kv-feature.png') no-repeat center bottom / cover;
    position: relative;
    padding: 0 20px;
    margin-top: 40px;
}
.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 70px;
    background: rgba(0, 0, 0, 0.2);
    &_title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 256px;
        height: 30px;
        background: #57970c;
        border: solid 1px #aacc63;
        font-size: 1.6rem;
        font-weight: bold;
        color: #fff;
        letter-spacing: 3px;
    }
}
.agri-flyer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    position: relative;
    .images {
        display: flex;
        justify-content: center;
        align-items: center;
        &_img {
            width: 90%;
            height: auto;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .markers {
        position: absolute;
        top: 0;
        left: 0;
        .marker {
            display: block;
            position: absolute;
            width: 24px;
            height: 24px;
            cursor: pointer;
            &:hover::after {
                content: '';
                width: 28px;
                height: 28px;
                background: url('../static/img/btn_baloon-hov-sp.png');
                position: absolute;
                top: -1px;
                right: -1px;
                z-index: 2;
            }
            &--01 {
                top: 22px;
                left: 58px;
            }
            &--02 {
                top: 70px;
                left: 10px;
            }
            &--03 {
                top: 130px;
                left: 25px;
            }
            &--04 {
                top: 65px;
                left: 140px;
            }
            &--05 {
                top: 115px;
                left: 126px;
            }
            &--06 {
                top: 140px;
                left: 145px;
            }
            &--07 {
                top: 85px;
                left: 240px;
            }
            &--08 {
                top: 95px;
                left: 280px;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
.caption {
    width: 100%;
    position: absolute;
    bottom: 0;
    &_text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        background: rgba(0, 0, 0, 0.6);
        p {
            font-size: 1.6rem;
            font-weight: bold;
            color: #fff;
        }
    }
}
}

// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 1024px) {
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 540px;
        padding: 0;
        margin-top: 60px;
        background: url('../static/img/bgimg_kv-feature.png') no-repeat center center / cover;
        position: relative;
    }
    .heading {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100px;
        background: rgba(0, 0, 0, 0.2);
        &_inner {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            position: relative;
        }
        &_title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 400px;
            height: 40px;
            background: #57970c;
            border: solid 1px #aacc63;
            font-size: 2.1rem;
            font-weight: bold;
            color: #fff;
            letter-spacing: 3px;
        }
    }
    .agri-flyer {
        margin-top: 30px;
        position: relative;
        width: 500px;
        height: auto;
        .images {
            &_img {
                width: 500px;
                height: auto;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .markers {
            width: 500px;
            position: absolute;
            top: 0;
            left: 0;
            .marker {
                display: block;
                position: absolute;
                width: 36px;
                height: 36px;
                cursor: pointer;
                &:hover::after {
                    content: '';
                    width: 44px;
                    height: 45px;
                    background: url('../static/img/btn_baloon-hov.png');
                    position: absolute;
                    top: -1px;
                    right: -1px;
                    z-index: 2;
                }
                &--01 {
                    top: 44px;
                    left: 88px;
                }
                &--02 {
                    top: 130px;
                    left: 0;
                }
                &--03 {
                    top: 215px;
                    left: 12px;
                }
                &--04 {
                    top: 105px;
                    left: 205px;
                }
                &--05 {
                    top: 190px;
                    left: 180px;
                }
                &--06 {
                    top: 235px;
                    left: 210px;
                }
                &--07 {
                    top: 140px;
                    left: 370px;
                }
                &--08 {
                    top: 155px;
                    left: 432px;
                }
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .caption {
        width: 100%;
        position: absolute;
        bottom: 0;
        &_text {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 60px;
            background: rgba(0, 0, 0, 0.6);
            p {
                font-size: 2.2rem;
                font-weight: bold;
                color: #fff;
            }
        }
    }
}

// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1025px) {
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 608px;
        padding: 0;
        background: url('../static/img/bgimg_kv-feature.png') no-repeat center center / cover;
        position: relative;
    }
    .heading {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 120px;
        @include stripe($backColor: none, $frontColor: #012948);
        &_inner {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 1280px;
            max-width: 1360px;
            width: 100%;
            height: 100%;
            position: relative;
        }
        &_title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 450px;
            height: 50px;
            background: #57970c;
            border: solid 1px #aacc63;
            font-size: 2.8rem;
            font-weight: bold;
            color: #fff;
            letter-spacing: 3px;
            &-sub {
                position: absolute;
                left: 40px;
                top: 50%; // for IE
                transform: translateY(-50%); // for IE
            }
        }
    }
    .agri-flyer {
        margin-top: 15px;
        position: relative;
        .images {
            &_img {
                width: 610px;
                height: auto;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .markers {
            position: absolute;
            top: 0;
            left: 0;
            .marker {
                display: block;
                position: absolute;
                width: 36px;
                height: 36px;
                cursor: pointer;
                &:hover::after {
                    content: '';
                    width: 44px;
                    height: 45px;
                    background: url('../static/img/btn_baloon-hov.png');
                    position: absolute;
                    top: -1px;
                    right: -1px;
                    z-index: 2;
                }
                &--01 {
                    top: 44px;
                    left: 88px;
                }
                &--02 {
                    top: 150px;
                    left: 0;
                }
                &--03 {
                    top: 260px;
                    left: 15px;
                }
                &--04 {
                    top: 128px;
                    left: 260px;
                }
                &--05 {
                    top: 230px;
                    left: 225px;
                }
                &--06 {
                    top: 285px;
                    left: 260px;
                }
                &--07 {
                    top: 174px;
                    left: 446px;
                }
                &--08 {
                    top: 188px;
                    left: 540px;
                }
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .caption {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        &_text {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 60px;
            background: rgba(0, 0, 0, 0.6);
            p {
                font-size: 2.2rem;
                font-weight: bold;
                color: #fff;
            }
        }
    }
}
</style>
