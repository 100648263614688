<template>
    <section class="container">
        <!-- Header -->
        <Header :menus="menuItems" />
        <!-- Contents Component -->
        <RouterView name="KeyVisual" />
        <RouterView :sliderImage="sliderImage" />
        <!-- Footer -->
        <Footer :menus="menuItems" />
    </section>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            menuItems: [
                {
                    name: 'トップ',
                    path: '/',
                },
                {
                    name: 'Agri Flyerの特長',
                    path: '/feature',
                },
                {
                    name: 'スペック',
                    path: '/spec',
                },
                {
                    name: 'スマート農業って？',
                    path: '/smart',
                },
                {
                    name: 'よくある質問',
                    path: '/question',
                },
                {
                    name: 'お問い合わせ',
                    path: '/inquiry',
                },
                {
                    name: 'IER製品',
                    path: '/lineup',
                },
            ],
            sliderImage: [
                {
                    alt: '',
                    image_pc: '/static/img/img_carousel-tmb01.png',
                    image_sp: '/img/slide_sp-1.jpg',
                },
                {
                    alt: '',
                    image_pc: 'static/img/img_carousel-tmb02.png',
                    image_sp: '/img/slide_sp-1.jpg',
                },
                {
                    alt: '',
                    image_pc: 'static/img/img_carousel-tmb03.png',
                    image_sp: '/img/slide_sp-1.jpg',
                },
            ],
        };
    },
    methods: {
        // metaを置き換える
        setPageTitle(to) {
            if (to.meta) {
                const title = 'Agri Flyer' + to.meta.title;
                const description = to.meta.description;
                // タイトル置き換え
                document.title = title;
                // 説明文置き換え
                document
                    .querySelector("meta[name='description']")
                    .setAttribute('content', description);
                // OGP置き換え
                // document.querySelector("meta[property='og:title']").setAttribute('content', title)
                // document.querySelector("meta[property='og:description']").setAttribute('content', description)
            }
        },
    },
    watch: {
        $route(to, from) {
            this.setPageTitle(to);
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.container {
    width: 100%;
}
</style>
