var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("section", { staticClass: "content-box" }, [
      _c("div", { staticClass: "content-box_inner" }, [
        _c("div", { staticClass: "heading" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("h3", { staticClass: "heading_title-sub" }, [
            _vm._v("\n                        高性能・高耐久性、"),
            _vm.$mq === "sp" ? _c("br") : _vm._e(),
            _vm._v("\n                        次世代の農薬散布用ドローン"),
            _vm.$mq === "tb" ? _c("br") : _vm._e(),
            _vm._v("「アグリフライヤー」\n                    ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "link" }, [
          _c(
            "a",
            {
              staticClass: "link_btn link_btn--01",
              class: [_vm.activeName == "btn01" ? "active" : ""],
              attrs: { href: "javascript:void(0)", name: "btn01" },
              on: {
                mouseover: _vm.mouseOver,
                mouseleave: _vm.mouseLeave,
                click: function($event) {
                  $event.preventDefault()
                  return _vm.scrollPage($event)
                }
              }
            },
            [
              _vm._v(
                "\n                        どんなマシン？\n                    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "link_btn link_btn--02",
              class: [_vm.activeName == "btn02" ? "active" : ""],
              attrs: { href: "javascript:void(0)", name: "btn02" },
              on: {
                mouseover: _vm.mouseOver,
                mouseleave: _vm.mouseLeave,
                click: function($event) {
                  $event.preventDefault()
                  return _vm.scrollPage($event)
                }
              }
            },
            [
              _vm._v(
                "\n                        どこがすごいの？\n                    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "link_btn link_btn--03",
              class: [_vm.activeName == "btn03" ? "active" : ""],
              attrs: { href: "javascript:void(0)", name: "btn03" },
              on: {
                mouseover: _vm.mouseOver,
                mouseleave: _vm.mouseLeave,
                click: function($event) {
                  $event.preventDefault()
                  return _vm.scrollPage($event)
                }
              }
            },
            [
              _vm._v(
                "\n                        スマート農業って？\n                    "
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading_img" }, [
      _c("img", {
        attrs: { src: "static/img/AF_logo_img.png", alt: "Agri-Flyer：ロゴ" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading_title" }, [
      _c("img", {
        attrs: { src: "static/img/AF_logo_b.png", alt: "Agri-Flyer：ロゴ" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }