<template>
    <div id="video-comp" class="youtube">
        <!--<h3>{{videopath}}</h3>-->
        <iframe src="https://www.youtube.com/embed/84OOSnV6PKA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</template>

<script>
export default {
    data() {
        return {
            play: false, // 再生・停止状態
        };
    },
    directives: {
        video(el, binding) {
            binding.value ? el.play() : el.pause();
        },
    },
    computed: {
        btnstate() {
            return this.play ? 'STOP' : 'PLAY';
        },
    },
    props: {
        videopath: String, // 呼出元から渡されるビデオのパス
    },
};
</script>
<style scoped lang="scss">
// ****************************************
// SP Layout
// ****************************************
@media screen and (max-width: 599px) {
    .youtube {
        iframe {
            width: 100%;
            max-width: 640px;
            height: calc((100vw - 20px) * 0.5625);
            margin: auto;
        }
    }
    #video-comp {
    background-color: $dark-gray;
    padding: 4px;
    margin: 2rem auto;
    video {
        width: 100%;
        height: auto;
    }
    button {
        width: 100%;
        padding: 8px;
        text-align: center;
        font-size: 1.2rem;
        background-color: rgba(255, 255, 255, 0.2);
        border: none;
        margin-top: 2px;
        color: $white;
        &:hover {
            background-color: rgba(255, 255, 255, 0.4);
        }
    }
    }
}
// ****************************************
// SPL Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 768px) {
    .youtube {
        iframe {
            width: 100%;
            max-width: 640px;
            height: calc((100vw - 20px) * 0.5625);
            margin: auto;
        }
    }
    #video-comp {
        width: 100%;
        background-color: $dark-gray;
        padding: 4px;
        video {
            width: 100%;
            height: auto;
        }
        button {
            width: 100%;
            padding: 8px;
            text-align: center;
            font-size: 1.2rem;
            background-color: rgba(255, 255, 255, 0.2);
            border: none;
            margin-top: 2px;
            color: $white;
            &:hover {
                background-color: rgba(255, 255, 255, 0.4);
            }
        }
    }
}
// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .youtube {
        iframe {
            width: 640px;
            height: 360px;
            margin: auto;
        }
    }
    #video-comp {
        background-color: $dark-gray;
        padding: 4px;
        video {
            width: 720px;
            height: 405px;
        }
        button {
            width: 100%;
            padding: 8px;
            text-align: center;
            font-size: 1.2rem;
            background-color: rgba(255, 255, 255, 0.2);
            border: none;
            margin-top: 2px;
            color: $white;
            &:hover {
                background-color: rgba(255, 255, 255, 0.4);
            }
        }
    }
}
// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1025px) {
    .youtube {
        iframe {
            width: 640px;
            height: 360px;
            margin: auto;
        }
    }
    #video-comp {
        background-color: $dark-gray;
        padding: 4px;
        video {
            width: 720px;
            height: 405px;
        }
        button {
            width: 100%;
            padding: 8px;
            text-align: center;
            font-size: 1.2rem;
            background-color: rgba(255, 255, 255, 0.2);
            border: none;
            margin-top: 2px;
            color: $white;
            &:hover {
                background-color: rgba(255, 255, 255, 0.4);
            }
        }
    }
}
</style>
