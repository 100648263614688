var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("section", [
      _c("div", { staticClass: "heading" }, [
        _c("h2", { staticClass: "shade" }, [
          _vm._v("\n                自動車開発技術をいかした、"),
          _vm.$mq === "tb" || _vm.$mq === "spl" || _vm.$mq === "sp"
            ? _c("br")
            : _vm._e(),
          _vm._v("世界初の工業化産業用マルチコプター\n            ")
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "image-slider" },
        [
          _c(
            "swiper",
            { attrs: { options: _vm.swiperOptions } },
            [
              _vm._l(_vm.sliderImage, function(slider) {
                return _c("swiper-slide", { key: slider.index }, [
                  _c("img", {
                    attrs: { src: slider.image_pc, alt: slider.alt }
                  })
                ])
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "swiper-button-prev",
                  attrs: { slot: "button-prev" },
                  slot: "button-prev"
                },
                [
                  _c("img", {
                    attrs: { src: "static/img/btn_carousel-left.png" }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "swiper-button-next",
                  attrs: { slot: "button-next" },
                  slot: "button-next"
                },
                [
                  _c("img", {
                    attrs: { src: "static/img/btn_carousel-right.png" }
                  })
                ]
              )
            ],
            2
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "content-box content-box--01" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("CompVideo", { attrs: { videopath: "" } }),
        _vm._v(" "),
        _vm.$mq === "sp"
          ? _c("div", { staticClass: "images--02_img" }, [
              _c("img", {
                attrs: {
                  src: "static/img/img_transform-sp.png",
                  alt: "Agri-Flyer"
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$mq === "pc"
          ? _c("div", { staticClass: "images--02_img" }, [
              _c("img", {
                attrs: {
                  src: "static/img/img_transform.png",
                  alt: "Agri-Flyer"
                }
              })
            ])
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("section", { staticClass: "content-box content-box--02" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "feature" }, [
        _c("div", { staticClass: "col-box" }, [
          _c("p", { staticClass: "caption_text" }, [
            _vm._v("\n                    重心変化を抑え安定した飛行を実現、"),
            _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
            _vm._v("タンク下へのポンプの配置で"),
            _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
            _vm._v("薬剤を最後まで使い切ることが可能\n                ")
          ]),
          _vm._v(" "),
          _vm._m(2)
        ]),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _vm._m(4)
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "read-box" }, [
        _vm._v(
          "\n            タンクキャップは直径170ｍｍと農薬散布ドローンでは最大サイズ（当社調べ）を採用し、薬剤の充填、洗浄が容易に行えます。また、工具を使わずに脱着が可能で、現場で種類の違う薬剤を使い分けることも容易です。\n        "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "option-box" }, [
        _vm._m(5),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                オプションの粒剤散布ユニットへ換装することで、粒状肥料などの散布も可能です。"
          ),
          _vm.$mq === "pc" ? _c("br") : _vm._e(),
          _vm._v(
            "ユニットの付け替えに要する時間はわずか１分ほどです。\n            "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flight-assist" }, [
        _c("div", { staticClass: "heading" }, [
          _c("h2", [
            _vm._v(
              "\n                    農薬散布専用機のための飛行アシスト機能。"
            ),
            _vm.$mq === "spl" || _vm.$mq === "tb" || _vm.$mq === "pc"
              ? _c("br")
              : _vm._e(),
            _vm._v("飛行速度と連動した流量制御により、"),
            _vm.$mq === "spl" || _vm.$mq === "tb" ? _c("br") : _vm._e(),
            _vm._v("均一できめ細かい散布を行います\n                ")
          ])
        ]),
        _vm._v(" "),
        _vm.$mq === "sp"
          ? _c("div", { staticClass: "images--02" }, [_vm._m(6)])
          : _vm._e(),
        _vm._v(" "),
        _vm.$mq === "pc"
          ? _c("div", { staticClass: "images--02" }, [_vm._m(7)])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "content-box content-box--03" }, [
      _vm._m(8),
      _vm._v(" "),
      _c("div", { staticClass: "heading" }, [
        _c("h2", [
          _vm._v("\n                新開発専用電動モーターを採用、"),
          _vm.$mq === "spl" ? _c("br") : _vm._e(),
          _vm._v("1000時間連続耐久試験をクリア。"),
          _vm.$mq === "spl" || _vm.$mq === "tb" || _vm.$mq === "pc"
            ? _c("br")
            : _vm._e(),
          _vm._v("Made in Japan の品質を誇ります\n            ")
        ])
      ]),
      _vm._v(" "),
      _vm.$mq === "sp"
        ? _c("div", { staticClass: "images--02_img" }, [
            _c("img", {
              attrs: { src: "static/img/img_test-sp.png", alt: "テスト" }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$mq === "pc"
        ? _c("div", { staticClass: "images--02_img" }, [
            _c("img", {
              attrs: { src: "static/img/img_test.png", alt: "テスト" }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", { staticClass: "caption--01_text" }, [
        _vm._v(
          "\n            専用に開発されたモーターは1000時間の耐久性試験の他、着陸耐久（落下）テスト、FEM解析、IP試験など、自動車開発に準じた手法での様々なテストをクリア。また専用に開発されたESCは、マグネシウム合金性アームに内蔵され、ローターのダウンウォッシュで冷却されることで100％の性能を発揮します。\n        "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "heading" }, [
        _c("h2", { staticClass: "quality" }, [
          _vm._v("\n                アグリフライヤーは"),
          _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
          _vm._v("日本の工業製品としての品質を"),
          _c("br"),
          _vm._v("高い水準で有しています\n            ")
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "content-box content-box--04" }, [
      _vm._m(9),
      _vm._v(" "),
      _c("div", { staticClass: "heading" }, [
        _c("h2", [
          _vm._v("\n                日本を代表する車メーカーでの"),
          _vm.$mq === "tb" || _vm.$mq === "pc" ? _c("br") : _vm._e(),
          _vm._v("エンジン開発・車両開発・量産の経験を持ったエンジニアを"),
          _vm.$mq === "tb" || _vm.$mq === "pc" ? _c("br") : _vm._e(),
          _vm._v(
            "中心としたベンチャー「石川エナジーリサーチ」が開発\n            "
          )
        ])
      ]),
      _vm._v(" "),
      _vm.$mq === "spl" || _vm.$mq === "sp"
        ? _c("div", { staticClass: "images--02_img" }, [
            _c("object", {
              attrs: {
                type: "image/svg+xml",
                data: "static/img/chart_aboutus-sp.svg"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$mq === "tb" || _vm.$mq === "pc"
        ? _c("div", { staticClass: "images--02_img" }, [
            _c("object", {
              attrs: {
                type: "image/svg+xml",
                data: "static/img/chart_aboutus.svg"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._m(10)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("div", { staticClass: "heading_title" }, [
        _c("object", {
          attrs: {
            type: "image/svg+xml",
            data: "static/img/index_feature01.svg"
          }
        })
      ]),
      _vm._v(" "),
      _c("h2", [
        _vm._v("\n                使いやすさを徹底追求した親切設計。"),
        _c("br"),
        _vm._v("折りたたみ可能なコンパクト機体を実現\n            ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("div", { staticClass: "heading_title" }, [
        _c("object", {
          attrs: {
            type: "image/svg+xml",
            data: "static/img/index_feature02.svg"
          }
        })
      ]),
      _vm._v(" "),
      _c("h2", [
        _vm._v(
          "\n                農薬詰め替えや洗浄が容易なタンク構造を採用。"
        ),
        _c("br"),
        _vm._v("満載時と空載時の重心変化を抑えることで、"),
        _c("br"),
        _vm._v("安定した飛行を実現しています。\n            ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images_photo" }, [
      _c("img", {
        attrs: { src: "static/img/img_column-transform.jpg", alt: "Agri-Flyer" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-box" }, [
      _c("p", { staticClass: "caption_text" }, [
        _vm._v("\n                    タンクの着脱はワンタッチ。"),
        _c("br"),
        _vm._v("広い開口部で農薬の詰め替え、"),
        _c("br"),
        _vm._v("洗浄も容易\n                ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "images_photo" }, [
        _c("img", {
          attrs: { src: "static/img/img_column-tank.png", alt: "Agri -Flyer" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-box" }, [
      _c("p", { staticClass: "caption_text" }, [
        _vm._v("\n                    流量センサーを用いた"),
        _c("br"),
        _vm._v("フィードバック制御により"),
        _c("br"),
        _vm._v("安定した定量散布を実現\n                ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "images_photo" }, [
        _c("img", {
          attrs: {
            src: "static/img/img_column-magnesium.png",
            alt: "Agri-Flyer"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: { src: "static/img/img_option.png", alt: "オプション" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--02_img" }, [
      _c("object", {
        attrs: {
          type: "image/svg+xml",
          data: "static/img/chart_flightassist-vertical.svg"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--02_img" }, [
      _c("object", {
        attrs: {
          type: "image/svg+xml",
          data: "static/img/chart_flightassist-wide.svg"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading_title" }, [
      _c("object", {
        attrs: { type: "image/svg+xml", data: "static/img/index_feature03.svg" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading_title" }, [
      _c("object", {
        attrs: { type: "image/svg+xml", data: "static/img/index_feature04.svg" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading emphasis" }, [
      _c("h2", [
        _c("p", [
          _vm._v("\n                    石川エナジーリサーチでは"),
          _c("br"),
          _vm._v(
            "\n                    熱エネルギーを利用したシステム事業を中心に"
          ),
          _c("br"),
          _vm._v(
            "\n                    新たなソリューションを創造しています\n                "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }