var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("a", { attrs: { id: "head" } }),
    _vm._v(" "),
    _c("section", { staticClass: "content-box" }, [
      _c("div", { staticClass: "heading" }, [
        _c("div", { staticClass: "heading_inner" }, [
          _vm.$mq === "pc"
            ? _c("div", { staticClass: "heading_title-sub" }, [
                _c("object", {
                  attrs: { data: "static/img/img_pagetitle-feature.svg" }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(0)
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "agri-flyer" }, [
        _c("div", { staticClass: "markers" }, [
          _c(
            "a",
            {
              staticClass: "marker marker--01",
              attrs: { value: "高耐久モーター" },
              on: { mouseover: _vm.mouseOver, mouseleave: _vm.mouseLeave }
            },
            [
              _c("img", {
                attrs: {
                  src: "static/img/btn_baloon-nom.png",
                  alt: "高耐久モーター"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "marker marker--02",
              attrs: { value: "折畳ローター" },
              on: { mouseover: _vm.mouseOver, mouseleave: _vm.mouseLeave }
            },
            [
              _c("img", {
                attrs: {
                  src: "static/img/btn_baloon-nom.png",
                  alt: "折畳ローター"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "marker marker--03",
              attrs: { value: "散布ノズル" },
              on: { mouseover: _vm.mouseOver, mouseleave: _vm.mouseLeave }
            },
            [
              _c("img", {
                attrs: {
                  src: "static/img/btn_baloon-nom.png",
                  alt: "散布ノズル"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "marker marker--04",
              attrs: { value: "農薬タンク、バッテリー収納部" },
              on: { mouseover: _vm.mouseOver, mouseleave: _vm.mouseLeave }
            },
            [
              _c("img", {
                attrs: {
                  src: "static/img/btn_baloon-nom.png",
                  alt: "農薬タンク、バッテリー収納部"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "marker marker--05",
              attrs: { value: "ポンプユニット" },
              on: { mouseover: _vm.mouseOver, mouseleave: _vm.mouseLeave }
            },
            [
              _c("img", {
                attrs: {
                  src: "static/img/btn_baloon-nom.png",
                  alt: "ポンプユニット"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "marker marker--06",
              attrs: { value: "流量センサー" },
              on: { mouseover: _vm.mouseOver, mouseleave: _vm.mouseLeave }
            },
            [
              _c("img", {
                attrs: {
                  src: "static/img/btn_baloon-nom.png",
                  alt: "流量センサー"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "marker marker--07",
              attrs: { value: "ESC内臓マグネシウム合金フレーム" },
              on: { mouseover: _vm.mouseOver, mouseleave: _vm.mouseLeave }
            },
            [
              _c("img", {
                attrs: {
                  src: "static/img/btn_baloon-nom.png",
                  alt: "ESC内臓マグネシウム合金フレーム"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "marker marker--08",
              attrs: { value: "高輝度LED" },
              on: { mouseover: _vm.mouseOver, mouseleave: _vm.mouseLeave }
            },
            [
              _c("img", {
                attrs: {
                  src: "static/img/btn_baloon-nom.png",
                  alt: "高輝度LED"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _vm._m(1)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "caption" }, [
        _c("div", { staticClass: "caption_text" }, [
          _c("p", [_vm._v(_vm._s(_vm.captionText))])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading_title" }, [
      _c("p", [
        _vm._v(
          "\n                        アグリフライヤーの特長\n                    "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images" }, [
      _c("div", { staticClass: "images_img" }, [
        _c("img", {
          attrs: {
            src: "static/img/bgimg_kv-feature-drone.png",
            alt: "Agri-Flyer：イラスト"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }