<template>
    <section>
        <!-- ******************* どんなマシン？ ******************* -->
        <section class="content-box content-box--01" id="section-01">
            <div class="content-box--01_inner">
                <figure class="banner-sp">
                    <a href="https://agri-flyer-r.ier-prod.jp/"><img src="static/img/typer-banner-sp.png" alt="知性を備えたニューエディション “type-R” 誕生"></a>
                </figure>
<!--                     <p>
                       2021年2月24日～26日に開催された<br v-if="$mq === 'sp' || $mq === 'spl'">
                       「次世代農業EXPO」<span class="text-s">（インテックス大阪）</span>にて、<br>
                        <strong>国産農薬散布用ドローン「アグリフライヤー」</strong><br v-if="$mq === 'sp'" />を出展しました。<br>
                        この場をお借りし、当ブースにご来場いただいた<br v-if="$mq === 'sp'" />多くの方々に御礼申し上げます。
                    </p> -->
                <div class="heading">
                    <h2 class="heading_title">どんなマシン？</h2>
                    <h3 class="heading_title-sub">What's Agri Flyer?</h3>
                </div>
                <div class="lead">
                    <p class="lead_text">
                        自動車開発技術から生まれた、<br
                            v-if="$mq === 'sp' || $mq === 'spl' || $mq === 'tb'"
                        />
                        高性能、高耐久性の農薬散布用ドローンです！
                    </p>
                </div>
                <div class="area-compvideo">
                    <CompVideo videopath=""></CompVideo>
                </div>
                <div class="feature">
                    <div class="images">
                        <div class="images_img">
                            <img src="static/img/img_af-std.png" alt="Agri-Flyer：イラスト" />
                        </div>
                    </div>
                    <div class="caption">
                        <div class="caption_title-sub">
                            <p>
                                IER農業用マルチコプター<br
                                    v-if="$mq === 'sp'"
                                />「アグリフライヤー」
                            </p>
                        </div>
                        <div class="caption_title">
                            <object
                                type="image/svg+xml"
                                data="static/img/img_aflogo-min.svg"
                            ></object>
                        </div>
                        <div class="caption_text">
                            <p>
                                時間と労力のかかる農薬散布作業。<br v-if="$mq === 'pc'" />
                                日本の農業では、高齢化に伴いこれからの作業の省力化が急務となっています。<br
                                    v-if="$mq === 'pc'"
                                />
                                <span v-show="$mq === 'pc'">
                                    最新のドローンを農薬散布に活用することで圧倒的な省力化を実現可能。<br
                                        v-if="$mq === 'pc'"
                                    />
                                    IERのAgri-Flyerなら、1ヘクタールの散布をわずか15分で行うことができます。
                                </span>
                            </p>
                        </div>
                        <div class="link">
                            <router-link class="link_btn" to="/feature"
                                >Agri Flyerの特長</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- ******************* どこがすごいの？ ******************* -->
        <section class="content-box content-box--02" id="section-02">
            <div class="content-box--02_inner">
                <div class="heading">
                    <h2 class="heading_title">どこがすごいの？</h2>
                    <h3 class="heading_title-sub">What's amazing?</h3>
                </div>
                <div class="lead">
                    <p class="lead_text">
                        現場での困りごとを解決する機能を網羅、<br
                            v-if="$mq === 'sp' || $mq === 'spl' || $mq === 'tb'"
                        />
                        しかも安価で高いパフォーマンスを実現！
                    </p>
                </div>
                <div class="feature">
                    <div class="col-box">
                        <p class="caption_text">
                            簡単折りたたみ機構でコンパクトに<br
                                v-if="$mq === 'sp' || $mq === 'spl'"
                            />
                            現場では工具不要で飛行状態に
                        </p>
                        <div class="images_img">
                            <img
                                src="static/img/img_column-transform.jpg"
                                alt="Agri-Flyer：折りたたみ"
                            />
                        </div>
                    </div>
                    <div class="col-box">
                        <p class="caption_text">
                            重心変化を抑え安定した飛行を実現、<br
                                v-if="$mq === 'sp' || $mq === 'spl'"
                            />
                            タンク下へのポンプの配置で<br v-if="$mq === 'sp' || $mq === 'spl'" />
                            薬剤を最後まで使い切ることが可能
                        </p>
                        <div class="images_img">
                            <img src="static/img/img_column-tank.png" alt="Agri-Flyer：ポンプ" />
                        </div>
                    </div>
                    <div class="col-box">
                        <p class="caption_text">
                            樹脂製に比べ、紫外線劣化の少ない<br
                                v-if="$mq === 'sp' || $mq === 'spl'"
                            />
                            マグネシウム合金製ボディを採用<br
                                v-if="$mq === 'sp' || $mq === 'spl'"
                            />
                            農業用としての高い耐久性を実現
                        </p>
                        <div class="images_img">
                            <img
                                src="static/img/img_column-magnesium.png"
                                alt="Agri-Flyer：マグネシウムボディ"
                            />
                        </div>
                    </div>
                </div>
                <div class="graph" v-if="$mq === 'sp' || $mq === 'spl'">
                    <div class="images">
                        <div class="images_img">
                            <object
                                type="image/svg+xml"
                                data="static/img/chart_runningcost-sp.svg"
                            ></object>
                        </div>
                    </div>
                </div>
                <div class="graph" v-if="$mq === 'tb' || $mq === 'pc'">
                    <div class="images">
                        <div class="images_img">
                            <object
                                type="image/svg+xml"
                                data="static/img/chart_runningcost-wide.svg"
                            ></object>
                        </div>
                    </div>
                </div>
                <div class="link">
                    <router-link class="link_btn" to="/feature">Agri Flyerの特長</router-link>
                </div>
            </div>
        </section>
        <!-- ******************* スマート農業って？ ******************* -->
        <section class="content-box content-box--03" id="section-03">
            <div class="content-box--03_inner">
                <div class="heading">
                    <h2 class="heading_title">スマート農業って？</h2>
                    <h3 class="heading_title-sub">What's Smart Farm?</h3>
                </div>
                <div class="lead">
                    <p class="lead_text">
                        スマート農業&emsp;<br
                            v-if="$mq === 'spl'"
                        />＝&ensp;「先端技術」&ensp;×&ensp;「農業技術」
                    </p>
                </div>
                <div class="images--01" v-if="$mq === 'pc'">
                    <div class="images--01_img">
                        <object data="static/img/chart_smartfarm-wide.svg"></object>
                    </div>
                </div>
                <div class="images--01" v-if="$mq === 'sp'">
                    <div class="images--01_img">
                        <object data="static/img/chart_sfarm-vertical.svg"></object>
                    </div>
                </div>
                <div class="caption--01">
                    <p class="caption--01_title">
                        「スマート農業」とは
                    </p>
                    <p class="caption--01_text">
                        最先端のロボット技術や人工知能（AI）を活用した、新しい時代の農業のスタイルです。農家の高齢化や担い手不足が深刻な問題となる中、政府でも2019年のり、国内50箇所のモデル農場で、自動運転トラクターを使っての農地開拓の実証実験に乗り出すなど、実用に向けた動きが加速しています。そのひとつ、ドローンを利用した農薬散布が、非常に注目されています。
                    </p>
                </div>
                <div class="images--02" v-if="$mq === 'tb' || $mq === 'pc'">
                    <div class="images--02_img">
                        <object data="static/img/chart_5years.svg"></object>
                    </div>
                </div>
                <div class="images--02" v-if="$mq === 'sp' || $mq === 'spl'">
                    <div class="images--02_img">
                        <object data="static/img/chart_5years-vertical.svg"></object>
                    </div>
                </div>
                <div class="caption--02">
                    <p class="caption--02_text">
                        <span class="marker"
                            >5年後には、ドローンによる空散が<br
                                v-if="$mq === 'sp' || $mq === 'spl' || $mq === 'tb'"
                            />50%のシェアとなることが予想されるなど、<br />
                            現在、もっとも期待されている<br
                                v-if="$mq === 'sp' || $mq === 'spl' || $mq === 'tb'"
                            />ソリューションのひとつになっています。</span
                        >
                    </p>
                </div>
                <div class="link">
                    <router-link class="link_btn" to="/smart">スマート農業をくわしく</router-link>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import CompVideo from './CompVideo.vue';
export default {
    props: ['menus'],
    components: {
        CompVideo: CompVideo,
    },
};
</script>

<!-- ==================================================
CSS Style
=================================================== -->
<style scoped lang="scss">
// ****************************************
// SP Layout
// ****************************************
// common
@media screen and (max-width: 599px) {
// banner type-R
.banner-sp {
    width: 100%;
    margin-bottom: 40px;
    img {
        object-fit: cover;
        width: 100%;
    }
}
// event-photo
.img-event{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 970px;
    margin-bottom: 40px;
    li {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        img {
            width: 100%;
            height: auto;
        }
    }
}
.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40px 20px 40px;
    background: #fff;
    &_inner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .banner {
        margin-bottom: 30px;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &_text {
            font-size: 1.4rem;
            line-height: 1.5;
            margin-top: 10px;
            border: solid 1px #aaa;
            padding: 10px 25px;
        }
        img {
            width: 100%;
            height: auto;
        }
        p {
            margin-top: 10px;
            text-align: center;
            line-height: 1.5;
            font-size: 1.4rem;
            strong {
                font-weight: bold;
                color: $af-green;
            }
            .text-s {
                font-size: 1.2rem;
            }
        }
    }
}
.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    &_title {
        font-size: 1.8rem;
        text-align: center;
        &-sub {
            font-size: 1.4rem;
            font-weight: normal;
            color: #007a0c;
            text-align: center;
            margin-top: 8px;
        }
    }
}
.lead {
    margin-top: 20px;
    &_text {
        text-align: center;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.5;
        margin-top: 20px;
        white-space: nowrap;
    }
}
// contents
.content-box {
    // どんなマシン？ ********************
    &--01 {
        position: relative;
        z-index: 1;
        &_inner {
            width: 100%;
        }
        .images {
            width: 100%;
            margin-top: 10px;
            &_img {
                width: 100%;
                height: 200px;
                background: #333;
            }
        }
        .feature {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            .images {
                display: flex;
                justify-content: center;
                &_img {
                    width: 90%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .caption {
                display: flex;
                flex-direction: column;
                align-items: center;
                &_title {
                    margin-top: 12px;
                    width: 200px;
                    height: auto;
                    object {
                        width: 100%;
                        height: auto;
                    }
                    &-sub {
                        p {
                            font-size: 1.4rem;
                            font-weight: bold;
                            text-align: center;
                            line-height: 1.5;
                            margin-top: 10px;
                        }
                    }
                }
                &_text {
                    line-height: 1.5;
                    margin-top: 15px;
                }
            }
            .link {
                width: 100%;
                margin-top: 30px;
                &_btn {
                    @include btn-detail-sp();
                }
            }
        }
    }
    // どこがすごいの？ ********************
    &--02 {
        position: relative;
        z-index: 1;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            margin-top: 5%;
            transform: skewY(-5deg);
            transform-origin: bottom left;
            z-index: -1;
            width: 100%;
            height: 100%;
            background-color: $af-feature-mat;
        }
        .feature {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 30px;
            .col-box {
                width: 100%;
                background: #fff;
                border: solid 1px #b1d2e7;
                padding: 0 10px 10px;
                margin-top: 20px;
                &:first-child {
                    margin-top: 0;
                }
                .images_img {
                    width: 100%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .caption_text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100px;
                    font-size: 1.6rem;
                    font-weight: bold;
                    line-height: 1.6;
                    text-align: center;
                }
            }
        }
        .graph {
            margin-top: 30px;
            > .images {
                .images_img {
                    width: 100%;
                    height: auto;
                    object {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .link {
            width: 100%;
            margin-top: 30px;
            &_btn {
                @include btn-detail-sp();
            }
        }
    }
    // スマート農業って？ ********************
    &--03 {
        .images {
            &--01 {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                &_img {
                    width: 244px;
                    height: auto;
                    object {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            &--02 {
                display: flex;
                justify-content: center;
                margin-top: 40px;
                &_img {
                    display: flex;
                    justify-content: center;
                    width: 335px;
                    height: auto;
                    object {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .caption {
            &--01 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                background: #f7f7e8;
                border: solid 2px #82c580;
                border-radius: 12px;
                padding: 20px 20px;
                margin-top: 30px;
                &_title {
                    font-size: 1.6rem;
                    font-weight: bold;
                }
                &_text {
                    font-size: 1.6rem;
                    line-height: 1.6;
                    margin-top: 15px;
                    text-align: justify;
                }
            }
            &--02 {
                margin-top: 30px;
                &_text {
                    font-size: 1.6rem;
                    line-height: 1.8;
                    font-weight: bold;
                    text-align: center;
                    .marker {
                        background: linear-gradient(transparent 50%, #eaff80 0);
                    }
                }
            }
        }
        .link {
            width: 100%;
            margin-top: 30px;
            &_btn {
                @include btn-detail-sp();
            }
        }
    }
    }
}
// ****************************************
// SPL Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 768px) {
    // banner type-R
    .banner-sp {
        width: 100%;
        margin-bottom: 40px;
        img {
            object-fit: cover;
            width: 100%;
        }
    }
    // event-photo
    .img-event{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 970px;
        margin-bottom: 40px;
        li {
            width: 100%;
            height: auto;
            margin-bottom: 10px;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    // common
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 0 80px;
        background: #fff;
        .banner {
            width: 100vw;
            margin-top: 40px;
            margin-bottom: 70px;
            padding: 0 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &_text {
                font-size: 2.4rem;
                line-height: 1.5;
                margin-top: 20px;
                border: solid 1px #aaa;
                padding: 10px 30px;
            }
            img {
                width: 100%;
                height: auto;
            }
            p {
                margin-top: 20px;
                text-align: center;
                line-height: 1.5;
                font-size: 1.6rem;
                strong {
                    font-weight: bold;
                    color: $af-green;
                }
            }
        }
    }
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .lead {
        margin-top: 35px;
        &_text {
            text-align: center;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;
            white-space: normal;
        }
    }
    // contents
    .content-box {
        // どんなマシン？ ********************
        &--01 {
            padding: 40px 20px;
            &_inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                > .area-compvideo {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    width: 100%;
                    background-color: transparent;
                }
                .feature {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 50px;
                    width: 100%;
                    > .images {
                        background: none;
                        .images_img {
                            width: 100%;
                            height: auto;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                    .caption {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        margin-left: 0px;
                        &_title {
                            margin-top: 25px;
                            width: 320px;
                            height: auto;
                            object {
                                width: 100%;
                                height: auto;
                            }
                            &-sub {
                                width: 100%;
                                p {
                                    font-size: 2.4rem;
                                    font-weight: bold;
                                    margin-top: 0;
                                }
                            }
                        }
                        &_text {
                            font-size: 1.6rem;
                            line-height: 1.6;
                            margin-top: 25px;
                        }
                        .link {
                            margin-top: 25px;
                            &_btn {
                                @include btn-detail-spl();
                            }
                        }
                    }
                }
            }
        }
        // どこがすごいの？ ********************
        &--02 {
            position: relative;
            z-index: 1;
            padding: 70px 20px 70px;
            margin-top: 20px;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                margin-top: 5%;
                transform: skewY(-5deg);
                transform-origin: bottom left;
                z-index: -1;
                width: 100%;
                height: 100%;
                background-color: $af-feature-mat;
            }
            &_inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                .feature {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 40px;
                    .col-box {
                        justify-content: space-between;
                        width: 100%;
                        max-width: 400px;
                        height: 412px;
                        background: #fff;
                        border: solid 1px #b1d2e7;
                        padding: 0 10px 10px;
                        margin-top: 20px;
                        margin-left: 0px;
                        &:first-child {
                            margin-left: 0;
                        }
                        .images_img {
                            width: 100%;
                            height: auto;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                        .caption {
                            &_text {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 132px;
                                font-size: 2rem;
                                font-weight: bold;
                                line-height: 1.6;
                                text-align: center;
                            }
                        }
                    }
                }
                .graph {
                    margin-top: 50px;
                    width: 100%;
                    > .images {
                        .images_img {
                            width: 100%;
                            height: auto;
                            object {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
                .link {
                    display: flex;
                    justify-content: center;
                    margin-top: 50px;
                    &_btn {
                        @include btn-detail-spl();
                    }
                }
            }
        }
        // スマート農業って？ ********************
        &--03 {
            padding: 70px 20px 50px;
            margin-top: 0;
            &_inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                .images {
                    &--01 {
                        display: flex;
                        justify-content: center;
                        margin-top: 40px;
                        &_img {
                            width: 996px;
                            height: auto;
                            object {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                    &--02 {
                        margin-top: 80px;
                        width: 100%;
                        margin: 20px auto;
                        padding: 20px;
                        &_img {
                            width: 100%;
                            height: auto;
                            object {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
                .caption {
                    &--01 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 100%;
                        height: auto;
                        background: #f7f7e8;
                        border: solid 2px #82c580;
                        border-radius: 12px;
                        padding: 40px;
                        margin-top: 50px;
                        &_title {
                            font-size: 2rem;
                            font-weight: bold;
                        }
                        &_text {
                            font-size: 1.6rem;
                            line-height: 2;
                            margin-top: 15px;
                            text-align: justify;
                        }
                    }
                    &--02 {
                        margin-top: 70px;
                        &_text {
                            font-size: 2.8rem;
                            line-height: 1.8;
                            font-weight: bold;
                            text-align: center;
                            .marker {
                                background: linear-gradient(transparent 50%, #eaff80 0);
                            }
                        }
                    }
                }
                .link {
                    display: flex;
                    justify-content: center;
                    margin-top: 50px;
                    &_btn {
                        @include btn-detail-spl();
                    }
                }
            }
        }
    }
}

// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 769px) and (max-width: 1024px) {
    // banner type-R
    .banner-sp {
        display: none;
        width: 100%;
        margin-bottom: 40px;
        img {
            object-fit: cover;
            width: 100%;
        }
    }
    // event-photo
    .img-event{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 970px;
        margin-bottom: 40px;
        li {
            width: 480px;
            height: auto;
            margin-bottom: 10px;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    // common
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 0 80px;
        background: #fff;
        .banner {
            width: 100vw;
            margin-bottom: 30px;
            padding: 0 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &_text {
                font-size: 3rem;
                line-height: 1.5;
                margin-top: 30px;
                border: solid 1px #aaa;
                padding: 15px 40px;
            }
            img {
                max-width: 75%;
                height: auto;
                margin: auto;
            }
            p {
                margin-top: 20px;
                text-align: center;
                line-height: 1.6;
                font-size: 1.8rem;
                strong {
                    font-weight: bold;
                    color: $af-green;
                }
                .text-s {
                    font-size: 1.4rem;
                }
            }
        }
    }
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .lead {
        margin-top: 35px;
        &_text {
            text-align: center;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;
        }
    }
    // contents
    .content-box {
        // どんなマシン？ ********************
        &--01 {
            &_inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                > .area-compvideo {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 35px;
                    width: 100vw;
                    padding: 10px;
                    background: url('../static/img/bgimg_video.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                    .images_img {
                        width: 720px;
                        background: #333;
                        margin: 10px 0;
                    }
                }
                .feature {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-top: 50px;
                    padding: 0 40px;
                    > .images {
                        background: none;
                        .images_img {
                            width: 100%;
                            height: auto;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                    .caption {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-left: 50px;
                        &_title {
                            margin-top: 25px;
                            width: 320px;
                            height: auto;
                            object {
                                width: 100%;
                                height: auto;
                            }
                            &-sub {
                                width: 100%;
                                p {
                                    font-size: 2.8rem;
                                    font-weight: bold;
                                    margin-top: 0;
                                }
                            }
                        }
                        &_text {
                            font-size: 1.6rem;
                            line-height: 1.6;
                            margin-top: 25px;
                        }
                        .link {
                            margin-top: 25px;
                            &_btn {
                                @include btn-detail-tb();
                            }
                        }
                    }
                }
            }
        }
        // どこがすごいの？ ********************
        &--02 {
            padding: 80px 40px;
            &_inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                .feature {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin-top: 40px;
                    .col-box {
                        justify-content: space-between;
                        width: 420px;
                        height: auto;
                        background: #fff;
                        border: solid 1px #b1d2e7;
                        padding: 20px 10px 10px;
                        margin-top: 0;
                        margin-left: 20px;
                        &:first-child {
                            margin-left: 0;
                        }
                        .images_img {
                            width: 100%;
                            height: auto;
                            img {
                                width: 100%;
                                height: auto;
                            }
                            object {
                                width: auto;
                                height: auto;
                            }
                        }
                        .caption {
                            &_text {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 2rem;
                                font-weight: bold;
                                line-height: 1.6;
                                text-align: center;
                            }
                        }
                    }
                }
                .graph {
                    margin-top: 50px;
                    width: 100%;
                    > .images {
                        .images_img {
                            width: 100%;
                            height: auto;
                            object {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
                .link {
                    display: flex;
                    justify-content: center;
                    margin-top: 50px;
                    &_btn {
                        @include btn-detail-tb();
                    }
                }
            }
        }
        // スマート農業って？ ********************
        &--03 {
            padding: 80px 40px;
            &_inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                .images {
                    &--01 {
                        display: flex;
                        justify-content: center;
                        margin-top: 40px;
                        &_img {
                            width: 996px;
                            height: auto;
                            object {
                                width: auto;
                                height: auto;
                            }
                        }
                    }
                    &--02 {
                        margin-top: 80px;
                        width: 100%;
                        padding: 20px;
                        &_img {
                            width: 100%;
                            height: auto;
                            object {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
                .caption {
                    &--01 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 100%;
                        height: 298px;
                        background: #f7f7e8;
                        border: solid 2px #82c580;
                        border-radius: 12px;
                        padding: 0 30px;
                        margin-top: 50px;
                        &_title {
                            font-size: 2.4rem;
                            font-weight: bold;
                        }
                        &_text {
                            font-size: 1.8rem;
                            line-height: 2;
                            margin-top: 15px;
                            text-align: justify;
                        }
                    }
                    &--02 {
                        margin-top: 70px;
                        &_text {
                            font-size: 2.8rem;
                            line-height: 1.8;
                            font-weight: bold;
                            text-align: center;
                            .marker {
                                background: linear-gradient(transparent 50%, #eaff80 0);
                            }
                        }
                    }
                }
                .link {
                    display: flex;
                    justify-content: center;
                    margin-top: 50px;
                    &_btn {
                        @include btn-detail();
                    }
                }
            }
        }
    }
}
// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1025px) {
    // banner type-R
    .banner-sp {
        display: none;
        width: 100%;
        margin-bottom: 40px;
        img {
            object-fit: cover;
            width: 100%;
        }
    }
    // event-photo
    .img-event{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 970px;
        margin-bottom: 40px;
        li {
            width: 480px;
            height: auto;
            margin-bottom: 10px;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    // common
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .lead {
        margin-top: 35px;
        &_text {
            text-align: center;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;
        }
    }
    // contents
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 0 80px;
        background: #fff;
        .banner {
            width: 100vw;
            margin-bottom: 40px;
            padding: 0 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &_text {
                font-size: 3.4rem;
                line-height: 1.5;
                margin-top: 40px;
                border: solid 1px #aaa;
                padding: 30px 70px;
            }
            img {
                width: 700px;
                height: auto;
            }
            p {
                margin-top: 30px;
                text-align: center;
                line-height: 1.6;
                font-size: 2.1rem;
                strong {
                    font-weight: bold;
                    color: $af-green;
                }
                .text-s {
                    font-size: 1.6rem;
                }
            }
        }
    }
    .content-box {
        // どんなマシン？ ********************
        &--01 {
            &_inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                min-width: 1280px;
                max-width: 100%;
                > .area-compvideo {
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 35px;
                    width: 100vw;
                    background: url('../static/img/bgimg_video.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                    .images_img {
                        width: 720px;
                        background: #333;
                        margin: 10px 0;
                    }
                }
                .feature {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-top: 50px;
                    > .images {
                        background: none;
                        width: 620px; // for IE
                        .images_img {
                            width: 620px;
                            height: auto;
                            img {
                                width: auto;
                                height: 100%;
                            }
                        }
                    }
                    .caption {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 600px;
                        margin-left: 50px;
                        &_title {
                            margin-top: 25px;
                            width: 320px;
                            height: auto;
                            object {
                                width: 100%;
                                height: auto;
                            }
                            &-sub {
                                width: 650px;
                                p {
                                    font-size: 2.8rem;
                                    font-weight: bold;
                                    margin-top: 0;
                                }
                            }
                        }
                        &_text {
                            font-size: 1.6rem;
                            line-height: 1.6;
                            margin-top: 25px;
                        }
                        .link {
                            margin-top: 25px;
                            &_btn {
                                @include btn-detail();
                            }
                        }
                    }
                }
            }
        }
        // どこがすごいの？ ********************
        &--02 {
            &_inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                min-width: 1280px;
                max-width: 1360px;
                .feature {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin-top: 40px;
                    .col-box {
                        justify-content: space-between;
                        width: 400px;
                        height: 412px;
                        background: #fff;
                        border: solid 1px #b1d2e7;
                        padding: 0 10px 10px;
                        margin-top: 0;
                        margin-left: 40px;
                        &:first-child {
                            margin-left: 0;
                        }
                        .images_img {
                            width: 100%;
                            height: auto;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                        .caption {
                            &_text {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 132px;
                                font-size: 2rem;
                                font-weight: bold;
                                line-height: 1.6;
                                text-align: center;
                                width: 378px; // for IE
                            }
                        }
                    }
                }
                .graph {
                    margin-top: 50px;
                    > .images {
                        .images_img {
                            width: 840px;
                            height: auto;
                            object {
                                width: auto;
                                height: auto;
                            }
                        }
                    }
                }
                .link {
                    display: flex;
                    justify-content: center;
                    margin-top: 50px;
                    &_btn {
                        @include btn-detail();
                    }
                }
            }
        }
        // スマート農業って？ ********************
        &--03 {
            &_inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                min-width: 1280px;
                max-width: 1360px;
                .images {
                    &--01 {
                        display: flex;
                        justify-content: center;
                        margin-top: 40px;
                        &_img {
                            width: 996px;
                            height: auto;
                            object {
                                width: auto;
                                height: auto;
                            }
                        }
                    }
                    &--02 {
                        margin-top: 80px;
                        &_img {
                            width: 846px;
                            height: auto;
                            object {
                                width: auto;
                                height: auto;
                            }
                        }
                    }
                }
                .caption {
                    &--01 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 844px;
                        height: 298px;
                        background: #f7f7e8;
                        border: solid 2px #82c580;
                        border-radius: 12px;
                        padding: 0 30px;
                        margin-top: 50px;
                        &_title {
                            font-size: 2.4rem;
                            font-weight: bold;
                            margin-bottom: 0; // for IE
                        }
                        &_text {
                            font-size: 2rem;
                            line-height: 2;
                            margin-top: 15px;
                            text-align: justify;
                        }
                    }
                    &--02 {
                        margin-top: 70px;
                        &_text {
                            font-size: 2.8rem;
                            line-height: 1.8;
                            font-weight: bold;
                            text-align: center;
                            .marker {
                                background: linear-gradient(transparent 50%, #eaff80 0);
                            }
                        }
                    }
                }
                .link {
                    display: flex;
                    justify-content: center;
                    margin-top: 50px;
                    &_btn {
                        @include btn-detail();
                    }
                }
            }
        }
    }
}
</style>
