<template>
    <section>
        <section class="content-box">
            <div class="content-box_inner">
                <div class="heading">
                    <div class="heading_inner">
                        <div class="heading_title-sub" v-if="$mq === 'pc'">
                            <object data="static/img/img_pagetitle-spec.svg"></object>
                        </div>
                        <div class="heading_title">
                            <p>
                                スペック
                            </p>
                        </div>
                    </div>
                </div>
                <div class="spec">
                    <div class="spec_inner">
                        <div class="lead">
                            <p class="lead_text--01">
                                自動車開発で培った高い技術力が<br />かつてない高性能・高耐久性を実現しました
                            </p>
                            <p class="lead_text--02">
                                Agri Flyerは農水協の認定機体です
                            </p>
                        </div>
                        <div class="images">
                            <div class="images_img">
                                <object data="static/img/img_aflogo-simbol-nega.svg"></object>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
export default {
    // mounted() {
    //     console.log('Component mounted.')
    // }
};
</script>

<!-- ==================================================
CSS Style
=================================================== -->
<style scoped lang="scss">
// ****************************************
// SP Layout
// ****************************************
@media screen and (max-width: 599px) {
.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 320px;
    background: url('../static/img/bgimg_kv-spec.png') no-repeat center bottom / cover;
    position: relative;
    padding: 0 20px;
    margin-top: 40px;
    &_inner {
        width: 100vw;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }
}
.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 70px;
    background: rgba(0, 0, 0, 0.2);
    &_title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 256px;
        height: 30px;
        background: #57970c;
        border: solid 1px #aacc63;
        font-size: 1.6rem;
        font-weight: bold;
        color: #fff;
        letter-spacing: 3px;
    }
}
.spec {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    &_inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.7));
        .lead {
            display: flex;
            flex-direction: column;
            align-items: center;
            &_text {
                &--01 {
                    font-size: 1.6rem;
                    line-height: 1.5;
                    font-weight: bold;
                    text-align: center;
                    color: #fff;
                }
                &--02 {
                    font-size: 1.8rem;
                    font-weight: bold;
                    color: #fff;
                    margin-top: 20px;
                }
            }
        }
        .images {
            margin-top: 40px;
            &_img {
                width: 124px;
                height: auto;
                object {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
}

// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 1024px) {
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 480px;
        padding: 0;
        margin-top: 60px;
        background: url('../static/img/bgimg_kv-spec.png') no-repeat center center / cover;
        position: relative;
    }
    .heading {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100px;
        background: rgba(0, 0, 0, 0.2);
        &_inner {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            position: relative;
        }
        &_title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 400px;
            height: 40px;
            background: #57970c;
            border: solid 1px #aacc63;
            font-size: 2.1rem;
            font-weight: bold;
            color: #fff;
            letter-spacing: 3px;
        }
    }
    .spec {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        &_inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 50px;
            filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.7));
            .lead {
                display: flex;
                flex-direction: column;
                align-items: center;
                &_text {
                    &--01 {
                        font-size: 2.6rem;
                        line-height: 1.5;
                        font-weight: bold;
                        text-align: center;
                        color: #fff;
                    }
                    &--02 {
                        font-size: 3rem;
                        font-weight: bold;
                        color: #fff;
                        margin-top: 20px;
                    }
                }
            }
            .images {
                margin-top: 50px;
                &_img {
                    width: 200px;
                    height: auto;
                    object {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}

// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1025px) {
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 608px;
        padding: 0;
        background: url('../static/img/bgimg_kv-spec.png') no-repeat center center / cover;
        position: relative;
        &_inner {
            width: 100vw;
            height: auto;
            background: none;
        }
    }
    .heading {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 120px;
        @include stripe($backColor: none, $frontColor: #012948);
        &_inner {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 1280px;
            max-width: 1360px;
            width: 100%;
            height: 100%;
            position: relative;
        }
        &_title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 250px;
            height: 50px;
            background: #57970c;
            border: solid 1px #aacc63;
            font-size: 2.8rem;
            font-weight: bold;
            color: #fff;
            letter-spacing: 3px;
            &-sub {
                position: absolute;
                left: 40px;
                top: 50%; // for IE
                transform: translateY(-50%); // for IE
            }
        }
    }
    .spec {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        &_inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 1280px;
            max-width: 1360px;
            margin: 0;
            filter: drop-shadow(4px 4px 5px rgba(0, 0, 0, 1));
            .lead {
                display: flex;
                flex-direction: column;
                &_text {
                    &--01 {
                        font-size: 3.2rem;
                        line-height: 1.5;
                        font-weight: bold;
                        text-align: center;
                        color: #fff;
                        margin-bottom: 0; // for IE
                    }
                    &--02 {
                        font-size: 3.8rem;
                        font-weight: bold;
                        color: #fff;
                        margin-top: 40px;
                        margin-bottom: 0; // for IE
                    }
                }
            }
            .images {
                margin-top: 70px;
                &_img {
                    width: 238px;
                    height: auto;
                    object {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}
</style>
