var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "youtube", attrs: { id: "video-comp" } }, [
      _c("iframe", {
        attrs: {
          src: "https://www.youtube.com/embed/84OOSnV6PKA",
          frameborder: "0",
          allow:
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }