<template>
    <section>
        <section>
            <div class="heading">
                <h2 class="shade">
                    自動車開発技術をいかした、<br
                        v-if="$mq === 'tb' || $mq === 'spl' || $mq === 'sp'"
                    />世界初の工業化産業用マルチコプター
                </h2>
            </div>
            <div class="image-slider">
                <swiper :options="swiperOptions">
                    <swiper-slide v-for="slider in sliderImage" :key="slider.index">
                        <img :src="slider.image_pc" :alt="slider.alt" />
                    </swiper-slide>
                    <!-- Next Back -->
                    <div class="swiper-button-prev" slot="button-prev">
                        <img src="static/img/btn_carousel-left.png" />
                    </div>
                    <div class="swiper-button-next" slot="button-next">
                        <img src="static/img/btn_carousel-right.png" />
                    </div>
                </swiper>
            </div>
        </section>
        <section class="content-box content-box--01">
            <div class="heading">
                <div class="heading_title">
                    <object type="image/svg+xml" data="static/img/index_feature01.svg"></object>
                </div>
                <h2>
                    使いやすさを徹底追求した親切設計。<br />折りたたみ可能なコンパクト機体を実現
                </h2>
            </div>

            <CompVideo videopath=""></CompVideo>

            <div class="images--02_img" v-if="$mq === 'sp'">
                <img src="static/img/img_transform-sp.png" alt="Agri-Flyer" />
            </div>
            <div class="images--02_img" v-if="$mq === 'pc'">
                <img src="static/img/img_transform.png" alt="Agri-Flyer" />
            </div>
        </section>
        <section class="content-box content-box--02">
            <div class="heading">
                <div class="heading_title">
                    <object type="image/svg+xml" data="static/img/index_feature02.svg"></object>
                </div>
                <h2>
                    農薬詰め替えや洗浄が容易なタンク構造を採用。<br />満載時と空載時の重心変化を抑えることで、<br />安定した飛行を実現しています。
                </h2>
            </div>
            <div class="feature">
                <div class="col-box">
                    <p class="caption_text">
                        重心変化を抑え安定した飛行を実現、<br
                            v-if="$mq === 'sp' || $mq === 'spl'"
                        />タンク下へのポンプの配置で<br
                            v-if="$mq === 'sp' || $mq === 'spl'"
                        />薬剤を最後まで使い切ることが可能
                    </p>
                    <div class="images_photo">
                        <img src="static/img/img_column-transform.jpg" alt="Agri-Flyer" />
                    </div>
                </div>
                <div class="col-box">
                    <p class="caption_text">
                        タンクの着脱はワンタッチ。<br />広い開口部で農薬の詰め替え、<br />洗浄も容易
                    </p>
                    <div class="images_photo">
                        <img src="static/img/img_column-tank.png" alt="Agri -Flyer" />
                    </div>
                </div>
                <div class="col-box">
                    <p class="caption_text">
                        流量センサーを用いた<br />フィードバック制御により<br />安定した定量散布を実現
                    </p>
                    <div class="images_photo">
                        <img src="static/img/img_column-magnesium.png" alt="Agri-Flyer" />
                    </div>
                </div>
            </div>
            <p class="read-box">
                タンクキャップは直径170ｍｍと農薬散布ドローンでは最大サイズ（当社調べ）を採用し、薬剤の充填、洗浄が容易に行えます。また、工具を使わずに脱着が可能で、現場で種類の違う薬剤を使い分けることも容易です。
            </p>
            <div class="option-box">
                <div class="image">
                    <img src="static/img/img_option.png" alt="オプション" />
                </div>
                <p>
                    オプションの粒剤散布ユニットへ換装することで、粒状肥料などの散布も可能です。<br
                        v-if="$mq === 'pc'"
                    />ユニットの付け替えに要する時間はわずか１分ほどです。
                </p>
            </div>
            <div class="flight-assist">
                <div class="heading">
                    <h2>
                        農薬散布専用機のための飛行アシスト機能。<br
                            v-if="$mq === 'spl' || $mq === 'tb' || $mq === 'pc'"
                        />飛行速度と連動した流量制御により、<br
                            v-if="$mq === 'spl' || $mq === 'tb'"
                        />均一できめ細かい散布を行います
                    </h2>
                </div>
                <div class="images--02" v-if="$mq === 'sp'">
                    <div class="images--02_img">
                        <object
                            type="image/svg+xml"
                            data="static/img/chart_flightassist-vertical.svg"
                        ></object>
                    </div>
                </div>
                <div class="images--02" v-if="$mq === 'pc'">
                    <div class="images--02_img">
                        <object
                            type="image/svg+xml"
                            data="static/img/chart_flightassist-wide.svg"
                        ></object>
                    </div>
                </div>
            </div>
        </section>
        <section class="content-box content-box--03">
            <div class="heading_title">
                <object type="image/svg+xml" data="static/img/index_feature03.svg"></object>
            </div>
            <div class="heading">
                <h2>
                    新開発専用電動モーターを採用、<br
                        v-if="$mq === 'spl'"
                    />1000時間連続耐久試験をクリア。<br
                        v-if="$mq === 'spl' || $mq === 'tb' || $mq === 'pc'"
                    />Made in Japan の品質を誇ります
                </h2>
            </div>

            <div class="images--02_img" v-if="$mq === 'sp'">
                <img src="static/img/img_test-sp.png" alt="テスト" />
            </div>
            <div class="images--02_img" v-if="$mq === 'pc'">
                <img src="static/img/img_test.png" alt="テスト" />
            </div>

            <p class="caption--01_text">
                専用に開発されたモーターは1000時間の耐久性試験の他、着陸耐久（落下）テスト、FEM解析、IP試験など、自動車開発に準じた手法での様々なテストをクリア。また専用に開発されたESCは、マグネシウム合金性アームに内蔵され、ローターのダウンウォッシュで冷却されることで100％の性能を発揮します。
            </p>
            <div class="heading">
                <h2 class="quality">
                    アグリフライヤーは<br
                        v-if="$mq === 'sp' || $mq === 'spl'"
                    />日本の工業製品としての品質を<br />高い水準で有しています
                </h2>
            </div>
        </section>
        <section class="content-box content-box--04">
            <div class="heading_title">
                <object type="image/svg+xml" data="static/img/index_feature04.svg"></object>
            </div>
            <div class="heading">
                <h2>
                    日本を代表する車メーカーでの<br
                        v-if="$mq === 'tb' || $mq === 'pc'"
                    />エンジン開発・車両開発・量産の経験を持ったエンジニアを<br
                        v-if="$mq === 'tb' || $mq === 'pc'"
                    />中心としたベンチャー「石川エナジーリサーチ」が開発
                </h2>
            </div>
            <div class="images--02_img" v-if="$mq === 'spl' || $mq === 'sp'">
                <object type="image/svg+xml" data="static/img/chart_aboutus-sp.svg"></object>
            </div>
            <div class="images--02_img" v-if="$mq === 'tb' || $mq === 'pc'">
                <object type="image/svg+xml" data="static/img/chart_aboutus.svg"></object>
            </div>
            <div class="heading emphasis">
                <h2>
                    <p>
                        石川エナジーリサーチでは<br />
                        熱エネルギーを利用したシステム事業を中心に<br />
                        新たなソリューションを創造しています
                    </p>
                </h2>
            </div>
        </section>
    </section>
</template>

<script>
import CompVideo from './CompVideo.vue';
export default {
    components: {
        CompVideo,
    },
    props: ['sliderImage'],
    data() {
        return {
            // Swiper設定
            swiperOptions: {
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                speed: 700,
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                // Break Points
                breakpoints: {
                    375: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    769: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    1025: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                },
            },
        };
    },
    methods: {},
};
</script>

<!-- ==================================================
CSS Style
=================================================== -->
<style scoped lang="scss">
// ****************************************
// SP Layout
// ****************************************
// *************** Image Slider ***************
.image-slider {
    width: 100%;
    background: $black;
    padding: 7px 0;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    .swiper-container {
        width: 100%;
        height: 100%;
        .swiper-wrapper {
            .swiper-slide {
                > img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .swiper-button-prev,
        .swiper-button-next {
            width: 20px;
            height: 20px;
            transform: translateY(-50%);
            > img {
                width: 100%;
                height: auto;
            }
            &::after {
                content: normal;
            }
        }
    }
}
.heading_title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 30px;
    border: solid 1px $af-green;
    margin-bottom: 2rem;
    background-color: $white;
}
// common
.row-box--between {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > div {
        display: flex;
        align-items: center;
    }
}
.row-box--stretch {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > div {
        display: flex;
        align-items: center;
    }
}

.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
    &.emphasis {
        h2 {
            text-align: center;
            font-size: 1.6rem;
            line-height: 2.56rem;
            margin-bottom: 2rem;
            letter-spacing: 0.1rem;
            border-top: solid 2px $af-green;
            color: $af-darkgreen;
            padding: 2rem 4rem;
        }
    }
    h2 {
        text-align: center;
        font-size: 1.6rem;
        line-height: 2.56rem;
        margin-bottom: 2rem;
        &.shade {
            text-shadow: 3px 3px 1px $smoke-skyblue;
            letter-spacing: 0.25rem;
        }
        &.quality {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 120px;
            background-image: url('../static/img/bgimg_quality-sp.png');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }
    h3 {
        font-size: 1.6rem;
        line-height: 2.56rem;
        text-align: center;
        margin: auto;
        &.table-title {
            font-size: 1.6rem;
        }
    }
    h4 {
        font-size: 1.4rem;
        line-height: 2.24rem;
        margin: auto;
        text-align: center;
        text-align: justify;
        text-justify: inter-ideograph;
    }
    &_section {
        margin: 0 auto 2rem;
        text-align: center;
        h2 {
            display: inline;
            border-bottom: solid 2px $af-yellowgreen;
            text-align: center;
            font-size: 1.6rem;
            padding-bottom: 0.5rem;
        }
    }
    &_title {
        font-size: 3.2rem;
        text-align: center;
        &-sub {
            font-size: 1.8rem;
            font-weight: normal;
            color: #007a0c;
            text-align: center;
            margin-top: 20px;
        }
    }
}
.lead {
    margin-top: 35px;
    &_text {
        text-align: center;
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 1.5;
    }
}
.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40px 20px 40px;
    background: #fff;
}
.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    &_title {
        font-size: 1.8rem;
        text-align: center;
        &-sub {
            font-size: 1.4rem;
            font-weight: normal;
            color: #007a0c;
            text-align: center;
            margin-top: 8px;
        }
    }
}
.lead {
    margin-top: 20px;
    &_text {
        text-align: center;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.5;
        margin-top: 20px;
        white-space: nowrap;
    }
}
.read-box {
    font-size: 1.6rem;
    line-height: 2.56rem;
    margin-top: 2rem;
}
.option-box {
    border: solid 2px $af-blue;
    background-color: $white;
    width: 100%;
    padding: 2rem;
    margin-top: 1rem;
    border-radius: 8px;
    img {
        width: 100%;
    }
    p {
        font-size: 1.5rem;
        line-height: 2.4rem;
    }
}
.flight-assist {
    width: 100%;
    div {
        width: 100%;
        object {
            width: 100%;
        }
    }
}
// contents
.content-box {
    // FEATURE01 ********************
    &--01 {
        margin-top: 40px;
        position: relative;
        z-index: 1;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            margin-top: 5%;
            transform: skewY(-5deg);
            transform-origin: bottom left;
            z-index: -1;
            width: 100%;
            height: 100%;
            background-color: $af-feature-mat;
        }
        .images {
            &--01 {
                width: 100%;
                margin-top: 10px;
                &_photo {
                    width: 100%;
                    height: 200px;
                    background: #333;
                }
            }
            &--02 {
                display: flex;
                justify-content: center;
                &_img {
                    width: 100%;
                    img {
                        width: 100%;
                    }
                }
                &_photo {
                    width: 90%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .feature {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            .caption {
                display: flex;
                flex-direction: column;
                align-items: center;
                &_title {
                    margin-top: 12px;
                    width: 200px;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    &-sub {
                        p {
                            font-size: 1.4rem;
                            font-weight: bold;
                            text-align: center;
                            line-height: 1.5;
                            margin-top: 10px;
                        }
                    }
                }
                &_text {
                    line-height: 1.5;
                    margin-top: 15px;
                }
            }
            .link {
                width: 100%;
                margin-top: 30px;
                &_btn {
                    @include btn-detail-sp();
                }
            }
        }
    }
    // FEATURE02 ********************
    &--02 {
        .feature {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 30px;
            .col-box {
                width: 100%;
                background: #fff;
                border: solid 1px #b1d2e7;
                padding: 0 10px 10px;
                margin-top: 20px;
                &:first-child {
                    margin-top: 0;
                }
                .images {
                    &_photo {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                .caption_text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100px;
                    font-size: 1.6rem;
                    font-weight: bold;
                    line-height: 1.6;
                    text-align: center;
                }
            }
        }
        .link {
            width: 100%;
            margin-top: 30px;
            &_btn {
                @include btn-detail-sp();
            }
        }
    }
    // FEATURE03 ********************
    &--03 {
        position: relative;
        z-index: 1;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            margin-top: 5%;
            transform: skewY(-5deg);
            transform-origin: bottom left;
            z-index: -1;
            width: 100%;
            height: 100%;
            background-color: $af-feature-mat;
        }
        .images {
            &--01 {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                &_img {
                    width: 80%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            &--02 {
                display: flex;
                justify-content: center;
                margin-top: 40px;
                &_img {
                    width: 100%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    object {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .caption {
            &--01 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                background: #f7f7e8;
                border: solid 2px #82c580;
                border-radius: 12px;
                padding: 20px 20px;
                margin-top: 30px;
                &_title {
                    font-size: 1.6rem;
                    font-weight: bold;
                }
                &_text {
                    font-size: 1.6rem;
                    line-height: 1.6;
                    margin-top: 15px;
                    text-align: justify;
                }
            }
            &--02 {
                margin-top: 30px;
                &_text {
                    font-size: 1.6rem;
                    line-height: 1.8;
                    font-weight: bold;
                    text-align: center;
                    .marker {
                        background: linear-gradient(transparent 50%, #eaff80 0);
                    }
                }
            }
        }
        .balloon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            background: #fff;
            border: solid 2px #0b4271;
            border-radius: 8px;
            padding: 10px;
            margin-top: 30px;
            &_text {
                font-size: 1.4rem;
                .strong {
                    font-size: 1.8rem;
                    font-weight: bold;
                    color: #e6001c;
                    .mini {
                        font-size: 1.4rem;
                    }
                }
            }
            position: relative;
            @include triangle(
                $width: 20px,
                $height: 20px,
                $top: -20px,
                $left: 50%,
                $translate: translate(-50%)
            );
        }
        .link {
            width: 100%;
            margin-top: 30px;
            &_btn {
                @include btn-detail-sp();
            }
        }
    }
    // ABOUT US ********************
    &--04 {
        .images {
            &--01 {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                &_img {
                    width: 80%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            &--02 {
                display: flex;
                justify-content: center;
                margin-top: 40px;
                &_img {
                    width: 100%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    object {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .caption {
            &--01 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                background: #f7f7e8;
                border: solid 2px #82c580;
                border-radius: 12px;
                padding: 20px 20px;
                margin-top: 30px;
                &_title {
                    font-size: 1.6rem;
                    font-weight: bold;
                }
                &_text {
                    font-size: 1.6rem;
                    line-height: 1.6;
                    margin-top: 15px;
                    text-align: justify;
                }
            }
            &--02 {
                margin-top: 30px;
                &_text {
                    font-size: 1.6rem;
                    line-height: 1.8;
                    font-weight: bold;
                    text-align: center;
                    .marker {
                        background: linear-gradient(transparent 50%, #eaff80 0);
                    }
                }
            }
        }
        .balloon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            background: #fff;
            border: solid 2px #0b4271;
            border-radius: 8px;
            padding: 10px;
            margin-top: 30px;
            &_text {
                font-size: 1.4rem;
                .strong {
                    font-size: 1.8rem;
                    font-weight: bold;
                    color: #e6001c;
                    .mini {
                        font-size: 1.4rem;
                    }
                }
            }
            position: relative;
            @include triangle(
                $width: 20px,
                $height: 20px,
                $top: -20px,
                $left: 50%,
                $translate: translate(-50%)
            );
        }
        .link {
            width: 100%;
            margin-top: 30px;
            &_btn {
                @include btn-detail-sp();
            }
        }
    }
}

// ****************************************
// SPL Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 768px) {
    // *************** Image Slider ***************
    .image-slider {
        width: 100%;
        background: $black;
        padding: 7px 0;
        display: flex;
        justify-content: center;
        margin-top: 25px;
        margin-bottom: 60px;
        .swiper-container {
            width: 100%;
            height: 100%;
            .swiper-wrapper {
                .swiper-slide {
                    > img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .swiper-button-prev,
            .swiper-button-next {
                width: 24px;
                height: 24px;
                transform: translateY(-50%);
                > img {
                    width: 100%;
                    height: auto;
                }
                &::after {
                    content: normal;
                }
            }
        }
    }
    .heading_title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 30px;
        border: solid 1px $af-green;
        margin-bottom: 2rem;
        background-color: $white;
    }
    // common
    .row-box--between {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .row-box--stretch {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }

    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
        width: 100%;
        &.emphasis {
            h2 {
                text-align: center;
                font-size: 2rem;
                line-height: 1.6;
                margin: 2rem auto;
                letter-spacing: 0.1rem;
            }
        }
        h2 {
            text-align: center;
            font-size: 2.4rem;
            line-height: 3.84rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            &.quality {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 32vw;
                background-image: url('../static/img/bgimg_quality-sp.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }
        h3 {
            font-size: 1.6rem;
            line-height: 2.56rem;
            text-align: center;
            margin: auto;
            &.table-title {
                font-size: 1.6rem;
            }
        }
        h4 {
            font-size: 1.4rem;
            line-height: 2.24rem;
            margin: auto;
            text-align: center;
            text-align: justify;
            text-justify: inter-ideograph;
        }
        &_section {
            margin: 0 auto 2rem;
            text-align: center;
            h2 {
                display: inline;
                border-bottom: solid 2px $af-yellowgreen;
                text-align: center;
                font-size: 1.6rem;
                padding-bottom: 0.5rem;
            }
        }
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .lead {
        margin-top: 35px;
        &_text {
            text-align: center;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;
        }
    }
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        &_title {
            font-size: 1.8rem;
            text-align: center;
            margin-top: 20px;
            &-sub {
                font-size: 1.4rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 8px;
            }
        }
    }
    .lead {
        margin-top: 20px;
        &_text {
            text-align: center;
            font-size: 1.6rem;
            font-weight: bold;
            line-height: 1.5;
            margin-top: 20px;
            white-space: nowrap;
        }
    }
    .read-box {
        font-size: 1.6rem;
        max-width: 880px;
        line-height: 2.56rem;
        margin: 2rem auto;
    }
    .option-box {
        border: solid 4px $smoke-skyblue;
        background-color: $af-feature-mat;
        width: 100%;
        padding: 2rem;
        margin: 2rem auto;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        div {
            width: 100%;
            img {
                width: 100%;
            }
        }
        p {
            width: 80%;
            font-size: 1.6rem;
            line-height: 2.56rem;
            padding-left: 20px;
        }
    }
    .flight-assist {
        max-width: 100%;
        div {
            width: 100%;
            object {
                width: 100%;
                display: block;
                margin: auto;
            }
        }
    }
    // contents
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        padding: 40px 20px 40px;
        background: #fff;
        margin: auto;
    }
    .content-box {
        // FEATURE01 ********************
        &--01 {
            position: relative;
            z-index: 1;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                margin-top: 5%;
                transform: skewY(-5deg);
                transform-origin: bottom left;
                z-index: -1;
                width: 100%;
                height: 100%;
                background-color: $af-feature-mat;
            }
            .images {
                &--01 {
                    width: 100%;
                    margin-top: 10px;
                    &_photo {
                        width: 100%;
                        height: 200px;
                        background: #333;
                    }
                }
                &--02 {
                    display: flex;
                    justify-content: center;
                    &_img {
                        margin-top: 4rem;
                        width: 100%;
                        img {
                            display: block;
                            width: 100%;
                            margin: auto;
                        }
                        object {
                            display: block;
                            width: auto;
                            margin: auto;
                        }
                    }
                    &_photo {
                        width: 90%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .feature {
                display: flex;
                flex-direction: column;
                margin-top: 30px;
                .caption {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    &_title {
                        margin-top: 12px;
                        width: 200px;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        &-sub {
                            p {
                                font-size: 1.4rem;
                                font-weight: bold;
                                text-align: center;
                                line-height: 1.5;
                                margin-top: 10px;
                            }
                        }
                    }
                    &_text {
                        line-height: 1.5;
                        margin-top: 15px;
                    }
                }
                .link {
                    width: 100%;
                    margin-top: 30px;
                    &_btn {
                        @include btn-detail-sp();
                    }
                }
            }
            video {
                width: 720px;
            }
        }
        // FEATURE02 ********************
        &--02 {
            .feature {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                max-width: 1282px;
                margin-top: 30px;
                .col-box {
                    align-items: center;
                    width: 100%;
                    max-width: 400px;
                    background: #fff;
                    border: solid 1px #b1d2e7;
                    padding: 20px 10px 10px;
                    margin-top: 20px;
                    .images {
                        &_photo {
                            width: 100%;
                            height: auto;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                    .caption_text {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100px;
                        font-size: 2rem;
                        font-weight: bold;
                        line-height: 3.2rem;
                        text-align: center;
                        margin-bottom: 2rem;
                    }
                }
            }
            .link {
                width: 100%;
                margin-top: 30px;
                &_btn {
                    @include btn-detail-sp();
                }
            }
        }
        // FEATURE03 ********************
        &--03 {
            position: relative;
            z-index: 1;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                margin-top: 5%;
                transform: skewY(-5deg);
                transform-origin: bottom left;
                z-index: -1;
                width: 100%;
                height: 100%;
                background-color: $af-feature-mat;
            }
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 30px;
                    &_img {
                        width: 80%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                &--02 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        max-width: 880px;
                        margin: auto;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        object {
                            display: block;
                            width: auto;
                            height: auto;
                            margin: auto;
                        }
                    }
                }
            }
            .caption {
                &--01 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    background: #f7f7e8;
                    border: solid 2px #82c580;
                    border-radius: 12px;
                    padding: 20px 20px;
                    margin-top: 30px;
                    &_title {
                        font-size: 1.6rem;
                        font-weight: bold;
                    }
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.6;
                        margin-top: 15px;
                        text-align: justify;
                        max-width: 880px;
                    }
                }
                &--02 {
                    margin-top: 30px;
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.8;
                        font-weight: bold;
                        text-align: center;
                        .marker {
                            background: linear-gradient(transparent 50%, #eaff80 0);
                        }
                    }
                }
            }
            .balloon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                background: #fff;
                border: solid 2px #0b4271;
                border-radius: 8px;
                padding: 10px;
                margin-top: 30px;
                &_text {
                    font-size: 1.4rem;
                    .strong {
                        font-size: 1.8rem;
                        font-weight: bold;
                        color: #e6001c;
                        .mini {
                            font-size: 1.4rem;
                        }
                    }
                }
                position: relative;
                @include triangle(
                    $width: 20px,
                    $height: 20px,
                    $top: -20px,
                    $left: 50%,
                    $translate: translate(-50%)
                );
            }
            .link {
                width: 100%;
                margin-top: 30px;
                &_btn {
                    @include btn-detail-sp();
                }
            }
        }
        // ABOUT US ********************
        &--04 {
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 30px;
                    &_img {
                        width: 80%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                &--02 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        max-width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            margin: auto;
                        }
                        object {
                            display: block;
                            width: 100%;
                            height: auto;
                            margin: auto;
                        }
                    }
                }
            }
            .caption {
                &--01 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    background: #f7f7e8;
                    border: solid 2px #82c580;
                    border-radius: 12px;
                    padding: 20px 20px;
                    margin-top: 30px;
                    &_title {
                        font-size: 1.6rem;
                        font-weight: bold;
                    }
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.6;
                        margin-top: 15px;
                        text-align: justify;
                    }
                }
                &--02 {
                    margin-top: 30px;
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.8;
                        font-weight: bold;
                        text-align: center;
                        .marker {
                            background: linear-gradient(transparent 50%, #eaff80 0);
                        }
                    }
                }
            }
            .balloon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                background: #fff;
                border: solid 2px #0b4271;
                border-radius: 8px;
                padding: 10px;
                margin-top: 30px;
                &_text {
                    font-size: 1.4rem;
                    .strong {
                        font-size: 1.8rem;
                        font-weight: bold;
                        color: #e6001c;
                        .mini {
                            font-size: 1.4rem;
                        }
                    }
                }
                position: relative;
                @include triangle(
                    $width: 20px,
                    $height: 20px,
                    $top: -20px,
                    $left: 50%,
                    $translate: translate(-50%)
                );
            }
            .link {
                width: 100%;
                margin-top: 30px;
                &_btn {
                    @include btn-detail-sp();
                }
            }
        }
    }
}
// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 769px) and (max-width: 1024px) {
    // *************** Image Slider ***************
    .image-slider {
        width: 100%;
        background: $black;
        padding: 7px 0;
        display: flex;
        justify-content: center;
        margin-top: 25px;
        .swiper-container {
            width: 100%;
            height: 100%;
            .swiper-wrapper {
                .swiper-slide {
                    > img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .swiper-button-prev,
            .swiper-button-next {
                width: 28px;
                height: 28px;
                transform: translateY(-50%);
                > img {
                    width: 100%;
                    height: auto;
                }
                &::after {
                    content: normal;
                }
            }
        }
    }
    .heading_title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 30px;
        border: solid 1px $af-green;
        margin-bottom: 2rem;
        background-color: $white;
    }
    // common
    .row-box--between {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .row-box--stretch {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
        width: 100%;
        &.emphasis {
            h2 {
                text-align: center;
                font-size: 2.4rem;
                line-height: 3.84rem;
                margin: 2rem auto;
                letter-spacing: 0.1rem;
            }
        }
        h2 {
            text-align: center;
            font-size: 2.8rem;
            line-height: 4.48rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            &.quality {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 880px;
                height: 300px;
                background-image: url('../static/img/bgimg_quality-sp.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }
        h3 {
            font-size: 1.6rem;
            line-height: 2.56rem;
            text-align: center;
            margin: auto;
            &.table-title {
                font-size: 1.6rem;
            }
        }
        h4 {
            font-size: 1.4rem;
            line-height: 2.24rem;
            margin: auto;
            text-align: center;
            text-align: justify;
            text-justify: inter-ideograph;
        }
        &_section {
            margin: 0 auto 2rem;
            text-align: center;
            h2 {
                display: inline;
                border-bottom: solid 2px $af-yellowgreen;
                text-align: center;
                font-size: 1.6rem;
                padding-bottom: 0.5rem;
            }
        }
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .lead {
        margin-top: 35px;
        &_text {
            text-align: center;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;
        }
    }
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        &_title {
            font-size: 1.8rem;
            text-align: center;
            &-sub {
                font-size: 1.4rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 8px;
            }
        }
    }
    .lead {
        margin-top: 20px;
        &_text {
            text-align: center;
            font-size: 1.6rem;
            font-weight: bold;
            line-height: 1.5;
            margin-top: 20px;
            white-space: nowrap;
        }
    }
    .read-box {
        font-size: 1.6rem;
        max-width: 880px;
        line-height: 2.56rem;
        margin: 2rem auto;
    }
    .option-box {
        border: solid 4px $smoke-skyblue;
        background-color: $af-feature-mat;
        width: 100%;
        max-width: 880px;
        padding: 2rem;
        margin: 2rem auto;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        img {
            width: 100%;
        }
        p {
            font-size: 1.8rem;
            line-height: 2.88rem;
            padding-left: 20px;
            max-width: 66.6%;
        }
    }
    .flight-assist {
        max-width: 100%;
        margin: 2rem auto;
        div {
            width: 100%;
            margin: 2rem auto;
            object {
                width: auto;
                display: block;
                margin: auto;
            }
        }
    }
    // contents
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        padding: 40px 20px 40px;
        background: #fff;
        margin: auto;
    }
    .content-box {
        // FEATURE01 ********************
        &--01 {
            margin-top: 80px;
            .images {
                &--01 {
                    width: 100%;
                    margin-top: 10px;
                    &_photo {
                        width: 100%;
                        height: 200px;
                        background: #333;
                    }
                }
                &--02 {
                    display: flex;
                    justify-content: center;
                    &_img {
                        margin-top: 4rem;
                        width: 100%;
                        img {
                            display: block;
                            width: auto;
                            margin: auto;
                        }
                        object {
                            display: block;
                            width: auto;
                            margin: auto;
                        }
                    }
                    &_photo {
                        width: 90%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .feature {
                display: flex;
                flex-direction: column;
                margin-top: 30px;
                .caption {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    &_title {
                        margin-top: 12px;
                        width: 200px;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        &-sub {
                            p {
                                font-size: 1.4rem;
                                font-weight: bold;
                                text-align: center;
                                line-height: 1.5;
                                margin-top: 10px;
                            }
                        }
                    }
                    &_text {
                        line-height: 1.5;
                        margin-top: 15px;
                    }
                }
                .link {
                    width: 100%;
                    margin-top: 30px;
                    &_btn {
                        @include btn-detail-sp();
                    }
                }
            }
            video {
                width: 720px;
            }
        }
        // FEATURE02 ********************
        &--02 {
            .feature {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                max-width: 1282px;
                margin-top: 30px;
                .col-box {
                    align-items: center;
                    width: 32%;
                    background: #fff;
                    border: solid 1px #b1d2e7;
                    padding: 20px 10px 10px;
                    margin-top: 0px;
                    .images {
                        &_photo {
                            width: 100%;
                            height: auto;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                    .caption_text {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 120px;
                        font-size: 1.8rem;
                        font-weight: bold;
                        line-height: 2.88rem;
                        text-align: center;
                    }
                }
            }
            .link {
                width: 100%;
                margin-top: 30px;
                &_btn {
                    @include btn-detail-sp();
                }
            }
        }
        // FEATURE03 ********************
        &--03 {
            padding-top: 80px;
            margin-bottom: 40px;
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 30px;
                    &_img {
                        width: 80%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                &--02 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        max-width: 880px;
                        margin: auto;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        object {
                            display: block;
                            width: auto;
                            height: auto;
                            margin: auto;
                        }
                    }
                }
            }
            .caption {
                &--01 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    background: #f7f7e8;
                    border: solid 2px #82c580;
                    border-radius: 12px;
                    padding: 20px 20px;
                    margin-top: 30px;
                    &_title {
                        font-size: 1.6rem;
                        font-weight: bold;
                    }
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.6;
                        margin-top: 15px;
                        text-align: justify;
                        max-width: 880px;
                    }
                }
                &--02 {
                    margin-top: 30px;
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.8;
                        font-weight: bold;
                        text-align: center;
                        .marker {
                            background: linear-gradient(transparent 50%, #eaff80 0);
                        }
                    }
                }
            }
            .balloon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                background: #fff;
                border: solid 2px #0b4271;
                border-radius: 8px;
                padding: 10px;
                margin-top: 30px;
                &_text {
                    font-size: 1.4rem;
                    .strong {
                        font-size: 1.8rem;
                        font-weight: bold;
                        color: #e6001c;
                        .mini {
                            font-size: 1.4rem;
                        }
                    }
                }
                position: relative;
                @include triangle(
                    $width: 20px,
                    $height: 20px,
                    $top: -20px,
                    $left: 50%,
                    $translate: translate(-50%)
                );
            }
            .link {
                width: 100%;
                margin-top: 30px;
                &_btn {
                    @include btn-detail-sp();
                }
            }
        }
        // ABOUT US ********************
        &--04 {
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 30px;
                    &_img {
                        width: 80%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                &--02 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        max-width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            margin: auto;
                        }
                        object {
                            display: block;
                            width: auto;
                            height: auto;
                            margin: auto;
                        }
                    }
                }
            }
            .caption {
                &--01 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    background: #f7f7e8;
                    border: solid 2px #82c580;
                    border-radius: 12px;
                    padding: 20px 20px;
                    margin-top: 30px;
                    &_title {
                        font-size: 1.6rem;
                        font-weight: bold;
                    }
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.6;
                        margin-top: 15px;
                        text-align: justify;
                    }
                }
                &--02 {
                    margin-top: 30px;
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.8;
                        font-weight: bold;
                        text-align: center;
                        .marker {
                            background: linear-gradient(transparent 50%, #eaff80 0);
                        }
                    }
                }
            }
            .balloon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                background: #fff;
                border: solid 2px #0b4271;
                border-radius: 8px;
                padding: 10px;
                margin-top: 30px;
                &_text {
                    font-size: 1.4rem;
                    .strong {
                        font-size: 1.8rem;
                        font-weight: bold;
                        color: #e6001c;
                        .mini {
                            font-size: 1.4rem;
                        }
                    }
                }
                position: relative;
                @include triangle(
                    $width: 20px,
                    $height: 20px,
                    $top: -20px,
                    $left: 50%,
                    $translate: translate(-50%)
                );
            }
            .link {
                width: 100%;
                margin-top: 30px;
                &_btn {
                    @include btn-detail-sp();
                }
            }
        }
    }
}
// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1025px) {
    // *************** Image Slider ***************
    .image-slider {
        width: 100%;
        background: $black;
        padding: 7px 0;
        display: flex;
        justify-content: center;
        margin-top: 25px;
        .swiper-container {
            width: 1194px;
            height: 100%;
            min-width: 1194px;
            transform: translateX(10px);
            .swiper-wrapper {
                .swiper-slide {
                    > img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .swiper-button-prev,
            .swiper-button-next {
                width: 20px;
                height: 20px;
                transform: translateY(-50%);
                > img {
                    width: 100%;
                    height: auto;
                }
                &::after {
                    content: normal;
                }
            }
        }
    }
    .heading_title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 30px;
        border: solid 1px $af-green;
        margin-bottom: 2rem;
        background-color: $white;
    }
    // common
    .row-box--between {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .row-box--stretch {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        &.emphasis {
            h2 {
                text-align: center;
                font-size: 2.4rem;
                line-height: 3.84rem;
                margin: 2rem auto;
                letter-spacing: 0.1rem;
            }
        }
        h2 {
            text-align: center;
            font-size: 2.8rem;
            line-height: 4.48rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            &.quality {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 880px;
                height: 300px;
                background-image: url('../static/img/bgimg_quality-sp.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }
        h3 {
            font-size: 1.6rem;
            line-height: 2.56rem;
            text-align: center;
            margin: auto;
            &.table-title {
                font-size: 1.6rem;
            }
        }
        h4 {
            font-size: 1.4rem;
            line-height: 2.24rem;
            margin: auto;
            text-align: center;
            text-align: justify;
            text-justify: inter-ideograph;
        }
        &_section {
            margin: 0 auto 2rem;
            text-align: center;
            h2 {
                display: inline;
                border-bottom: solid 2px $af-yellowgreen;
                text-align: center;
                font-size: 1.6rem;
                padding-bottom: 0.5rem;
            }
        }
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .lead {
        margin-top: 35px;
        &_text {
            text-align: center;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;
        }
    }
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
        &_title {
            font-size: 1.8rem;
            text-align: center;
            &-sub {
                font-size: 1.4rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 8px;
            }
        }
    }
    .lead {
        margin-top: 20px;
        &_text {
            text-align: center;
            font-size: 1.6rem;
            font-weight: bold;
            line-height: 1.5;
            margin-top: 20px;
            white-space: nowrap;
        }
    }
    .read-box {
        font-size: 1.6rem;
        max-width: 880px;
        line-height: 2.56rem;
        margin: 2rem auto;
    }
    .option-box {
        border: solid 4px $smoke-skyblue;
        background-color: $af-feature-mat;
        width: 100%;
        max-width: 880px;
        height: 193px; // for IE
        padding: 2rem;
        margin: 2rem auto;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        img {
            width: 100%;
        }
        p {
            font-size: 2rem;
            line-height: 3.2rem;
            padding-left: 20px;
            margin-top: 0; // for IE
        }
    }
    .flight-assist {
        max-width: 100%;
        margin: 2rem auto;
        div {
            width: 100%;
            margin: 2rem auto;
            object {
                width: auto;
                display: block;
                margin: auto;
            }
        }
    }
    // contents
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-width: 1280px;
        padding: 40px 20px 40px;
        background: #fff;
        margin: auto;
    }
    .content-box {
        // FEATURE01 ********************
        &--01 {
            margin: 80px auto 40px;
            padding: 80px auto 40px;
            .images {
                &--01 {
                    width: 100%;
                    margin-top: 10px;
                    &_photo {
                        width: 100%;
                        height: 200px;
                        background: #333;
                    }
                }
                &--02 {
                    display: flex;
                    justify-content: center;
                    &_img {
                        margin-top: 4rem;
                        width: 100%;
                        img {
                            display: block;
                            width: auto;
                            margin: auto;
                        }
                        object {
                            display: block;
                            width: auto;
                            margin: auto;
                        }
                    }
                    &_photo {
                        width: 90%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .feature {
                display: flex;
                flex-direction: column;
                margin-top: 30px;
                .caption {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    &_title {
                        margin-top: 12px;
                        width: 200px;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        &-sub {
                            p {
                                font-size: 1.4rem;
                                font-weight: bold;
                                text-align: center;
                                line-height: 1.5;
                                margin-top: 10px;
                            }
                        }
                    }
                    &_text {
                        line-height: 1.5;
                        margin-top: 15px;
                    }
                }
                .link {
                    width: 100%;
                    margin-top: 30px;
                    &_btn {
                        @include btn-detail-sp();
                    }
                }
            }
            video {
                width: 720px;
            }
        }
        // FEATURE02 ********************
        &--02 {
            padding-top: 0;
            .feature {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                max-width: 1282px;
                margin-top: 30px;
                .col-box {
                    align-items: center;
                    width: 32%;
                    background: #fff;
                    border: solid 1px #b1d2e7;
                    padding: 20px 10px 10px;
                    margin-top: 0px;
                    .images {
                        &_photo {
                            width: 100%;
                            height: auto;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                    .caption_text {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100px;
                        font-size: 2rem;
                        font-weight: bold;
                        line-height: 3.2rem;
                        text-align: center;
                        margin-bottom: 2rem;
                        width: 388px; // for IE
                        margin-top: 0; // for IE
                    }
                }
            }
            .link {
                width: 100%;
                margin-top: 30px;
                &_btn {
                    @include btn-detail-sp();
                }
            }
        }
        // FEATURE03 ********************
        &--03 {
            padding-top: 100px;
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 30px;
                    &_img {
                        width: 80%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                &--02 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        max-width: 880px;
                        margin: auto;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        object {
                            display: block;
                            width: auto;
                            height: auto;
                            margin: auto;
                        }
                    }
                }
            }
            .caption {
                &--01 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    background: #f7f7e8;
                    border: solid 2px #82c580;
                    border-radius: 12px;
                    padding: 20px 20px;
                    margin-top: 30px;
                    &_title {
                        font-size: 1.6rem;
                        font-weight: bold;
                    }
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.6;
                        margin-top: 15px;
                        text-align: justify;
                        max-width: 880px;
                    }
                }
                &--02 {
                    margin-top: 30px;
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.8;
                        font-weight: bold;
                        text-align: center;
                        .marker {
                            background: linear-gradient(transparent 50%, #eaff80 0);
                        }
                    }
                }
            }
            .balloon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                background: #fff;
                border: solid 2px #0b4271;
                border-radius: 8px;
                padding: 10px;
                margin-top: 30px;
                &_text {
                    font-size: 1.4rem;
                    .strong {
                        font-size: 1.8rem;
                        font-weight: bold;
                        color: #e6001c;
                        .mini {
                            font-size: 1.4rem;
                        }
                    }
                }
                position: relative;
                @include triangle(
                    $width: 20px,
                    $height: 20px,
                    $top: -20px,
                    $left: 50%,
                    $translate: translate(-50%)
                );
            }
            .link {
                width: 100%;
                margin-top: 30px;
                &_btn {
                    @include btn-detail-sp();
                }
            }
        }
        // ABOUT US ********************
        &--04 {
            padding-top: 80px;
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 30px;
                    &_img {
                        width: 80%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                &--02 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        max-width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            margin: auto;
                        }
                        object {
                            display: block;
                            width: auto;
                            height: auto;
                            margin: auto;
                        }
                    }
                }
            }
            .caption {
                &--01 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    background: #f7f7e8;
                    border: solid 2px #82c580;
                    border-radius: 12px;
                    padding: 20px 20px;
                    margin-top: 30px;
                    &_title {
                        font-size: 1.6rem;
                        font-weight: bold;
                    }
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.6;
                        margin-top: 15px;
                        text-align: justify;
                    }
                }
                &--02 {
                    margin-top: 30px;
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.8;
                        font-weight: bold;
                        text-align: center;
                        .marker {
                            background: linear-gradient(transparent 50%, #eaff80 0);
                        }
                    }
                }
            }
            .balloon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                background: #fff;
                border: solid 2px #0b4271;
                border-radius: 8px;
                padding: 10px;
                margin-top: 30px;
                &_text {
                    font-size: 1.4rem;
                    .strong {
                        font-size: 1.8rem;
                        font-weight: bold;
                        color: #e6001c;
                        .mini {
                            font-size: 1.4rem;
                        }
                    }
                }
                position: relative;
                @include triangle(
                    $width: 20px,
                    $height: 20px,
                    $top: -20px,
                    $left: 50%,
                    $translate: translate(-50%)
                );
            }
            .link {
                width: 100%;
                margin-top: 30px;
                &_btn {
                    @include btn-detail-sp();
                }
            }
        }
    }
}
</style>
