var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.$route.path !== "/inquiry" && _vm.$route.path !== "/question",
            expression:
              "$route.path !== '/inquiry' && $route.path !== '/question'"
          }
        ],
        staticClass: "content-box content-box--01"
      },
      [
        _c("div", { staticClass: "content-box_inner" }, [
          _c(
            "div",
            { staticClass: "inquiry" },
            [
              _c(
                "router-link",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$mq === "sp",
                      expression: "$mq === 'sp'"
                    }
                  ],
                  staticClass: "inquiry_btn",
                  attrs: { to: "/inquiry" }
                },
                [_vm._v("お問い合わせはこちらから")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.$mq === "spl" ||
                        _vm.$mq === "tb" ||
                        _vm.$mq === "pc",
                      expression:
                        "$mq === 'spl' || $mq === 'tb' || $mq === 'pc'"
                    }
                  ],
                  staticClass: "inquiry_btn",
                  attrs: { to: "/inquiry" }
                },
                [_vm._v("Agri Flyerに関するお問い合わせはこちらから")]
              )
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("section", { staticClass: "content-box content-box--02" }, [
      _c("div", { staticClass: "content-box_inner" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("nav", { staticClass: "footer-navi" }, [
          _c(
            "ul",
            _vm._l(_vm.menus, function(menu) {
              return _c(
                "li",
                { key: menu.item },
                [
                  _c("router-link", { attrs: { to: menu.path } }, [
                    _vm._v(_vm._s(menu.name))
                  ])
                ],
                1
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "com-info" }, [
          _c("p", { staticClass: "com-info_text com-info_text--01" }, [
            _vm._v("\n                    開発・製造・販売元："),
            _vm.$mq === "sp" ? _c("br") : _vm._e(),
            _vm._v(" "),
            _c(
              "a",
              { attrs: { href: "http://ier.co.jp/", target: "_blank" } },
              [_vm._v("（株）石川エナジーリサーチ")]
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "com-info_text com-info_text--02" }, [
            _vm._v("\n                    販売代理店："),
            _vm.$mq === "sp" ? _c("br") : _vm._e(),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.itsim.com/company/",
                  target: "_blank"
                }
              },
              [_vm._v("（株）アイティーシム")]
            )
          ])
        ]),
        _vm._v(" "),
        _vm._m(1)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("div", { staticClass: "logo_title" }, [
        _c("img", {
          attrs: { src: "static/img/AF_logo_w.png", alt: "Agri-Flyer ：ロゴ" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "logo_text" }, [
        _c("p", [_vm._v("IER農業用マルチコプター「アグリフライヤー」")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "copyright" }, [
      _c("p", { staticClass: "copyright_text" }, [
        _vm._v(
          "\n                    ©2019 Itsim Co., Ltd. All Rights Reserved.\n                "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }