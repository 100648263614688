import Vue from 'vue';
import VueRouter from 'vue-router';

// ****************************************
// ページコンポーネント
// ****************************************
// グローバルメニュー ********************
import KeyVisualTop from '../src/components/KeyVisualTop.vue';
import KeyVisualFeature from '../src/components/KeyVisualFeature.vue';
import KeyVisualSpec from '../src/components/KeyVisualSpec.vue';
import KeyVisualSmart from '../src/components/KeyVisualSmart.vue';
import KeyVisualQuestion from '../src/components/KeyVisualQuestion.vue';
import KeyVisualInquiry from '../src/components/KeyVisualInquiry.vue';
import KeyVisualLineup from '../src/components/KeyVisualLineup.vue';
import ContentsTop from '../src/components/ContentsTop.vue';
import ContentsFeature from '../src/components/ContentsFeature.vue';
import ContentsSpec from '../src/components/ContentsSpec.vue';
import ContentsSmart from '../src/components/ContentsSmart.vue';
import ContentsQuestion from '../src/components/ContentsQuestion.vue';
import ContentsInquiry from '../src/components/ContentsInquiry.vue';
import ContentsLineup from '../src/components/ContentsLineup.vue';

// ****************************************
// ルーティング
// ****************************************
Vue.use(VueRouter);

// パスにコンポーネントを登録
const routes = [
    {
        path: '/',
        name: 'top',
        components: {
            default: ContentsTop,
            KeyVisual: KeyVisualTop,
        },
        meta: {
            title: '',
            description: '高性能・高耐久性、 次世代の農薬散布用ドローン「アグリフライヤー」',
        },
    },
    {
        path: '/feature',
        name: 'feature',
        components: {
            default: ContentsFeature,
            KeyVisual: KeyVisualFeature,
        },
        meta: {
            title: ' | Agri Flyerの特徴',
            description: '高性能・高耐久性、 次世代の農薬散布用ドローン「アグリフライヤー」',
        },
    },
    {
        path: '/spec',
        name: 'spec',
        components: {
            default: ContentsSpec,
            KeyVisual: KeyVisualSpec,
        },
        meta: {
            title: ' | スペック',
            description: '高性能・高耐久性、 次世代の農薬散布用ドローン「アグリフライヤー」',
        },
    },
    {
        path: '/smart',
        name: 'smart',
        components: {
            default: ContentsSmart,
            KeyVisual: KeyVisualSmart,
        },
        meta: {
            title: ' | スマート農業って？',
            description: '高性能・高耐久性、 次世代の農薬散布用ドローン「アグリフライヤー」',
        },
    },
    {
        path: '/question',
        name: 'question',
        components: {
            default: ContentsQuestion,
            KeyVisual: KeyVisualQuestion,
        },
        meta: {
            title: ' | よくある質問',
            description: '高性能・高耐久性、 次世代の農薬散布用ドローン「アグリフライヤー」',
        },
    },
    {
        path: '/inquiry',
        name: 'inquiry',
        components: {
            default: ContentsInquiry,
            KeyVisual: KeyVisualInquiry,
        },
        meta: {
            title: ' | お問い合わせ',
            description: '高性能・高耐久性、 次世代の農薬散布用ドローン「アグリフライヤー」',
        },
    },
    {
        path: '/lineup',
        name: 'lineup',
        components: {
            default: ContentsLineup,
            KeyVisual: KeyVisualLineup,
        },
        meta: {
            title: ' | IER製品',
            description: '石川エナジーリサーチのドローン製品',
        },
    },
];

// ****************************************
// ルーターインスタンス
// ****************************************
const router = new VueRouter({
    mode: 'history',
    routes,
    // URLにハッシュがなければ、ページトップに移動
    scrollBehavior(to, from, savedPosition) {
        if (!to.hash) {
            return { x: 0, y: 0 };
        }
    },
});

export default router;
