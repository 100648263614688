var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("section", { staticClass: "content-box" }, [
        _c("div", { staticClass: "heading" }, [
          _c("div", { staticClass: "heading_inner" }, [
            _c("div", { staticClass: "heading_title" }, [
              _c("p", [_vm._v("IER ドローン製品")])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }