var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("section", { staticClass: "content-box" }, [
      _c("div", { staticClass: "heading" }, [
        _c("div", { staticClass: "heading_inner" }, [
          _vm.$mq === "pc"
            ? _c("div", { staticClass: "heading_title-sub" }, [
                _c("object", {
                  attrs: { data: "static/img/img_pagetitle-sfarm.svg" }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(0)
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "smart-farm" }, [
        _vm.$mq === "sp" || _vm.$mq === "spl" || _vm.$mq === "tb"
          ? _c("div", { staticClass: "images_img" }, [
              _c("object", {
                attrs: { data: "static/img/img_sfarm-simbol-sp.svg" }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$mq === "pc"
          ? _c("div", { staticClass: "images_img" }, [
              _c("object", {
                attrs: { data: "static/img/img_sfarm-simbol.svg" }
              })
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading_title" }, [
      _c("p", [
        _vm._v(
          "\n                        スマート農業とドローン\n                    "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }