var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _vm.$mq === "sp"
      ? _c("section", { staticClass: "heading_image" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _vm.$mq === "pc"
      ? _c("section", { staticClass: "heading_image" }, [_vm._m(1)])
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "content-box content-box--01" }, [
      _vm._m(2),
      _vm._v(" "),
      _c("dl", { staticClass: "faq" }, [
        _c("dt", [
          _c("div", { staticClass: "row-box--faq" }, [
            _vm.$mq === "spl" || _vm.$mq === "sp"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("object", {
                    attrs: {
                      type: "image/svg+xml",
                      data: "static/img/headletter-q-sp.svg"
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$mq === "tb" || _vm.$mq === "pc"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("img", {
                    attrs: { src: "static/img/headletter-q-pc.svg" }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", [_vm._v("Agri Flyerの特長を教えてください")])
          ])
        ]),
        _vm._v(" "),
        _c("dd", [
          _c("div", { staticClass: "row-box--faq" }, [
            _vm.$mq === "spl" || _vm.$mq === "sp"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("object", {
                    attrs: {
                      type: "image/svg+xml",
                      data: "static/img/headletter-a-sp.svg"
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$mq === "tb" || _vm.$mq === "pc"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("img", {
                    attrs: { src: "static/img/headletter-a-pc.svg" }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._m(3)
          ])
        ])
      ]),
      _vm._v(" "),
      _c("dl", { staticClass: "faq" }, [
        _c("dt", [
          _c("div", { staticClass: "row-box--faq" }, [
            _vm.$mq === "spl" || _vm.$mq === "sp"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("object", {
                    attrs: {
                      type: "image/svg+xml",
                      data: "static/img/headletter-q-sp.svg"
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$mq === "tb" || _vm.$mq === "pc"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("img", {
                    attrs: { src: "static/img/headletter-q-pc.svg" }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", [
              _vm._v("総重量、飛行時間、散布容量などのスペックを教えてください")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("dd", [
          _c("div", { staticClass: "row-box--faq" }, [
            _vm.$mq === "spl" || _vm.$mq === "sp"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("object", {
                    attrs: {
                      type: "image/svg+xml",
                      data: "static/img/headletter-a-sp.svg"
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$mq === "tb" || _vm.$mq === "pc"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("img", {
                    attrs: { src: "static/img/headletter-a-pc.svg" }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "性能他、上記スペックの一覧は “スペック” ページでご確認いただけます"
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("dl", { staticClass: "faq" }, [
        _c("dt", [
          _c("div", { staticClass: "row-box--faq" }, [
            _vm.$mq === "spl" || _vm.$mq === "sp"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("object", {
                    attrs: {
                      type: "image/svg+xml",
                      data: "static/img/headletter-q-sp.svg"
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$mq === "tb" || _vm.$mq === "pc"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("img", {
                    attrs: { src: "static/img/headletter-q-pc.svg" }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", [_vm._v("自動運転は可能ですか？")])
          ])
        ]),
        _vm._v(" "),
        _c("dd", [
          _c("div", { staticClass: "row-box--faq" }, [
            _vm.$mq === "spl" || _vm.$mq === "sp"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("object", {
                    attrs: {
                      type: "image/svg+xml",
                      data: "static/img/headletter-a-sp.svg"
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$mq === "tb" || _vm.$mq === "pc"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("img", {
                    attrs: { src: "static/img/headletter-a-pc.svg" }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "現モデルは半自動散布となります。ホバリング後に、散布補助モード（M＋モード）に切り替えると、前後進時に散布、スイッチ操作で無散布での横移動も可能です。現在、全自動散布飛行を主体とした次期モデルを開発中です"
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _c("dl", { staticClass: "faq" }, [
        _c("dt", [
          _c("div", { staticClass: "row-box--faq" }, [
            _vm.$mq === "spl" || _vm.$mq === "sp"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("object", {
                    attrs: {
                      type: "image/svg+xml",
                      data: "static/img/headletter-q-sp.svg"
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$mq === "tb" || _vm.$mq === "pc"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("img", {
                    attrs: { src: "static/img/headletter-q-pc.svg" }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", [_vm._v("保険や機体補償等について教えてください")])
          ])
        ]),
        _vm._v(" "),
        _c("dd", [
          _c("div", { staticClass: "row-box--faq" }, [
            _vm.$mq === "spl" || _vm.$mq === "sp"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("object", {
                    attrs: {
                      type: "image/svg+xml",
                      data: "static/img/headletter-a-sp.svg"
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$mq === "tb" || _vm.$mq === "pc"
              ? _c("div", { staticClass: "headletter" }, [
                  _c("img", {
                    attrs: { src: "static/img/headletter-a-pc.svg" }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._m(5)
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "index_image" }, [
      _c("img", { attrs: { src: "static/img/img_faq-sp.png", alt: "FAQ" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "index_image" }, [
      _c("img", { attrs: { src: "static/img/img_faq-pc.png", alt: "FAQ" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h2", { staticClass: "faq" }, [_vm._v("─ Agri Flyer 機体について ─")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("ul", [
        _c("li", [_vm._v("マグネシウム合金製の軽量・高耐久性機体")]),
        _vm._v(" "),
        _c("li", [_vm._v("専用に開発された日本製モーター＆ドライバー")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "要素部品を効率的に統合、煩雑になりがちなハーネス類をすっきりと整理"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("流量センサーを用いたフィードバック制御による定量散布")
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("定量散布と自動一定速飛行による高精度な散布を実現")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "容易な薬剤注入を可能にしたタンク設計。オプションで粒剤散布も可能"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h2", { staticClass: "faq" }, [_vm._v("─ ご購入・運用について ─")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "機体購入初年度は、自賠責保険の保険料は機体価格に付帯させていただいております。2年目以降は、任意にて自賠責保険にご加入いただけます。また、墜落や衝突などの偶発的な事故により、ドローンに生じた財物損害を補償する「動産総合保険」をご用意しており、任意でご加入いただけます。\n                        "
      ),
      _c("ul", { staticClass: "example" }, [
        _c("li", [
          _vm._v(
            "①年間保険料：機体時価（補償額）の６％（例）補償額150万円の場合、9万円"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "②支払額：補償額（免責は補償額の１％）（例）補償額150万円の場合、1.5万円が免責"
          )
        ])
      ]),
      _vm._v(
        "\n                       詳細な説明をご希望の方は、お問い合わせフォームにて必要事項をご入力の上ご送信ください。担当者より、詳しくご説明させていただきます。\n                    "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }