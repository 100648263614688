<template>
    <header class="header">
        <section class="content-box">
            <div class="site-title">
                <h1>
                    <img
                        src="static/img/img_logo-af_horizontal.svg"
                        alt="農薬散布用ドローンアグリフライヤー"
                    />
                </h1>
                <figure>
                    <a href="https://agri-flyer-r.ier-prod.jp/"><img src="static/img/typer-banner-nom.png" alt="知性を備えたニューエディション “type-R” 誕生"></a>
                </figure>
            </div>
            <nav class="global-navi--sp" v-if="$mq === 'sp' || $mq === 'spl' || $mq === 'tb'">
                <a class="menu-icon" @click="menuActive" :class="{ active: isActive }">
                    <span></span>
                    <span></span>
                    <span></span>
                </a>
                <ul :class="{ active: isActive }">
                    <li v-for="menu in menus" @click="menuActive" :key="menu.index">
                        <router-link :to="menu.path" exact>{{ menu.name }}</router-link>
                    </li>
                </ul>
            </nav>
            <nav class="global-navi--pc" v-if="$mq === 'pc'">
                <ul>
                    <li v-for="menu in menus" :key="menu.index">
                        <router-link :to="menu.path" exact>{{ menu.name }}</router-link>
                    </li>
                </ul>
            </nav>
        </section>
<!--         <div class="external" v-if="$mq === 'sp'">
            <a href="https://build-flyer.ier-prod.jp/" target="_blank">測量・空撮用ドローン Build Flyer</a>
        </div>
        <div class="external" v-if="$mq === 'spl' || $mq === 'tb' || $mq === 'pc'">
            <a href="https://build-flyer.ier-prod.jp/" target="_blank">IER製測量・空撮用ドローン「ビルドフライヤー」サイトへ</a>
        </div> -->
    </header>
</template>

<script>
export default {
    props: ['menus'],
    data() {
        return {
            isActive: false,
        };
    },
    methods: {
        menuActive() {
            this.isActive = !this.isActive;
        },
    },
};
</script>

<style scoped lang="scss">
// ****************************************
// SP Layout
// ****************************************
@media screen and (max-width: 599px) {
.header {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 40px;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    .external {
            position: relative;
            top: 36px;
            background: rgba(0, 0, 0, 0.6);
            padding: 0 20px;
            height: 32px;
            display: flex;
            align-items: center;
            border-radius: 0px 0px 12px 12px;
            a {
                font-size: 1.2rem;
                white-space: nowrap;
                color: $white;
                display: flex;
                align-items: center;
                &:link, &:visited {
                    color: $white;
                }
                &:hover {
                    text-decoration: underline;
                    color: $af-orange-blight;
                }
                &::after {
                    content: "";
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    margin-left: 8px;
                    background: url(../static/img/img_icon-external-agri.svg) no-repeat;
                }
            }
        }
}
.content-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #fff;
    padding: 0 20px;
    box-shadow: 0px 4px 4px 1px rgba(0,0,0,0.25);
}
.site-title {
    width: 120px;
    height: auto;
    img {
        width: 100%;
        height: auto;
    }
    figure {
        display: none;
    }
}
.global-navi--sp {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    .menu-icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all 0.4s;
        width: 40px;
        height: 40px;
        padding: 10px 5px;
        margin-right: 15px;
        span {
            display: inline-block;
            transition: all 0.4s;
            width: 100%;
            height: 2px;
            background-color: $black;
            border-radius: 4px;
            &:nth-of-type(1) {
                top: 0;
            }
            &:nth-of-type(2) {
                top: 20px;
            }
            &:nth-of-type(3) {
                bottom: 0;
            }
        }
        &.active {
            span {
                &:nth-of-type(1) {
                    -webkit-transform: translateY(9px) rotate(-45deg);
                    transform: translateY(9px) rotate(-45deg);
                }
                &:nth-of-type(2) {
                    opacity: 0;
                }
                &:nth-of-type(3) {
                    -webkit-transform: translateY(-9px) rotate(45deg);
                    transform: translateY(-9px) rotate(45deg);
                }
            }
        }
    }
    ul {
        width: 220px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        list-style: none;
        background: #333;
        transition: all 0.4s;
        position: absolute;
        right: -220px;
        top: 40px;
        li {
            a {
                display: inline-block;
                width: 100%;
                height: 100%;
                padding: 15px 0 15px 20px;
                color: $white;
                border-bottom: solid 1px #555;
            }
            .router-link-active {
                background: #599714;
            }
        }
        &.active {
            right: 0;
        }
    }
}
}

// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 1024px) {
    .header {
        display: flex;
        justify-content: center;
        width: 100vw;
        height: 34px;
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        .external {
            position: relative;
            top: 46px;
            background: rgba(0, 0, 0, 0.6);
            padding: 0 20px;
            height: 32px;
            display: flex;
            align-items: center;
            border-radius: 0px 0px 12px 12px;
            a {
                font-size: 1.2rem;
                white-space: nowrap;
                color: $white;
                display: flex;
                align-items: center;
                &:link, &:visited {
                    color: $white;
                }
                &:hover {
                    text-decoration: underline;
                    color: $af-orange-blight;
                }
                &::after {
                    content: "";
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    margin-left: 8px;
                    background: url(../static/img/img_icon-external-agri.svg) no-repeat;
                }
            }
        }
    }
    .content-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background: #fff;
        padding: 0 20px;
        height: 60px;
        box-shadow: 0px 4px 4px 1px rgba(0,0,0,0.25);
    }
    .site-title {
        display: flex;
        align-items: center;
        height: auto;
        h1 {
            width: 200px;
            img {
            width: 100%;
            height: auto;
            }
        }
        figure {
            width: 50%;
            border: green;
            margin: auto;
            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }
    .global-navi--sp {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        top: 10px;
        right: 0;
        z-index: 20;
        .menu-icon {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transition: all 0.4s;
            width: 40px;
            height: 40px;
            padding: 10px 5px;
            margin-right: 15px;
            span {
                display: inline-block;
                transition: all 0.4s;
                width: 100%;
                height: 2px;
                background-color: $black;
                border-radius: 4px;
                &:nth-of-type(1) {
                    top: 0;
                }
                &:nth-of-type(2) {
                    top: 20px;
                }
                &:nth-of-type(3) {
                    bottom: 0;
                }
            }
            &.active {
                span {
                    &:nth-of-type(1) {
                        -webkit-transform: translateY(9px) rotate(-45deg);
                        transform: translateY(9px) rotate(-45deg);
                    }
                    &:nth-of-type(2) {
                        opacity: 0;
                    }
                    &:nth-of-type(3) {
                        -webkit-transform: translateY(-9px) rotate(45deg);
                        transform: translateY(-9px) rotate(45deg);
                    }
                }
            }
        }
        ul {
            width: 220px;
            height: 100vh;
            display: flex;
            flex-direction: column;
            list-style: none;
            background: #333;
            transition: all 0.4s;
            position: absolute;
            right: -220px;
            top: 50px;
            li {
                a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    padding: 15px 0 15px 20px;
                    color: $white;
                    border-bottom: solid 1px #555;
                }
                .router-link-active {
                    background: #599714;
                }
            }
            &.active {
                right: 0;
            }
        }
    }
}

// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1025px) {
    .header {
        width: 100%;
        height: 156px;
        border-bottom: solid 4px #0b4271;
    }
    .content-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        min-width: 1280px;
        max-width: 1360px;
        width: 100%;
        height: 156px;
        background: #fff;
        border-bottom: solid 4px #0b4271;
        position: relative;
        box-shadow: none;
        margin: auto;
    }
    .site-title {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
        h1 {
            img {
                width: 300px;
                height: auto;
            }
        }
        figure {
            img {
                
            }
        }
    }
    .global-navi--pc {
        width: 800px;
        min-width: 800px;
        display: flex;
        position: relative;
        bottom: -4px;
        right: 0;
        ul {
            width: 100%;
            display: flex;
            justify-content: space-between;
            list-style: none;
            margin-bottom: 0; // for IE
            li {
                a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    padding: 15px 10px;
                    color: #0c4271;
                }
                .router-link-active {
                    border-bottom: solid 4px #7cc947;
                }
            }
        }
    }
    .external {
        position: relative;
        right: 0;
        top: 6px;
        background: rgba(0, 0, 0, 0.6);
        padding: 0 40px;
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 0px 0px 12px 12px;
        a {
            font-size: 1.4rem;
            white-space: nowrap;
            color: $white;
            display: flex;
            align-items: center;
            &:link, &:visited {
                color: $white;
            }
            &:hover {
                text-decoration: underline;
                color: $af-orange-blight;
            }
            &::after {
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-left: 8px;
                background: url(../static/img/img_icon-external-agri.svg) no-repeat;
            }
        }
    }
}
</style>
