<template>
    <section>
        <section class="content-box content-box--01">
            <div class="content-box_inner">
                <div class="heading">
                    <div class="heading_title" v-if="$mq === 'pc'">
                        <img src="static/img/inquiry_img_01.png" alt="CONTACT" />
                    </div>
                </div>
                <!-- ******************** リード ******************** -->
                <div class="lead" v-show="inputArea">
                    <p class="lead_text--01">
                        Agri Flyerについて、<br
                            v-if="$mq === 'sp'"
                        />資料請求や詳しい説明をご希望のお客様は、<br />
                        下のフォームに必要事項を<br v-if="$mq === 'sp'" />入力してご送信ください。
                    </p>
                    <p class="lead_text--02">
                        なお、ご質問への回答には、<br
                            v-if="$mq === 'sp'"
                        />通常３〜４営業日をいただいております。<br />
                        ご了承いただきますよう、<br
                            v-if="$mq === 'sp'"
                        />よろしくお願い申し上げます。
                    </p>
                </div>
                <!-- ******************** リード（確認） ******************** -->
                <div class="lead" v-show="confirmArea">
                    <p class="lead_text--01">
                        入力内容をご確認ください。<br />
                        内容に相違がなければ<br
                            v-if="$mq === 'sp'"
                        />「この内容で送信する」で送信ください。
                    </p>
                </div>
                <!-- ******************** リード（完了） ******************** -->
                <div class="lead lead--finish" v-show="finishArea">
                    <p class="lead_text--01">
                        送信完了しました。
                    </p>
                    <p class="lead_text--02">
                        フォームからお問い合わせメールを送信しました。<br v-if="$mq === 'pc'" />
                        追って担当者よりご連絡さしあげます。
                    </p>
                </div>
                <div class="form-area">
                    <!-- ******************** お名前 ******************** -->
                    <div class="input-error" v-if="!$v.nameArea.$error" v-show="inputArea">
                        <span>全角文字を入力してください</span>
                    </div>
                    <dl class="form-area_name inputArea" v-show="inputArea">
                        <dt>
                            <span class="required">お名前</span>
                        </dt>
                        <dd>
                            <input
                                type="text"
                                name="input-name"
                                placeholder="〇〇太郎"
                                class="inputText"
                                v-model="nameArea"
                                @change="$v.nameArea.$touch()"
                            />
                        </dd>
                    </dl>
                    <!-- ********** お名前（確認） ********** -->
                    <dl class="form-area_name confirmArea" v-show="confirmArea">
                        <dt>
                            <span>お名前</span>
                        </dt>
                        <dd>
                            <p>{{ nameArea }}</p>
                        </dd>
                    </dl>
                    <!-- ******************** 法人名 ******************** -->
                    <dl class="form-area_company inputArea" v-show="inputArea">
                        <dt>
                            <span>法人名</span>
                        </dt>
                        <dd>
                            <input
                                type="text"
                                name="input-company"
                                placeholder="株式会社○○○○"
                                class="inputText"
                                v-model="companyArea"
                            />
                        </dd>
                    </dl>
                    <!-- ********** 法人名（確認） ********** -->
                    <dl class="form-area_company confirmArea" v-show="confirmArea">
                        <dt>
                            <span>法人名</span>
                        </dt>
                        <dd>
                            <p>{{ companyArea }}</p>
                        </dd>
                    </dl>
                    <!-- ******************** Email ******************** -->
                    <div class="input-error" v-if="!$v.mailArea.$error" v-show="inputArea">
                        <span>正しいメールアドレスを入力してください</span>
                    </div>
                    <dl class="form-area_mail inputArea" v-show="inputArea">
                        <dt>
                            <span class="required">Email</span>
                        </dt>
                        <dd>
                            <input
                                type="text"
                                name="input-mail"
                                placeholder="ishikawa@example.com"
                                class="inputText"
                                v-model="mailArea"
                                @change="$v.mailArea.$touch()"
                            />
                        </dd>
                    </dl>
                    <!-- ********** Email（確認） ********** -->
                    <dl class="form-area_mail confirmArea" v-show="confirmArea">
                        <dt>
                            <span class="required">Email</span>
                        </dt>
                        <dd>
                            <p>{{ mailArea }}</p>
                        </dd>
                    </dl>
                    <!-- ******************** 内容 ******************** -->
                    <div class="input-error" v-if="!$v.contentArea.$error" v-show="inputArea">
                        <span>お問い合わせ内容を選択してください</span>
                    </div>
                    <dl class="form-area_content inputArea" v-show="inputArea">
                        <dt>
                            <span class="required">内&emsp;容</span>
                        </dt>
                        <dd>
                            <div class="radio">
                                <input
                                    type="radio"
                                    name="radio-content"
                                    placeholder=""
                                    id="radio-1"
                                    value="資料請求（PDF）"
                                    v-model="contentArea"
                                    @change="$v.contentArea.$touch()"
                                />
                                <label for="radio-1" class="radio-label">資料請求（PDF）</label>
                            </div>
                            <div class="radio">
                                <input
                                    type="radio"
                                    name="radio-content"
                                    placeholder=""
                                    id="radio-2"
                                    value="資料請求（郵送）"
                                    v-model="contentArea"
                                    @change="$v.contentArea.$touch()"
                                />
                                <label for="radio-2" class="radio-label">資料請求（郵送）</label>
                            </div>
                            <div class="radio">
                                <input
                                    type="radio"
                                    name="radio-content"
                                    placeholder=""
                                    id="radio-3"
                                    value="お問い合わせ（メール希望）"
                                    v-model="contentArea"
                                    @change="$v.contentArea.$touch()"
                                />
                                <label for="radio-3" class="radio-label"
                                    >お問い合わせ（メール希望）</label
                                >
                            </div>
                            <div class="radio">
                                <input
                                    type="radio"
                                    name="radio-content"
                                    placeholder=""
                                    id="radio-4"
                                    value="お問い合わせ（電話希望）"
                                    v-model="contentArea"
                                    @change="$v.contentArea.$touch()"
                                />
                                <label for="radio-4" class="radio-label"
                                    >お問い合わせ（電話希望）</label
                                >
                            </div>
                            <div class="radio">
                                <input
                                    type="radio"
                                    name="radio-content"
                                    placeholder=""
                                    id="radio-5"
                                    value="その他"
                                    v-model="contentArea"
                                    @change="$v.contentArea.$touch()"
                                />
                                <label for="radio-5" class="radio-label">その他</label>
                            </div>
                        </dd>
                    </dl>
                    <!-- ********** 内容（確認） *********** -->
                    <dl class="form-area_content confirmArea" v-show="confirmArea">
                        <dt>
                            <span class="required">内&emsp;容</span>
                        </dt>
                        <dd class="content">
                            <p>{{ contentArea }}</p>
                        </dd>
                    </dl>
                    <!-- ******************** 郵便番号 ******************** -->
                    <dl class="form-area_zip inputArea" v-show="inputArea">
                        <dt>
                            郵便番号
                        </dt>
                        <dd>
                            <input
                                type="text"
                                name="input-zip"
                                placeholder="0000000"
                                class="inputText"
                                v-model="zipArea"
                            />
                        </dd>
                    </dl>
                    <!-- ********** 郵便番号（確認） ********** -->
                    <dl class="form-area_zip confirmArea" v-show="confirmArea">
                        <dt>
                            郵便番号
                        </dt>
                        <dd>
                            <p>{{ zipArea }}</p>
                        </dd>
                    </dl>
                    <!-- ******************** 都道府県 ******************** -->
                    <dl class="form-area_address--01 inputArea" v-show="inputArea">
                        <dt>
                            都道府県
                        </dt>
                        <dd>
                            <input
                                type="text"
                                name="input-address-01"
                                placeholder="〇〇県"
                                class="inputText"
                                v-model="addArea01"
                            />
                        </dd>
                    </dl>
                    <!-- ******************** 市区町村 ******************** -->
                    <dl class="form-area_address--02 inputArea" v-show="inputArea">
                        <dt>
                            市区町村
                        </dt>
                        <dd>
                            <input
                                type="text"
                                name="input-address-02"
                                placeholder="〇〇市"
                                class="inputText"
                                v-model="addArea02"
                            />
                        </dd>
                    </dl>
                    <!-- ******************** 住所詳細 ******************** -->
                    <dl class="form-area_address--03 inputArea" v-show="inputArea">
                        <dt>
                            住所詳細
                        </dt>
                        <dd>
                            <input
                                type="text"
                                name="input-address-03"
                                placeholder="〇〇町1-1-1"
                                class="inputText"
                                v-model="addArea03"
                            />
                        </dd>
                    </dl>
                    <!-- ********** 住所連結（確認） ********** -->
                    <dl class="form-area_address--03 confirmArea" v-show="confirmArea">
                        <dt>
                            住&emsp;所
                        </dt>
                        <dd>
                            <p>{{ addAreaAll }}</p>
                        </dd>
                    </dl>
                    <!-- ******************** 電話番号 ******************** -->
                    <div
                        class="input-error"
                        v-show="inputArea"
                        v-if="$v.contentArea.$model === 'お問い合わせ（電話希望）'"
                    >
                        <span
                            >「内容」で「お問い合わせ（電話希望）」を選択した方は必ずご入力ください</span
                        >
                    </div>
                    <dl class="form-area_tel inputArea" v-show="inputArea">
                        <dt>
                            電話番号
                        </dt>
                        <dd>
                            <input
                                type="text"
                                name="input-address-tel"
                                placeholder="00000000000"
                                class="inputText"
                                v-model="telArea"
                            />
                        </dd>
                    </dl>
                    <!-- ********** 電話番号（確認） ********** -->
                    <dl class="form-area_tel confirmArea" v-show="confirmArea">
                        <dt>
                            電話番号
                        </dt>
                        <dd>
                            <p>{{ telArea }}</p>
                        </dd>
                    </dl>
                    <!-- ******************** お問い合わせ内容 ******************** -->
                    <div
                        class="input-error"
                        v-show="inputArea"
                        v-if="
                            $v.contentArea.$model === 'お問い合わせ（メール希望）' ||
                                $v.contentArea.$model === 'お問い合わせ（電話希望）'
                        "
                    >
                        <span>「内容」で「お問い合わせ」を選択した方は必ずご入力ください</span>
                    </div>
                    <dl class="form-area_text inputArea" v-show="inputArea">
                        <dt>
                            お問い合わせ内容
                        </dt>
                        <dd>
                            <textarea
                                name="textarea-text"
                                placeholder="お問い合わせ内容をご記入ください"
                                class="inputTextArea"
                                v-model="textArea"
                            ></textarea>
                        </dd>
                    </dl>
                    <!-- ********** お問い合わせ内容（確認） ********** -->
                    <dl class="form-area_text confirmArea" v-show="confirmArea">
                        <dt>
                            お問い合わせ内容
                        </dt>
                        <dd class="textarea">
                            <p>{{ textArea }}</p>
                        </dd>
                    </dl>
                </div>
                <!-- ******************** 入力内容確認画面へ ******************** -->
                <div class="confirm" v-show="inputArea">
                    <a
                        class="confirm_btn"
                        @click="dataConfirm"
                        :disabled="$v.$invalid"
                        :class="[$v.$invalid ? '' : 'active']"
                    >
                        入力内容確認画面へ
                    </a>
                </div>
                <!-- ********** この内容で送信する ********** -->
                <div class="back" v-show="confirmArea">
                    <a class="back_btn" @click="goBack">
                        内容を修正する
                    </a>
                </div>
                <!-- ********** この内容で送信する ********** -->
                <div class="submit" v-show="confirmArea">
                    <a class="submit_btn" @click="dataSend">
                        この内容で送信する
                    </a>
                </div>
                <!-- ********** TOPにもどる ********** -->
                <div class="top" v-show="finishArea">
                    <a class="top_btn" @click="goTop">
                        TOPにもどる
                    </a>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import { required, minLength, between, helpers } from 'vuelidate/dist/validators.min.js';
import { functions } from '@/firebase';

// ****************************************
// Validation rules
// ****************************************
// 全角のみ
const doubleByteReg = helpers.regex('alpha', /[^\x01-\x7E]/);
// // メールアドレス
const mailReg = helpers.regex(
    'alpha',
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
);
// // 電話番号
// const telReg = '';

export default {
    props: ['menus'],
    data() {
        return {
            // INPUTデータ
            nameArea: '',
            companyArea: '',
            mailArea: '',
            contentArea: '',
            zipArea: '',
            addArea01: '',
            addArea02: '',
            addArea03: '',
            telArea: '',
            textArea: '',
            addAreaAll: '',

            // 表示・非表示
            inputArea: true,
            confirmArea: false,
            finishArea: false,
        };
    },
    // バリデーション
    validations: {
        nameArea: {
            required,
            doubleByteReg,
        },
        mailArea: {
            required,
            mailReg,
        },
        contentArea: {
            required,
        },
    },
    methods: {
        goBack() {
            // 確認画面要素切替
            this.inputArea = true;
            this.confirmArea = false;
            this.finishArea = false;
        },
        formReset() {
            this.nameArea = null;
            this.companyArea = null;
            this.mailArea = null;
            this.contentArea = null;
            this.zipArea = null;
            this.addArea01 = null;
            this.addArea02 = null;
            this.addArea03 = null;
            this.telArea = null;
            this.textArea = null;
        },
        // 確認画面
        dataConfirm() {
            // 住所連結
            this.addAreaAll = this.addArea01 + this.addArea02 + this.addArea03;
            // 送信直前の判定
            this.$v.$touch();
            if (this.$v.$invalid) {
                // エラー処理
                console.log('エラーがあります');
            } else {
                // 確認画面要素切替
                this.inputArea = false;
                this.confirmArea = true;
                this.finishArea = false;
            }
        },
        dataSend() {
            let self = this;
            const mailer = functions.httpsCallable('mainMail');
            const resMailer = functions.httpsCallable('responsMail');

            let contents = {
                name: self.nameArea,
                company: self.companyArea,
                email: self.mailArea,
                title: self.contentArea,
                zipcode: self.zipArea,
                address: self.addAreaAll,
                tel: self.telArea,
                content: self.textArea,
            };

            console.log(contents);

            mailer(contents)
                .then(() => {
                    //お問い合わせメールを送信後確認メールを送信
                    resMailer(contents)
                        .then(() => {
                            console.log('送信成功');
                            self.formReset();
                            this.inputArea = false;
                            this.confirmArea = false;
                            this.finishArea = true;
                        })
                        .catch(err => {
                            console.log(err);
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        goTop() {
            // TOPに遷移
            this.$router.push({
                name: 'top',
            });
        },
    },
};
</script>

<!-- ==================================================
CSS Style
=================================================== -->
<style scoped lang="scss">
// common
::placeholder {
    color: #aaa;
}

// ****************************************
// SP Layout
// ****************************************
.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40px 20px 40px;
}
.lead {
    text-align: center;
    &_text {
        &--01 {
            font-size: 1.6rem;
            font-weight: bold;
            line-height: 1.7;
        }
        &--02 {
            font-size: 1.4rem;
            color: #fb0008;
            line-height: 1.5;
            margin-top: 20px;
        }
    }
    &--finish {
        &--02 {
            font-size: 2.4rem;
            font-weight: bold;
            color: #262626;
            line-height: 1.5;
            margin-top: 40px;
        }
    }
}
.form-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
    dl {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0;
        }
        // 入力画面
        &.inputArea {
            dt {
                flex-basis: 100%;
                padding-right: 1.5em;
                font-size: 1.4rem;
                font-weight: bold;
                .required {
                    &::after {
                        content: '【必須】';
                        color: #fb0008;
                    }
                }
            }
            dd {
                flex-grow: 1;
                display: flex;
                align-items: center;
                height: 40px;
                background: #f2f2f2;
                border: solid 1px #b5b5b5;
                border-radius: 6px;
                padding: 0 1em;
                position: relative;
                margin-top: 10px;
                input {
                    width: 100%;
                    height: 100%;
                    font-size: 1.6rem;
                }
            }
        }
        // 確認画面
        &.confirmArea {
            dt {
                flex-basis: 100%;
                padding-right: 1.5em;
                font-size: 1.4rem;
                font-weight: bold;
            }
            dd {
                flex-grow: 1;
                display: flex;
                align-items: center;
                height: 40px;
                background: #f2f2f2;
                border-radius: 6px;
                padding: 0 1em;
                position: relative;
                margin-top: 10px;
                p {
                    font-size: 1.6rem;
                }
                &.content {
                    margin-top: 15px;
                    p {
                        font-weight: normal;
                    }
                }
                &.textarea {
                    p {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        &.form-area_content {
            width: 100%;
            font-size: 1.6rem;
            font-weight: bold;
            dd {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                height: auto;
                background: none;
                border: none;
                margin: 10px 0 0;
                // custom radio button start --------------------
                $color1: #f4f4f4;
                $color2: #3197ee;
                .radio {
                    width: 100%;
                    margin-bottom: 15px;
                    margin-top: 10px;
                    font-size: 1.4rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    input[type='radio'] {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        opacity: 0;
                        + .radio-label {
                            width: 100%;
                            &:before {
                                content: '';
                                background: $color1;
                                border-radius: 100%;
                                border: 1px solid darken($color1, 25%);
                                display: inline-block;
                                width: 1em;
                                height: 1em;
                                position: relative;
                                margin-right: 0.5em;
                                vertical-align: top;
                                cursor: pointer;
                                text-align: center;
                                transition: all 250ms ease;
                            }
                        }
                        &:checked {
                            + .radio-label {
                                &:before {
                                    background-color: $color2;
                                    box-shadow: inset 0 0 0 4px $color1;
                                }
                            }
                        }
                        &:focus {
                            + .radio-label {
                                &:before {
                                    outline: none;
                                    border-color: $color2;
                                }
                            }
                        }
                        &:disabled {
                            + .radio-label {
                                &:before {
                                    box-shadow: inset 0 0 0 4px $color1;
                                    border-color: darken($color1, 25%);
                                    background: darken($color1, 25%);
                                }
                            }
                        }
                        + .radio-label {
                            &:empty {
                                &:before {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
                // custom radio button end --------------------
            }
        }
        &.form-area_text {
            dt {
                padding-right: 1.5em;
            }
            dd {
                height: 340px;
                padding: 1em;
                textarea {
                    resize: none;
                    width: 100%;
                    height: 100%;
                    font-size: 1.6rem;
                }
            }
        }
    }
    // バリデーションエラーテキスト
    .input-error {
        margin-bottom: 15px;
        span {
            font-size: 1.4rem;
            line-height: 1.5;
            color: #fb0008;
        }
    }
}
// 遷移ボタン --------------------
.confirm {
    width: 100%;
    margin-top: 10px;
    &_btn {
        @include btn-submit-sp($background: #ddd, $color: #bbb);
        // バリデーション通過時
        &.active {
            @include btn-submit-sp($color: #fff);
        }
    }
}
.back {
    width: 100%;
    margin-top: 40px;
    &_btn {
        @include btn-submit-sp($background: #bbb, $color: #fff);
    }
}
.submit {
    width: 100%;
    margin-top: 20px;
    &_btn {
        @include btn-submit-sp($color: #fff);
    }
}
.top {
    width: 100%;
    &_btn {
        @include btn-submit-sp($background: #bbb, $color: #fff);
    }
}
// ****************************************
// SPL Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 768px) {
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 40px 20px 40px;
        &_inner {
            width: 510px;
        }
    }
    .lead {
        text-align: center;
        &_text {
            &--01 {
                font-size: 1.6rem;
                font-weight: bold;
                line-height: 1.7;
            }
            &--02 {
                font-size: 1.6rem;
                color: #fb0008;
                line-height: 1.5;
                margin-top: 20px;
            }
        }
        &--finish {
            &--02 {
                font-size: 2.4rem;
                font-weight: bold;
                color: #262626;
                line-height: 1.5;
                margin-top: 40px;
            }
        }
    }
    .form-area {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 30px;
        dl {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 25px;
            &:last-child {
                margin-bottom: 0;
            }
            // 入力画面
            &.inputArea {
                dt {
                    flex-basis: 100%;
                    padding-right: 1.5em;
                    font-size: 1.6rem;
                    font-weight: bold;
                    .required {
                        &::after {
                            content: '【必須】';
                            color: #fb0008;
                        }
                    }
                }
                dd {
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    height: 40px;
                    background: #f2f2f2;
                    border: solid 1px #b5b5b5;
                    border-radius: 6px;
                    padding: 0 1em;
                    position: relative;
                    margin-top: 10px;
                    input {
                        width: 100%;
                        height: 100%;
                        font-size: 1.6rem;
                    }
                }
            }
            // 確認画面
            &.confirmArea {
                dt {
                    flex-basis: 100%;
                    padding-right: 1.5em;
                    font-size: 1.4rem;
                    font-weight: bold;
                }
                dd {
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    height: 40px;
                    background: #f2f2f2;
                    border-radius: 6px;
                    padding: 0 1em;
                    position: relative;
                    margin-top: 10px;
                    p {
                        font-size: 1.6rem;
                    }
                    &.content {
                        margin-top: 15px;
                        p {
                            font-weight: normal;
                        }
                    }
                    &.textarea {
                        p {
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
            &.form-area_content {
                width: 100%;
                font-size: 1.6rem;
                font-weight: bold;
                dd {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    height: auto;
                    background: none;
                    border: none;
                    margin: 10px 0 0;
                    // custom radio button start --------------------
                    $color1: #f4f4f4;
                    $color2: #3197ee;
                    .radio {
                        width: 100%;
                        margin-bottom: 15px;
                        margin-top: 10px;
                        font-size: 1.6rem;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        input[type='radio'] {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            opacity: 0;
                            + .radio-label {
                                width: 100%;
                                &:before {
                                    content: '';
                                    background: $color1;
                                    border-radius: 100%;
                                    border: 1px solid darken($color1, 25%);
                                    display: inline-block;
                                    width: 1em;
                                    height: 1em;
                                    position: relative;
                                    margin-right: 0.5em;
                                    vertical-align: top;
                                    cursor: pointer;
                                    text-align: center;
                                    transition: all 250ms ease;
                                }
                            }
                            &:checked {
                                + .radio-label {
                                    &:before {
                                        background-color: $color2;
                                        box-shadow: inset 0 0 0 4px $color1;
                                    }
                                }
                            }
                            &:focus {
                                + .radio-label {
                                    &:before {
                                        outline: none;
                                        border-color: $color2;
                                    }
                                }
                            }
                            &:disabled {
                                + .radio-label {
                                    &:before {
                                        box-shadow: inset 0 0 0 4px $color1;
                                        border-color: darken($color1, 25%);
                                        background: darken($color1, 25%);
                                    }
                                }
                            }
                            + .radio-label {
                                &:empty {
                                    &:before {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                    // custom radio button end --------------------
                }
            }
            &.form-area_text {
                dt {
                    padding-right: 1.5em;
                }
                dd {
                    height: 340px;
                    padding: 1em;
                    textarea {
                        resize: none;
                        width: 100%;
                        height: 100%;
                        font-size: 1.6rem;
                    }
                }
            }
        }
        // バリデーションエラーテキスト
        .input-error {
            margin-bottom: 15px;
            span {
                font-size: 1.4rem;
                line-height: 1.5;
                color: #fb0008;
            }
        }
    }
    // 遷移ボタン --------------------
    .confirm {
        width: 100%;
        margin-top: 10px;
        &_btn {
            @include btn-submit-sp($background: #ddd, $color: #bbb);
            // バリデーション通過時
            &.active {
                @include btn-submit-sp($color: #fff);
            }
        }
    }
    .back {
        width: 100%;
        margin-top: 40px;
        &_btn {
            @include btn-submit-sp($background: #bbb, $color: #fff);
        }
    }
    .submit {
        width: 100%;
        margin-top: 20px;
        &_btn {
            @include btn-submit-sp($color: #fff);
        }
    }
    .top {
        width: 100%;
        &_btn {
            @include btn-submit-sp($background: #bbb, $color: #fff);
        }
    }
}
// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        &_inner {
            width: 700px;
        }
    }
}
// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1025px) {
    // common
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 80px;
        &_inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 1280px;
            max-width: 1360px;
            width: 100%;
            position: relative;
        }
    }
    .heading {
        margin-top: 20px;
    }
    .lead {
        text-align: center;
        margin-top: 60px;
        &_text {
            &--01 {
                font-size: 2.4rem;
                font-weight: bold;
                line-height: 1.6;
            }
            &--02 {
                font-size: 1.6rem;
                color: $dark-gray;
                line-height: 1.5;
                margin-top: 40px;
            }
        }
        &--finish {
            &--02 {
                font-size: 2.4rem;
                font-weight: bold;
                color: #262626;
                line-height: 1.5;
                margin-top: 40px;
            }
        }
    }
    .form-area {
        display: flex;
        flex-direction: column;
        width: 800px;
        margin-top: 70px;
        dl {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-bottom: 40px;
            &:last-child {
                margin-bottom: 0;
            }
            // 入力画面
            &.inputArea {
                dt {
                    flex-basis: 200px;
                    flex-grow: 0;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    height: 60px;
                    padding-right: 1.5em;
                    font-size: 1.8rem;
                    font-weight: bold;
                    .required {
                        &::before {
                            content: '【必須】';
                            color: #fb0008;
                        }
                        &::after {
                            content: none;
                        }
                    }
                }
                dd {
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    height: 60px;
                    background: #f2f2f2;
                    border: solid 1px #b5b5b5;
                    border-radius: 10px;
                    padding: 0 1.5em;
                    position: relative;
                    margin-top: 0;
                    input {
                        width: 100%;
                        height: 100%;
                        font-size: 1.8rem;
                    }
                }
            }
            // 確認画面
            &.confirmArea {
                dt {
                    flex-basis: 200px;
                    flex-grow: 0;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    padding-right: 1.5em;
                    font-size: 2.1rem;
                    font-weight: bold;
                }
                dd {
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    height: 70px;
                    background: #f2f2f2;
                    border-radius: 10px;
                    padding: 0 1.5em;
                    position: relative;
                    p {
                        font-size: 2.1rem;
                    }
                    &.textarea {
                        p {
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
            &.form-area_content {
                width: 100%;
                font-size: 2.1rem;
                font-weight: bold;
                dd {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    height: auto;
                    background: none;
                    border: none;
                    margin: 10px 0;
                    // custom radio button start --------------------
                    $color1: #f4f4f4;
                    $color2: #3197ee;
                    .radio {
                        width: 100%;
                        margin-bottom: 30px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        input[type='radio'] {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            opacity: 0;
                            + .radio-label {
                                width: 100%;
                                &:before {
                                    content: '';
                                    background: $color1;
                                    border-radius: 100%;
                                    border: 1px solid darken($color1, 25%);
                                    display: inline-block;
                                    width: 1em;
                                    height: 1em;
                                    position: relative;
                                    margin-right: 0.5em;
                                    vertical-align: top;
                                    cursor: pointer;
                                    text-align: center;
                                    transition: all 250ms ease;
                                }
                            }
                            &:checked {
                                + .radio-label {
                                    &:before {
                                        background-color: $color2;
                                        box-shadow: inset 0 0 0 4px $color1;
                                    }
                                }
                            }
                            &:focus {
                                + .radio-label {
                                    &:before {
                                        outline: none;
                                        border-color: $color2;
                                    }
                                }
                            }
                            &:disabled {
                                + .radio-label {
                                    &:before {
                                        box-shadow: inset 0 0 0 4px $color1;
                                        border-color: darken($color1, 25%);
                                        background: darken($color1, 25%);
                                    }
                                }
                            }
                            + .radio-label {
                                &:empty {
                                    &:before {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                    // custom radio button end --------------------
                }
            }
            &.form-area_text {
                dt {
                    padding-right: 1.5em;
                }
                dd {
                    height: 340px;
                    padding: 1.5em;
                    textarea {
                        resize: none;
                        width: 100%;
                        height: 100%;
                        font-size: 1.8rem;
                    }
                }
            }
        }
        // バリデーションエラーテキスト
        .input-error {
            margin-left: 200px;
            margin-bottom: 10px;
            white-space: nowrap;
            span {
                font-size: 1.8rem;
                line-height: 1;
                color: #fb0008;
            }
        }
    }
    // 遷移ボタン --------------------
    .confirm {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        &_btn {
            @include btn-submit($background: #ddd, $color: #bbb);
            // バリデーション通過時
            &.active {
                @include btn-submit($color: #fff);
            }
        }
    }
    .back {
        display: flex;
        justify-content: center;
        margin-top: 80px;
        &_btn {
            @include btn-submit($background: #bbb, $color: #fff);
        }
    }
    .submit {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        &_btn {
            @include btn-submit($color: #fff);
        }
    }
    .top {
        display: flex;
        justify-content: center;
        &_btn {
            @include btn-submit($background: #bbb, $color: #fff);
        }
    }
}
</style>
