<template>
    <section>
        <section class="content-box content-box--01" id="01">
            <div class="heading">
                <h2 class="shade">
                    「スマート農業」で省力化と生産性の向上を実現。<br
                        v-if="$mq === 'tb' || $mq === 'pc'"
                    />
                    政府主導での実証実験スタート
                </h2>
            </div>
            <div class="row-box--between">
                <div class="images--02_img fullwidth">
                    <object
                        class="fullwidth"
                        type="image/svg+xml"
                        data="static/img/chart_sfarm-illust.svg"
                    ></object>
                </div>
                <div class="quote-box col-box">
                    <div class="images--pos_left sp">
                        <object
                            type="image/svg+xml"
                            data="static/img/img_quotation-start-sp.svg"
                        ></object>
                    </div>
                    <p>
                        最先端のロボット技術や人工知能（AI）を活用する「スマート（賢い）農業」の実用化に向けた動きが加速している。政府は2019年度から国内約50か所のモデル農場で、自動運転トラクターを使って畑を耕すなどの実証実験に乗り出す。農家の高齢化や担い手不足が深刻化する中、農業再生の切り札になるかが注目される。（2019.2.3
                        読売新聞より）
                    </p>
                    <div class="images--pos_right">
                        <object
                            type="image/svg+xml"
                            data="static/img/img_quotation-end-sp.svg"
                        ></object>
                    </div>
                </div>
            </div>
            <div class="heading spacer">
                <h3>
                    「スマート農業」実現のための<br
                        v-if="$mq === 'sp' || $mq === 'spl' || $mq === 'tb'"
                    />先端技術の開発と実装
                </h3>
            </div>
            <div class="images--02_img" v-if="$mq === 'sp' || $mq === 'spl'">
                <object type="image/svg+xml" data="static/img/chart_arrow-sp.svg"></object>
            </div>
            <div class="images--02_img spacer" v-if="$mq === 'tb' || $mq === 'pc'">
                <object type="image/svg+xml" data="static/img/chart_arrow-wide.svg"></object>
            </div>
            <div class="heading">
                <h4>
                    <span class="halftone">政府主導での実証実験の骨子では</span
                    ><br v-if="$mq === 'tb' || $mq === 'pc'" />
                    <span
                        >“要素技術を生産から出荷まで体系的に組み立て一気通貫での技術実証を実現。<br
                            v-if="$mq === 'tb' || $mq === 'pc'"
                        />
                        速やかな現場への普及までを総合的に推進する”</span
                    ><br /><span class="halftone">としています</span>
                </h4>
            </div>
        </section>
        <section class="content-box content-box--01">
            <div class="heading_section">
                <h2>防除の現状と今後の展望　〜その１〜</h2>
            </div>
            <div class="row-box--stretch spacer">
                <div class="images--02_img" v-if="$mq === 'sp' || $mq === 'spl'">
                    <object type="image/svg+xml" data="static/img/chart_5years-sp.svg"></object>
                </div>
                <div class="images--02_img" v-if="$mq === 'tb' || $mq === 'pc'">
                    <object type="image/svg+xml" data="static/img/chart_5years.svg"></object>
                </div>
                <div class="col-box col-article single-right">
                    <h3>５年後にはドローンによる空散が主流に</h3>
                    <p>
                        5年後には、現在主流となる散布用ヘリの利用は大きくその比率を変えないものの、人がタンクを背負っての散布、地上散布車による散布が大きく減るものと予想されます。これは、農家の高齢化の影響、運転技術の担い手が不足することに起因する変化と考えることができます。
                    </p>
                    <p>
                        散布用ドローンは、肉体的な負担を軽減し、また機体操作のみで散布は半自動で行うため、大きくそのシェアを伸ばし、面積比にして半分のシェアまで拡大するものと予想されています。
                    </p>
                </div>
            </div>
            <div class="row-box--stretch mg-top04">
                <div class="col-box col-article single-left">
                    <h3>散布用ヘリからドローンの利用へ</h3>
                    <p>散布用ドローンの市場規模も同様に大きく成長するものと予想されています。</p>
                    <p>
                        機体・サービスを含む経済的な市場規模として、2024年には5,073億円までに成長し、機体の登録台数もその数を3,500台を越えて伸ばす見込みです。
                    </p>
                    <p>
                        農業分野、水稲用途での開拓を中心に今後、急速な普及・発展が期待されています。
                    </p>
                </div>
                <div class="images--02_img" v-if="$mq === 'sp' || $mq === 'spl'">
                    <object type="image/svg+xml" data="static/img/chart_market-sp.svg"></object>
                </div>
                <div class="images--02_img" v-if="$mq === 'tb' || $mq === 'pc'">
                    <object type="image/svg+xml" data="static/img/chart_market-wide.svg"></object>
                </div>
            </div>
            <div class="compare mg-top04">
                <h3>比較してみよう</h3>
                <div class="row-box--stretch">
                    <div class="col-box">
                        <div class="index blue">
                            散布用ドローン
                        </div>
                        <div class="photo">
                            <img src="static/img/img_compare-drone.png" alt="ドローン" />
                        </div>
                        <ul class="plus">
                            <li>
                                <span
                                    >機体の値段が安い<br
                                        v-if="$mq === 'tb'"
                                    />（自費で購入可能）</span
                                >
                            </li>
                            <li>
                                <span>１台購入すれば<br />自分の好きなタイミングで散布できる</span>
                            </li>
                            <li>
                                <span
                                    >機体が小型で機動性が高く、<br />狭い圃場や複雑な地形の散布も可能</span
                                >
                            </li>
                            <li><span>飛行騒音が小さい</span></li>
                            <li>
                                <span
                                    >機体重量が軽いため一人でも<br />取り扱いが可能（監視員は別途必要）</span
                                >
                            </li>
                            <li><span>操縦が簡単で安全性の高い機能が豊富</span></li>
                            <li><span>粒状肥料散布も可能</span></li>
                        </ul>
                        <ul class="minus">
                            <li>1回の飛行時間が短い</li>
                            <li>所有者がメンテナンスする必要がある</li>
                        </ul>
                    </div>
                    <div class="col-box">
                        <div class="index gray">
                            散布用ヘリコプター
                        </div>
                        <div class="photo">
                            <img src="static/img/img_compare-heli.png" alt="ヘリコプター" />
                        </div>
                        <ul class="plus">
                            <li>
                                <span
                                    >業者へ委託すれば、作業料金が<br />単位面積のためわかりやすい</span
                                >
                            </li>
                            <li>
                                <span
                                    >散布範囲さえ指定すれば、<br />業者が散布してくれるので手間が無く楽</span
                                >
                            </li>
                            <li>
                                <span>1回の飛行時間が長く、<br />広範囲の散布が可能</span>
                            </li>
                        </ul>
                        <ul class="minus">
                            <li>操作に熟練が必要</li>
                            <li>機体が高価なため、<br />通常は散布を業者へ委託</li>
                            <li>業者委託のため<br />自由なタイミングで散布できない</li>
                            <li>飛行騒音が大きい</li>
                            <li>狭い圃場には難しい</li>
                            <li>機体が大型で<br v-if="$mq === 'tb'" />重量があるため人手が必要</li>
                        </ul>
                    </div>
                </div>
                <div class="quote-box wide spacer">
                    <div class="images--pos_left">
                        <object
                            type="image/svg+xml"
                            data="static/img/img_quotation-start-color.svg"
                        ></object>
                    </div>
                    どちらが得か？はケースによるものの、<br v-if="$mq === 'pc'" /><span
                        >圃場面積5ha以上</span
                    >への散布を考えた場合、<br v-if="$mq === 'pc'" /><span>ドローン導入</span
                    >の<span>メリットが大きい</span>とされています！
                    <div class="images--pos_right">
                        <object
                            type="image/svg+xml"
                            data="static/img/img_quotation-end-color.svg"
                        ></object>
                    </div>
                </div>
            </div>
        </section>
        <section class="content-box content-box--01">
            <div class="heading_section">
                <h2>防除の現状と今後の展望　〜その２〜</h2>
            </div>
            <div class="heading">
                <h3 class="table-title">無人航空機による散布等の面積</h3>
            </div>
            <div class="table-container">
                <table class="table-area">
                    <caption>
                        （単位：ha）
                    </caption>
                    <tbody>
                        <tr>
                            <th></th>
                            <th>平成２２年度</th>
                            <th>平成２３年度</th>
                            <th>平成２４年度</th>
                            <th>平成２５年度</th>
                            <th>平成２６年度</th>
                            <th>平成２７年度</th>
                            <th>平成２８年度</th>
                        </tr>
                        <tr>
                            <th>水稲防除</th>
                            <td>838,156</td>
                            <td>851,822</td>
                            <td>884,308</td>
                            <td>931,095</td>
                            <td>928,786</td>
                            <td>923,365</td>
                            <td>910,927</td>
                        </tr>
                        <tr>
                            <th>麦類防除</th>
                            <td>60,730</td>
                            <td>61,351</td>
                            <td>63,626</td>
                            <td>57,152</td>
                            <td>60,954</td>
                            <td>70,989</td>
                            <td>65,932</td>
                        </tr>
                        <tr>
                            <th>大豆防除</th>
                            <td>57,905</td>
                            <td>51,409</td>
                            <td>52,906</td>
                            <td>52,258</td>
                            <td>52,748</td>
                            <td>56,059</td>
                            <td>59,118</td>
                        </tr>
                        <tr>
                            <th>その他</th>
                            <td>6,459</td>
                            <td>6,556</td>
                            <td>6,202</td>
                            <td>6,779</td>
                            <td>7,348</td>
                            <td>7,565</td>
                            <td>10,729</td>
                        </tr>
                        <tr>
                            <th>合計</th>
                            <td>963,250</td>
                            <td>971,138</td>
                            <td>1,007,042</td>
                            <td>1,047,284</td>
                            <td>1,049,836</td>
                            <td>1,057,978</td>
                            <td>1,046,706</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="heading spacer">
                <h3 class="table-title">ドローンによる散布等の延べ面積</h3>
            </div>
            <div class="table-container">
                <div class="row-box--stretch">
                    <table class="table-area">
                        <caption>
                            （単位：ha）
                        </caption>
                        <tbody>
                            <tr>
                                <th></th>
                                <th>平成２８年度</th>
                                <th>平成２９年度（速報値）</th>
                            </tr>
                            <tr>
                                <th>水稲防除</th>
                                <td>586</td>
                                <td>7,000</td>
                            </tr>
                            <tr>
                                <th>麦類防除</th>
                                <td>0</td>
                                <td>700</td>
                            </tr>
                            <tr>
                                <th>大豆防除</th>
                                <td>97</td>
                                <td>500</td>
                            </tr>
                            <tr>
                                <th>その他</th>
                                <td>1</td>
                                <td>100</td>
                            </tr>
                            <tr>
                                <th>合計</th>
                                <td>684</td>
                                <td>8,300</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table-area">
                        <caption>
                            （単位：ha）
                        </caption>
                        <tbody>
                            <tr>
                                <th>地域</th>
                                <th>平成２８年度</th>
                                <th>平成２９年度（速報値）</th>
                            </tr>
                            <tr>
                                <th>北海道・東北</th>
                                <td>2</td>
                                <td>1,900</td>
                            </tr>
                            <tr>
                                <th>関東</th>
                                <td>97</td>
                                <td>400</td>
                            </tr>
                            <tr>
                                <th>東海</th>
                                <td>0</td>
                                <td>400</td>
                            </tr>
                            <tr>
                                <th>北陸</th>
                                <td>138</td>
                                <td>1,900</td>
                            </tr>
                            <tr>
                                <th>近畿</th>
                                <td>28</td>
                                <td>50</td>
                            </tr>
                            <tr>
                                <th>中国・四国</th>
                                <td>342</td>
                                <td>2,300</td>
                            </tr>
                            <tr>
                                <th>九州</th>
                                <td>77</td>
                                <td>1,400</td>
                            </tr>
                            <tr>
                                <th>合計</th>
                                <td>684</td>
                                <td>8,350</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row-box tmbup-box">
                <div class="images--02_img">
                    <object type="image/svg+xml" data="static/img/icon_tmbup-large.svg"></object>
                </div>
                <p>
                    アグリフライヤーのように、安価で高性能、<br
                        v-if="$mq === 'pc'"
                    />高耐久性を持ったドローンの登場により、<br
                        v-if="$mq === 'pc'"
                    />今後大幅な散布面積の増加が見込まれています！
                </p>
            </div>
            <div class="description">
                無人航空機による散布面積は、22年度から総面積を伸ばしてはいるものの、その変化は比較的緩やかです。<br
                    v-if="$mq === 'pc'"
                />
                また、作業別の延面積を見ても、29年度は大幅に増加しているものの、日本全国を合算しても、未だ1万haとなっています。<br
                    v-if="$mq === 'pc'"
                />
                しかし、安価で高性能なドローンの普及が、これらに大きな変化をもたらすことを期待されています。
            </div>
        </section>
        <section class="content-box content-box--01">
            <div class="heading_section">
                <h2>防除の現状と今後の展望　〜その３〜</h2>
            </div>
            <div class="row-box--stretch spacer">
                <div class="images--02_img" v-if="$mq === 'sp' || $mq === 'spl'">
                    <object type="image/svg+xml" data="static/img/chart_outlook-sp.svg"></object>
                </div>
                <div class="images--02_img" v-if="$mq === 'tb' || $mq === 'pc'">
                    <object type="image/svg+xml" data="static/img/chart_outlook.svg"></object>
                </div>
                <div class="col-box col-article single-right">
                    <h3>ドローンの普及と今後の展望</h3>
                    <p>
                        ドローンの登録台数は2017年３月〜2018年２月までの約1年間で約3倍もの登録台数となりました。それに伴い、オペレータの認定者数も約3倍となり、今後の需要の拡大に合わせ、登録台数、オペレータ数もますます増加していくものと予想されています。
                    </p>
                </div>
            </div>
            <div class="row-box tmbup-box">
                <div class="images--02_img">
                    <object type="image/svg+xml" data="static/img/icon_tmbup-large.svg"></object>
                </div>
                <p>
                    登録台数・オペレータの認定者数は２０１７年から２０１８年にかけて３倍に増加。<br
                        v-if="$mq === 'pc'"
                    />ドローンでの散布の需要は今後、大きく増加すると考えられます！
                </p>
            </div>
        </section>
    </section>
</template>

<script>
export default {};
</script>

<!-- ==================================================
CSS Style
=================================================== -->
<style scoped lang="scss">
// ****************************************
// SP Layout
// ****************************************
// common
.row-box--between {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > div {
        display: flex;
        align-items: center;
    }
}
.row-box--stretch {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > div {
        display: flex;
        align-items: center;
    }
}

.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    h2 {
        text-align: center;
        font-size: 1.6rem;
        line-height: 2.56rem;
        margin-bottom: 2rem;
        &.shade {
            text-shadow: 3px 3px 1px $smoke-skyblue;
            letter-spacing: 0rem;
        }
    }
    h3 {
        font-size: 1.6rem;
        line-height: 2.56rem;
        text-align: center;
        margin: auto;
        &.table-title {
            font-size: 1.6rem;
        }
    }
    h4 {
        font-size: 1.4rem;
        line-height: 2.24rem;
        margin: auto;
        text-align: center;
        text-align: justify;
        text-justify: inter-ideograph;
        span {
            background: linear-gradient(transparent 60%, $af-lime 0%);
            &.halftone {
                background: none;
                color: $mid-gray;
            }
        }
    }
    &_section {
        margin: 0 auto 2rem;
        text-align: center;
        h2 {
            display: inline;
            border-bottom: solid 2px $af-yellowgreen;
            text-align: center;
            font-size: 1.6rem;
            padding-bottom: 0.5rem;
        }
    }
    &_title {
        font-size: 3.2rem;
        text-align: center;
        &-sub {
            font-size: 1.8rem;
            font-weight: normal;
            color: #007a0c;
            text-align: center;
            margin-top: 20px;
        }
    }
}
.lead {
    margin-top: 35px;
    &_text {
        text-align: center;
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 1.5;
    }
}
.quote-box {
    background-color: $smoke-skyblue;
    font-size: 1.5rem;
    line-height: 2.7rem;
    width: 100%;
    padding: 3.2rem;
    text-align: justify;
    text-justify: inter-ideograph;
    &.wide {
        background-color: $white;
        width: 100%;
        max-width: 100%;
        padding: 1rem;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 3.2rem;
        text-align: left;
        span {
            color: $af-navy;
            font-size: 1.8rem;
            background: linear-gradient(transparent 60%, $skyblue 0%);
        }
    }
}
.col-article {
    margin-top: 2rem;
    text-align: justify;
    text-justify: inter-ideograph;
    &.single-right {
        max-width: 100%;
    }
    &.single-left {
        max-width: 100%;
    }
    h3 {
        width: 100%;
        text-align: center;
        font-size: 1.6rem;
        padding: 1rem;
        border: solid 2px $af-yellowgreen;
        border-radius: 4px;
        margin-bottom: 3.2rem;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: -20px;
            left: 0;
            width: 0px;
            height: 0px;
            margin: auto;
            border-style: solid;
            border-color: $af-yellowgreen transparent transparent transparent;
            border-width: 20px 20px 0 20px;
        }
    }
    p {
        font-size: 1.6rem;
        line-height: 2.56rem;
        text-align: justify;
        text-justify: inter-ideograph;
    }
}

// contents
.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 20px 20px;
    background: #fff;
}
.content-box {
    // 省力化と生産性の向上 ********************
    &--01 {
        .images {
            &--01 {
                display: flex;
                justify-content: center;
                margin-top: 35px;
                &_photo {
                    width: 720px;
                    height: 440px;
                    background: #333;
                }
            }
            &--02 {
                &_img {
                    width: 100%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    object {
                        width: 100%;
                        height: auto;
                    }
                }
                &_photo {
                    width: 620px;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
    .compare {
        width: 100%;
        background-color: $smoke-skyblue;
        padding: 2rem 1rem;
        border-radius: 4px;
        margin-top: 40px;
        .row-box--stretch {
            justify-content: space-between;
            & > div {
                margin-top: 2rem;
                background-color: $af-red--lite;
                max-width: 577px;
            }
        }
        h3 {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            background-color: $af-blue;
            border: solid 2px $white;
            height: 48px;
            border-radius: 24px;
            font-size: 1.6rem;
        }
        .index {
            text-align: center;
            padding: 1rem;
            font-size: 1.6rem;
            font-weight: bold;
            color: $white;
            width: 100%;
            &.blue {
                background-color: $af-navy;
            }
            &.gray {
                background-color: $mid-gray;
            }
        }
        .photo {
            width: 100%;
            img {
                width: 100%;
            }
        }
        ul {
            padding: 2rem;
            width: 100%;
            li {
                font-size: 1.5rem;
                font-weight: bold;
                text-align: center;
                margin: 2.4rem auto;
                line-height: 2.4rem;
            }
            &.plus {
                color: $af-navy;
                background-color: $white;
                background-image: url('../static/img/img_compare-tmbup.png');
                background-position: top left;
                background-repeat: no-repeat;
                li {
                    position: relative;
                    span {
                        display: inline;
                        background: linear-gradient(transparent 60%, $skyblue 0%);
                    }
                }
            }
            &.minus {
                color: $af-red;
                background-color: $af-red--lite;
                background-image: url('../static/img/img_label-tmbdwn.svg');
                background-image: url('../static/img/img_compare-tmbdwn.png'),
                    url('../static/img/bgimg_batsu.png');
                background-position: top left, center center;
                background-repeat: no-repeat, no-repeat;
            }
        }
    }
    .table-container {
        width: 100%;
    }
    .table-area {
        width: 100%;
        border-collapse: separate;
        border-spacing: 1px;
        display: block;
        overflow-x: scroll;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        margin-top: 1rem;
        tbody {
            display: table;
            width: 100%;
            background: darken($lite-gray, 20%);
        }
        caption {
            padding: 0 0 0.5rem;
            font-size: 1.4rem;
            text-align: right;
        }
        tr:nth-child(odd) {
            background-color: $lite-gray;
        }
        tr:nth-child(even) {
            background: darken($lite-gray, 5%);
        }
        tr:last-child {
            background: darken($smoke-skyblue, 10%);
        }
        th {
            font-size: 1.2rem;
            background-color: $dark-gray;
            color: $white;
            padding: 0.5rem;
            font-weight: bold;
            white-space: nowrap;
        }
        td {
            font-size: 1.2rem;
            text-align: right;
            padding: 8px;
        }
    }
    .tmbup-box {
        margin: 2rem auto;
        p {
            font-size: 1.6rem;
            line-height: 2.56rem;
            font-weight: bold;
            max-width: 100%;
            padding-left: 16px;
        }
    }
    .description {
        text-align: center;
        font-size: 1.6rem;
        line-height: 2.56rem;
        text-align: justify;
        text-justify: inter-ideograph;
    }
}
// ****************************************
// SPL Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 768px) {
    // common
    .row-box--between {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .row-box--stretch {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            text-align: center;
            font-size: 2.8rem;
            line-height: 4.48rem;
            margin-bottom: 4rem;
            &.shade {
                letter-spacing: 0;
            }
        }
        h3 {
            font-size: 2.4rem;
            line-height: 3.84rem;
            margin: 3.42rem auto;
            &.table-title {
                font-size: 2.4rem;
                margin-bottom: 1rem;
            }
        }
        h4 {
            font-size: 2rem;
            line-height: 3.2rem;
            margin: 2rem auto 0;
            text-align: left;
        }
        &_section {
            margin: 0 auto 4rem;
            text-align: center;
            h2 {
                display: inline;
                border-bottom: solid 2px $af-yellowgreen;
                text-align: center;
                font-size: 2.4rem;
                padding-bottom: 0.7rem;
            }
        }
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .lead {
        margin-top: 35px;
        &_text {
            text-align: center;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;
        }
    }
    .quote-box {
        background-color: $smoke-skyblue;
        font-size: 1.6rem;
        line-height: 3.2rem;
        letter-spacing: 0.1rem;
        max-width: 100%;
        padding: 3rem;
        text-align: justify;
        text-justify: inter-ideograph;
        margin-top: 40px;
        &.wide {
            background-color: $white;
            width: 100%;
            max-width: 100%;
            padding: 2rem;
            font-size: 2rem;
            font-weight: bold;
            line-height: 3.6rem;
            text-align: center;
            span {
                font-size: 2.4rem;
                background: linear-gradient(transparent 60%, $skyblue 0%);
            }
        }
    }
    .col-article {
        margin-top: 0;
        &.single-right {
            display: block;
            width: 70%;
            min-width: 420px;
            max-width: 768px;
            padding-left: 0px;
            margin: auto;
        }
        &.single-left {
            display: block;
            width: 70%;
            min-width: 420px;
            max-width: 768px;
            padding-left: 0px;
            margin: auto;
            padding-right: 0;
        }
        h3 {
            width: 100%;
            text-align: center;
            font-size: 2rem;
            padding: 1rem;
            border: solid 2px $af-yellowgreen;
            border-radius: 8px;
            margin-bottom: 3.2rem;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                right: 0;
                bottom: -20px;
                left: 0;
                width: 0px;
                height: 0px;
                margin: auto;
                border-style: solid;
                border-color: $af-yellowgreen transparent transparent transparent;
                border-width: 20px 20px 0 20px;
            }
        }
        p {
            font-size: 1.6rem;
            line-height: 2.56rem;
            text-align: justify;
            text-justify: inter-ideograph;
        }
    }

    // contents
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: auto;
        padding: 20px;
        background: #fff;
    }
    .content-box {
        // 省力化と生産性の向上 ********************
        &--01 {
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 35px;
                    &_photo {
                        width: 720px;
                        height: 440px;
                        background: #333;
                    }
                }
                &--02 {
                    &_img {
                        height: auto;
                        display: block;
                        margin: 2rem auto;
                        width: 70%;
                        min-width: 420px;
                        max-width: 768px;
                        &.fullwidth {
                            width: 100%;
                            min-width: 100%;
                            max-width: 100%;
                        }
                        object {
                            display: block;
                            margin: auto;
                        }
                    }
                    &_photo {
                        width: 620px;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
        .compare {
            width: 100%;
            max-width: 1282px;
            background-color: $smoke-skyblue;
            padding: 20px;
            border-radius: 8px;
            .row-box--stretch {
                justify-content: space-between;
                & > div {
                    margin-top: 4rem;
                    background-color: $af-red--lite;
                    max-width: 577px;
                }
            }
            h3 {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                background-color: $af-blue;
                border: solid 4px $white;
                height: 56px;
                border-radius: 28px;
                font-size: 24px;
            }
            .index {
                text-align: center;
                padding: 1rem;
                font-size: 2.4rem;
                color: $white;
                width: 100%;
                &.blue {
                    background-color: $af-navy;
                }
                &.gray {
                    background-color: $mid-gray;
                }
            }
            .photo {
                width: 100%;
                img {
                    width: 100%;
                }
            }
            ul {
                padding: 4rem;
                width: 100%;
                li {
                    font-size: 2rem;
                    font-weight: bold;
                    text-align: center;
                    margin: 3.2rem auto;
                    line-height: 3.2rem;
                }
                &.plus {
                    color: $af-navy;
                    background-color: $white;
                    background-image: url('../static/img/img_compare-tmbup.png');
                    background-position: top left;
                    background-repeat: no-repeat;
                    li {
                        position: relative;
                        span {
                            display: inline;
                            background: linear-gradient(transparent 60%, $skyblue 0%);
                        }
                    }
                }
                &.minus {
                    color: $af-red;
                    background-color: $af-red--lite;
                    background-image: url('../static/img/img_label-tmbdwn.svg');
                    background-image: url('../static/img/img_compare-tmbdwn.png'),
                        url('../static/img/bgimg_batsu.png');
                    background-position: top left, center center;
                    background-repeat: no-repeat, no-repeat;
                }
            }
        }
        .table-container {
            display: flex;
            width: 100%;
            max-width: 1282px;
            table:nth-child(2) {
                margin-left: 0px;
            }
        }
        .table-area {
            width: 100%;
            border-collapse: separate;
            border-spacing: 1px;
            tbody {
                display: table;
                width: 100%;
                background: darken($lite-gray, 20%);
            }
            caption {
                padding: 0 0 0.5rem;
                font-size: 1.6rem;
                text-align: right;
            }
            tr:nth-child(odd) {
                background-color: $lite-gray;
            }
            tr:nth-child(even) {
                background: darken($lite-gray, 5%);
            }
            tr:last-child {
                background: darken($smoke-skyblue, 10%);
            }
            th {
                background-color: $af-navy;
                color: $white;
                padding: 1rem;
                font-weight: bold;
                white-space: nowrap;
                font-size: 1.6rem;
            }
            td {
                text-align: right;
                padding: 8px;
                font-size: 1.6rem;
            }
        }
        .tmbup-box {
            margin: 4rem auto;
            div {
                min-width: 102px;
                max-width: 102px;
                width: 102px;
                object {
                    width: auto;
                }
            }
            p {
                font-size: 2rem;
                line-height: 3.2rem;
                font-weight: bold;
                width: 100%;
                padding-left: 20px;
            }
        }
        .description {
            text-align: left;
            font-size: 1.6rem;
            line-height: 2.56rem;
            width: 70%;
            min-width: 420px;
            max-width: 768px;
            display: block;
            margin: auto;
        }
    }
}

// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 769px) and (max-width: 1024px) {
    // common
    .spacer {
        margin-top: 2rem;
    }
    .row-box--between {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .row-box--stretch {
        display: flex;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            text-align: center;
            font-size: 2.8rem;
            line-height: 4.48rem;
            margin-bottom: 4.48rem;
            &.shade {
                text-shadow: 3px 3px 1px $smoke-skyblue;
                letter-spacing: 0rem;
            }
        }
        h3 {
            font-size: 2.8rem;
            line-height: 4.48rem;
            margin: 2rem auto;
            &.table-title {
                font-size: 2rem;
            }
        }
        h4 {
            font-size: 2.4rem;
            line-height: 4.8rem;
            margin: 4rem auto 0;
            text-align: center;
            span {
                background: linear-gradient(transparent 60%, $af-lime 0%);
            }
        }
        &_section {
            margin: 0 auto 2rem;
            text-align: center;
            h2 {
                display: inline;
                border-bottom: solid 2px $af-yellowgreen;
                text-align: center;
                font-size: 2.4rem;
                padding-bottom: 0.7rem;
            }
        }
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .lead {
        margin-top: 35px;
        &_text {
            text-align: center;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;
        }
    }
    .quote-box {
        background-color: $smoke-skyblue;
        font-size: 1.6rem;
        line-height: 3.2rem;
        letter-spacing: 0.1rem;
        max-width: 622px;
        padding: 4.32rem;
        text-align: justify;
        text-justify: inter-ideograph;
        &.wide {
            background-color: $white;
            width: 100%;
            max-width: 100%;
            padding: 2rem;
            font-size: 2rem;
            font-weight: bold;
            line-height: 4.8rem;
            text-align: center;
            span {
                font-size: 2.4rem;
            }
        }
    }
    .col-article {
        margin-top: 2rem;
        &.single-right {
            max-width: 435px;
            width: 100%;
            padding-left: 40px;
        }
        &.single-left {
            max-width: 435px;
            width: 100%;
            padding-right: 40px;
        }
        h3 {
            width: 100%;
            text-align: center;
            font-size: 2rem;
            padding: 1rem;
            border: solid 2px $af-yellowgreen;
            border-radius: 8px;
            margin-bottom: 3.2rem;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                right: 0;
                bottom: -20px;
                left: 0;
                width: 0px;
                height: 0px;
                margin: auto;
                border-style: solid;
                border-color: $af-yellowgreen transparent transparent transparent;
                border-width: 20px 20px 0 20px;
            }
        }
        p {
            font-size: 1.6rem;
            line-height: 2.56rem;
            text-align: justify;
            text-justify: inter-ideograph;
        }
    }

    // contents
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1024px;
        width: 100%;
        margin: auto;
        padding: 40px;
        background: #fff;
    }
    .content-box {
        // 省力化と生産性の向上 ********************
        &--01 {
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 35px;
                    &_photo {
                        width: 720px;
                        height: 440px;
                        background: #333;
                    }
                }
                &--02 {
                    &_img {
                        width: 100%;
                        height: auto;
                        object {
                            width: 100%;
                        }
                    }
                    &_photo {
                        width: 620px;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
        .compare {
            width: 100%;
            max-width: 1282px;
            background-color: $smoke-skyblue;
            padding: 4rem;
            border-radius: 16px;
            .row-box--stretch {
                justify-content: space-between;
                & > div {
                    margin-top: 4rem;
                    background-color: $af-red--lite;
                    max-width: 420px;
                }
            }
            h3 {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                background-color: $af-blue;
                border: solid 3px $white;
                height: 48px;
                border-radius: 24px;
                font-size: 2rem;
            }
            .index {
                text-align: center;
                padding: 1rem;
                font-size: 2rem;
                color: $white;
                width: 100%;
                &.blue {
                    background-color: $af-navy;
                }
                &.gray {
                    background-color: $mid-gray;
                }
            }
            .photo {
                width: 100%;
                img {
                    width: 100%;
                }
            }
            ul {
                padding: 4rem;
                width: 100%;
                li {
                    font-size: 2rem;
                    font-weight: bold;
                    text-align: center;
                    margin: 3.2rem auto;
                    line-height: 3.2rem;
                }
                &.plus {
                    color: $af-navy;
                    background-color: $white;
                    background-image: url('../static/img/img_compare-tmbup.png');
                    background-position: top left;
                    background-repeat: no-repeat;
                    li {
                        position: relative;
                        span {
                            display: inline;
                            background: linear-gradient(transparent 60%, $skyblue 0%);
                        }
                    }
                }
                &.minus {
                    color: $af-red;
                    background-color: $af-red--lite;
                    background-image: url('../static/img/img_label-tmbdwn.svg');
                    background-image: url('../static/img/img_compare-tmbdwn.png'),
                        url('../static/img/bgimg_batsu.png');
                    background-position: top left, center center;
                    background-repeat: no-repeat, no-repeat;
                }
            }
        }
        .table-container {
            display: flex;
            width: 100%;
            max-width: 1282px;
            table:nth-child(2) {
                margin-left: 40px;
            }
        }
        .table-area {
            width: 100%;
            border-collapse: separate;
            border-spacing: 1px;
            tbody {
                display: table;
                width: 100%;
                background: darken($lite-gray, 20%);
            }
            caption {
                padding: 0 0 0.5rem;
                font-size: 1.6rem;
                text-align: right;
            }
            tr:nth-child(odd) {
                background-color: $lite-gray;
            }
            tr:nth-child(even) {
                background: darken($lite-gray, 5%);
            }
            tr:last-child {
                background: darken($smoke-skyblue, 10%);
            }
            th {
                background-color: $af-navy;
                color: $white;
                padding: 1rem;
                font-weight: bold;
                white-space: nowrap;
                font-size: 1.6rem;
            }
            td {
                text-align: right;
                padding: 8px;
                font-size: 1.6rem;
            }
        }
        .tmbup-box {
            margin: 4rem auto;
            div {
                width: 102px;
            }
            p {
                font-size: 2.4rem;
                line-height: 3.6rem;
                font-weight: bold;
                width: 100%;
                padding-left: 40px;
            }
        }
        .description {
            text-align: justify;
            text-justify: inter-ideograph;
            font-size: 1.6rem;
            line-height: 2.56rem;
        }
    }
}
// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1025px) {
    // common
    .spacer {
        margin-top: 4rem;
    }
    .row-box--between {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
            flex: 2 0 20%;
        }
    }
    .row-box--stretch {
        display: flex;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            text-align: center;
            font-size: 3.2rem;
            line-height: 5.76rem;
            margin-bottom: 6.4rem;
            &.shade {
                text-shadow: 3px 3px 1px $smoke-skyblue;
                letter-spacing: 0.25rem;
            }
        }
        h3 {
            font-size: 2.8rem;
            line-height: 2.8rem;
            &.table-title {
                font-size: 2.4rem;
            }
        }
        h4 {
            font-size: 2.4rem;
            line-height: 4.8rem;
            margin: 4rem auto 0;
            text-align: center;
            span {
                background: linear-gradient(transparent 60%, $af-lime 0%);
            }
        }
        &_section {
            margin: 0 auto 4rem;
            text-align: center;
            h2 {
                display: inline;
                border-bottom: solid 2px $af-yellowgreen;
                text-align: center;
                font-size: 2.8rem;
                padding-bottom: 1rem;
            }
        }
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .lead {
        margin-top: 35px;
        &_text {
            text-align: center;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;
        }
    }
    .quote-box {
        background-color: $smoke-skyblue;
        font-size: 2rem;
        line-height: 4rem;
        letter-spacing: 0.1rem;
        max-width: 622px;
        padding: 4.32rem;
        text-align: justify;
        text-justify: inter-ideograph;
        p {
            width: 513px; // for IE
        }
        &.wide {
            background-color: $white;
            width: 100%;
            max-width: 100%;
            padding: 2rem;
            font-size: 2.4rem;
            font-weight: bold;
            line-height: 4.8rem;
            text-align: center;
            span {
                font-size: 2.8rem;
            }
        }
    }
    .col-article {
        margin-top: 2rem;
        &.single-right {
            max-width: 435px;
            width: 435px; // for IE
            padding-left: 40px;
            p {
                width: 287px; // for IE
            }
        }
        &.single-left {
            max-width: 435px;
            width: 435px; // for IE
            padding-right: 40px;
            p {
                width: 287px; // for IE
            }
        }
        h3 {
            width: 100%;
            text-align: center;
            font-size: 2rem;
            padding: 1rem;
            border: solid 2px $af-yellowgreen;
            border-radius: 8px;
            margin-bottom: 3.2rem;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                right: 0;
                bottom: -20px;
                left: 0;
                width: 0px;
                height: 0px;
                margin: auto;
                border-style: solid;
                border-color: $af-yellowgreen transparent transparent transparent;
                border-width: 20px 20px 0 20px;
            }
        }
        p {
            font-size: 1.6rem;
            line-height: 2.56rem;
            text-align: justify;
            text-justify: inter-ideograph;
        }
    }

    // contents
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1280px;
        margin: auto;
        padding: 40px;
        background: #fff;
    }
    .content-box {
        // 省力化と生産性の向上 ********************
        &--01 {
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 35px;
                    &_photo {
                        width: 720px;
                        height: 440px;
                        background: #333;
                    }
                }
                &--02 {
                    &_img {
                        width: 100%;
                        height: auto;
                        object {
                            width: 100%;
                        }
                    }
                    &_photo {
                        width: 620px;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
        .compare {
            width: 100%;
            max-width: 1282px;
            background-color: $smoke-skyblue;
            padding: 4rem;
            border-radius: 16px;
            .row-box--stretch {
                justify-content: space-between;
                width: 1120px; // for IE
                & > div {
                    margin-top: 4rem;
                    background-color: $af-red--lite;
                    width: 540px;
                }
            }
            h3 {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                background-color: $af-blue;
                border: solid 4px $white;
                height: 56px;
                border-radius: 28px;
                font-size: 24px;
            }
            .index {
                text-align: center;
                padding: 1rem;
                font-size: 2.4rem;
                color: $white;
                width: 100%;
                &.blue {
                    background-color: $af-navy;
                }
                &.gray {
                    background-color: $mid-gray;
                }
            }
            .photo {
                width: 100%;
                img {
                    width: 100%;
                }
            }
            ul {
                padding: 4rem;
                width: 100%;
                li {
                    font-size: 2.4rem;
                    font-weight: bold;
                    text-align: center;
                    margin: 3.6rem auto;
                    line-height: 3.6rem;
                }
                &.plus {
                    color: $af-navy;
                    background-color: $white;
                    background-image: url('../static/img/img_compare-tmbup.png');
                    background-position: top left;
                    background-repeat: no-repeat;
                    li {
                        position: relative;
                        span {
                            display: inline;
                            background: linear-gradient(transparent 60%, $skyblue 0%);
                        }
                    }
                }
                &.minus {
                    color: $af-red;
                    background-color: $af-red--lite;
                    background-image: url('../static/img/img_label-tmbdwn.svg');
                    background-image: url('../static/img/img_compare-tmbdwn.png'),
                        url('../static/img/bgimg_batsu.png');
                    background-position: top left, center center;
                    background-repeat: no-repeat, no-repeat;
                }
            }
        }
        .table-container {
            display: flex;
            width: 100%;
            max-width: 1282px;
            table:nth-child(2) {
                margin-left: 40px;
            }
        }
        .table-area {
            width: 100%;
            border-collapse: separate;
            border-spacing: 1px;
            tbody {
                display: table;
                width: 100%;
                background: darken($lite-gray, 20%);
            }
            caption {
                padding: 0 0 0.5rem;
                font-size: 1.6rem;
                text-align: right;
            }
            tr:nth-child(odd) {
                background-color: $lite-gray;
            }
            tr:nth-child(even) {
                background: darken($lite-gray, 5%);
            }
            tr:last-child {
                background: darken($smoke-skyblue, 10%);
            }
            th {
                background-color: $af-navy;
                color: $white;
                padding: 1rem;
                font-weight: bold;
                white-space: nowrap;
                font-size: 1.6rem;
            }
            td {
                text-align: right;
                padding: 8px;
                font-size: 1.6rem;
            }
        }
        .tmbup-box {
            margin: 4rem auto;
            div {
                width: 102px;
            }
            p {
                font-size: 2.4rem;
                line-height: 3.6rem;
                font-weight: bold;
                width: 100%;
                padding-left: 40px;
            }
        }
        .description {
            text-align: center;
            font-size: 1.6rem;
            line-height: 2.56rem;
        }
    }
}
</style>
