var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "container" },
    [
      _c("Header", { attrs: { menus: _vm.menuItems } }),
      _vm._v(" "),
      _c("RouterView", { attrs: { name: "KeyVisual" } }),
      _vm._v(" "),
      _c("RouterView", { attrs: { sliderImage: _vm.sliderImage } }),
      _vm._v(" "),
      _c("Footer", { attrs: { menus: _vm.menuItems } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }