<template>
    <section>
        <section class="content-box">
<!--             <div class="main-logo">
                <object data="static/img/img_aflogo-org.svg"></object>
            </div> -->
            <div class="content-box_inner">
                <div class="heading">
                    <div class="heading_img">
                        <img src="static/img/AF_logo_img.png" alt="Agri-Flyer：ロゴ" />
                    </div>
                    <div class="heading_title">
                        <img src="static/img/AF_logo_b.png" alt="Agri-Flyer：ロゴ" />
                    </div>
                    <h3 class="heading_title-sub">
                        高性能・高耐久性、<br v-if="$mq === 'sp'" />
                        次世代の農薬散布用ドローン<br v-if="$mq === 'tb'" />「アグリフライヤー」
                    </h3>
                </div>
                <div class="link">
                    <a
                        href="javascript:void(0)"
                        class="link_btn link_btn--01"
                        @mouseover="mouseOver"
                        @mouseleave="mouseLeave"
                        name="btn01"
                        :class="[activeName == 'btn01' ? 'active' : '']"
                        @click.prevent="scrollPage"
                    >
                        どんなマシン？
                    </a>
                    <a
                        href="javascript:void(0)"
                        class="link_btn link_btn--02"
                        @mouseover="mouseOver"
                        @mouseleave="mouseLeave"
                        name="btn02"
                        :class="[activeName == 'btn02' ? 'active' : '']"
                        @click.prevent="scrollPage"
                    >
                        どこがすごいの？
                    </a>
                    <a
                        href="javascript:void(0)"
                        class="link_btn link_btn--03"
                        @mouseover="mouseOver"
                        @mouseleave="mouseLeave"
                        name="btn03"
                        :class="[activeName == 'btn03' ? 'active' : '']"
                        @click.prevent="scrollPage"
                    >
                        スマート農業って？
                    </a>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
export default {
    data() {
        return {
            activeName: '',
        };
    },
    methods: {
        mouseOver(e) {
            this.activeName = e.target.getAttribute('name');
        },
        mouseLeave(e) {
            this.activeName = '';
        },
        scrollPage(e) {
            const btnName = e.target.name;
            if (btnName == 'btn01') {
                this.$scrollTo('#section-01');
            } else if (btnName == 'btn02') {
                this.$scrollTo('#section-02');
            } else if (btnName == 'btn03') {
                this.$scrollTo('#section-03');
            }
        },
    },
};
</script>

<!-- ==================================================
CSS Style
=================================================== -->
<style scoped lang="scss">
// ****************************************
// SP Layout
// ****************************************
@media screen and (max-width: 599px) {
.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 378px;
    background: #9bcd5b;
    background: url('../static/img/bgimg_kv01.jpg') no-repeat center bottom / cover;
    position: relative;
    padding: 0 20px;
    margin-top: 40px;
    position: relative;
    &_inner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .main-logo {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 60px;
        height: auto;
        object {
            width: 100%;
            height: auto;
        }
    }
}
.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    &_img {
        width: 110px;
        height: auto;
        img {
            width: 100%;
            height: auto;
        }
    }
    &_title {
        width: 160px;
        height: auto;
        margin-top: 15px;
        img {
            width: 100%;
            height: auto;
        }
        &-sub {
            font-size: 1.4rem;
            text-align: center;
            line-height: 1.6;
            margin-top: 30px;
        }
    }
}
.link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 140px;
    padding: 0 20px;
    position: absolute;
    bottom: 20px;
    &_btn {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        background: rgba(0, 0, 0, 0.7);
        border: solid 1px rgba(255, 255, 255, 0.5);
        font-size: 1.6rem;
        font-weight: bold;
        color: #fff;
    }
}
}
// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 1024px) {
    .content-box {
        height: 610px;
        background: url('../static/img/bgimg_kv01.png') no-repeat center center / cover;
        margin-bottom: 0;
        margin-top: 60px;
        position: relative;
        &_inner {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .main-logo {
            position: absolute;
            top: 25px;
            right: 30px;
            width: 120px;
            height: auto;
            object {
                width: 100%;
                height: auto;
            }
        }
    }
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 90px;
        &_img {
            width: 200px;
            height: auto;
            img {
                width: 100%;
                height: auto;
            }
        }
        &_title {
            width: 240px;
            height: auto;
            margin-top: 15px;
            img {
                width: 100%;
                height: auto;
            }
            &-sub {
                font-size: 2.4rem;
                text-align: center;
                line-height: 1.6;
                margin-top: 30px;
            }
        }
    }
    .link {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 660px;
        height: auto;
        position: absolute;
        bottom: 35px;
        &_btn {
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 180px;
            height: 38px;
            border: none;
            font-size: 1.6rem;
            font-weight: bold;
            position: relative;
            &::before {
                content: '';
                display: flex;
                justify-content: center;
                align-items: center;
                width: 140px;
                height: 140px;
                background: rgba(0, 0, 0, 0.7);
                border-radius: 50%;
                position: absolute;
                top: -170px;
            }
            &--01 {
                &::before {
                    background: url('../static/img/btn_kvfloat-what-tb.png') no-repeat center center;
                }
                &.active::before {
                    background: url('../static/img/btn_kvfloat-what-hov-tb.png') no-repeat center
                        center;
                }
            }
            &--02 {
                &::before {
                    background: url('../static/img/btn_kvfloat-feature-tb.png') no-repeat center
                        center;
                }
                &.active::before {
                    background: url('../static/img/btn_kvfloat-feature-hov-tb.png') no-repeat center
                        center;
                }
            }
            &--03 {
                &::before {
                    background: url('../static/img/btn_kvfloat-sfarm-tb.png') no-repeat center
                        center;
                }
                &.active::before {
                    background: url('../static/img/btn_kvfloat-sfarm-hov-tb.png') no-repeat center
                        center;
                }
            }
        }
    }
}

// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1025px) {
    .content-box {
        height: 610px;
        background: url('../static/img/bgimg_kv01.png') no-repeat center center / cover;
        margin-bottom: 0;
        position: relative;
        &_inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 1280px;
            max-width: 1360px;
            margin: auto;
        }
        .main-logo {
            position: absolute;
            top: 25px;
            right: 30px;
            width: 180px;
            height: auto;
            object {
                width: 100%;
                height: auto;
            }
        }
    }
    .heading {
        margin: 80px auto 0;
        &_img {
            width: auto;
            height: 66px;
            img {
                width: auto;
                height: 100%;
                margin: auto;
            }
        }
        &_title {
            width: 296px;
            height: auto;
            margin: 20px auto 0;
            img {
                width: 100%;
                height: auto;
            }
            &-sub {
                font-size: 2.8rem;
                text-align: center;
                line-height: 1.6;
                margin-top: 60px;
            }
        }
    }
    .link {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 840px;
        height: auto;
        position: absolute;
        bottom: 35px;
        left: 50%; // for IE
        transform: translate(-50%); // for IE
        &_btn {
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 216px;
            height: 38px;
            border: none;
            font-size: 1.8rem;
            font-weight: bold;
            position: relative;
            &::before {
                content: '';
                display: flex;
                justify-content: center;
                align-items: center;
                width: 156px;
                height: 156px;
                background: rgba(0, 0, 0, 0.7);
                border-radius: 50%;
                font-size: 1.6rem;
                font-weight: bold;
                position: absolute;
                top: -170px;
            }
            &--01 {
                &::before {
                    background: url('../static/img/btn_kvfloat-what.png') no-repeat center center;
                }
                &.active::before {
                    background: url('../static/img/btn_kvfloat-what-hov.png') no-repeat center
                        center;
                }
            }
            &--02 {
                &::before {
                    background: url('../static/img/btn_kvfloat-feature.png') no-repeat center center;
                }
                &.active::before {
                    background: url('../static/img/btn_kvfloat-feature-hov.png') no-repeat center
                        center;
                }
            }
            &--03 {
                &::before {
                    background: url('../static/img/btn_kvfloat-sfarm.png') no-repeat center center;
                }
                &.active::before {
                    background: url('../static/img/btn_kvfloat-sfarm-hov.png') no-repeat center
                        center;
                }
            }
        }
    }
}
</style>
