var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("section", { staticClass: "content-box content-box--01" }, [
      _c("div", { staticClass: "content-box_inner" }, [
        _c("div", { staticClass: "heading" }, [
          _vm.$mq === "pc"
            ? _c("div", { staticClass: "heading_title" }, [
                _c("img", {
                  attrs: {
                    src: "static/img/inquiry_img_01.png",
                    alt: "CONTACT"
                  }
                })
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.inputArea,
                expression: "inputArea"
              }
            ],
            staticClass: "lead"
          },
          [
            _c("p", { staticClass: "lead_text--01" }, [
              _vm._v("\n                    Agri Flyerについて、"),
              _vm.$mq === "sp" ? _c("br") : _vm._e(),
              _vm._v("資料請求や詳しい説明をご希望のお客様は、"),
              _c("br"),
              _vm._v("\n                    下のフォームに必要事項を"),
              _vm.$mq === "sp" ? _c("br") : _vm._e(),
              _vm._v("入力してご送信ください。\n                ")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "lead_text--02" }, [
              _vm._v("\n                    なお、ご質問への回答には、"),
              _vm.$mq === "sp" ? _c("br") : _vm._e(),
              _vm._v("通常３〜４営業日をいただいております。"),
              _c("br"),
              _vm._v("\n                    ご了承いただきますよう、"),
              _vm.$mq === "sp" ? _c("br") : _vm._e(),
              _vm._v("よろしくお願い申し上げます。\n                ")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.confirmArea,
                expression: "confirmArea"
              }
            ],
            staticClass: "lead"
          },
          [
            _c("p", { staticClass: "lead_text--01" }, [
              _vm._v("\n                    入力内容をご確認ください。"),
              _c("br"),
              _vm._v("\n                    内容に相違がなければ"),
              _vm.$mq === "sp" ? _c("br") : _vm._e(),
              _vm._v("「この内容で送信する」で送信ください。\n                ")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.finishArea,
                expression: "finishArea"
              }
            ],
            staticClass: "lead lead--finish"
          },
          [
            _c("p", { staticClass: "lead_text--01" }, [
              _vm._v(
                "\n                    送信完了しました。\n                "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "lead_text--02" }, [
              _vm._v(
                "\n                    フォームからお問い合わせメールを送信しました。"
              ),
              _vm.$mq === "pc" ? _c("br") : _vm._e(),
              _vm._v(
                "\n                    追って担当者よりご連絡さしあげます。\n                "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-area" }, [
          !_vm.$v.nameArea.$error
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.inputArea,
                      expression: "inputArea"
                    }
                  ],
                  staticClass: "input-error"
                },
                [_c("span", [_vm._v("全角文字を入力してください")])]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.inputArea,
                  expression: "inputArea"
                }
              ],
              staticClass: "form-area_name inputArea"
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.nameArea,
                      expression: "nameArea"
                    }
                  ],
                  staticClass: "inputText",
                  attrs: {
                    type: "text",
                    name: "input-name",
                    placeholder: "〇〇太郎"
                  },
                  domProps: { value: _vm.nameArea },
                  on: {
                    change: function($event) {
                      return _vm.$v.nameArea.$touch()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.nameArea = $event.target.value
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.confirmArea,
                  expression: "confirmArea"
                }
              ],
              staticClass: "form-area_name confirmArea"
            },
            [
              _vm._m(1),
              _vm._v(" "),
              _c("dd", [_c("p", [_vm._v(_vm._s(_vm.nameArea))])])
            ]
          ),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.inputArea,
                  expression: "inputArea"
                }
              ],
              staticClass: "form-area_company inputArea"
            },
            [
              _vm._m(2),
              _vm._v(" "),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.companyArea,
                      expression: "companyArea"
                    }
                  ],
                  staticClass: "inputText",
                  attrs: {
                    type: "text",
                    name: "input-company",
                    placeholder: "株式会社○○○○"
                  },
                  domProps: { value: _vm.companyArea },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.companyArea = $event.target.value
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.confirmArea,
                  expression: "confirmArea"
                }
              ],
              staticClass: "form-area_company confirmArea"
            },
            [
              _vm._m(3),
              _vm._v(" "),
              _c("dd", [_c("p", [_vm._v(_vm._s(_vm.companyArea))])])
            ]
          ),
          _vm._v(" "),
          !_vm.$v.mailArea.$error
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.inputArea,
                      expression: "inputArea"
                    }
                  ],
                  staticClass: "input-error"
                },
                [_c("span", [_vm._v("正しいメールアドレスを入力してください")])]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.inputArea,
                  expression: "inputArea"
                }
              ],
              staticClass: "form-area_mail inputArea"
            },
            [
              _vm._m(4),
              _vm._v(" "),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mailArea,
                      expression: "mailArea"
                    }
                  ],
                  staticClass: "inputText",
                  attrs: {
                    type: "text",
                    name: "input-mail",
                    placeholder: "ishikawa@example.com"
                  },
                  domProps: { value: _vm.mailArea },
                  on: {
                    change: function($event) {
                      return _vm.$v.mailArea.$touch()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.mailArea = $event.target.value
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.confirmArea,
                  expression: "confirmArea"
                }
              ],
              staticClass: "form-area_mail confirmArea"
            },
            [
              _vm._m(5),
              _vm._v(" "),
              _c("dd", [_c("p", [_vm._v(_vm._s(_vm.mailArea))])])
            ]
          ),
          _vm._v(" "),
          !_vm.$v.contentArea.$error
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.inputArea,
                      expression: "inputArea"
                    }
                  ],
                  staticClass: "input-error"
                },
                [_c("span", [_vm._v("お問い合わせ内容を選択してください")])]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.inputArea,
                  expression: "inputArea"
                }
              ],
              staticClass: "form-area_content inputArea"
            },
            [
              _vm._m(6),
              _vm._v(" "),
              _c("dd", [
                _c("div", { staticClass: "radio" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.contentArea,
                        expression: "contentArea"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      name: "radio-content",
                      placeholder: "",
                      id: "radio-1",
                      value: "資料請求（PDF）"
                    },
                    domProps: {
                      checked: _vm._q(_vm.contentArea, "資料請求（PDF）")
                    },
                    on: {
                      change: [
                        function($event) {
                          _vm.contentArea = "資料請求（PDF）"
                        },
                        function($event) {
                          return _vm.$v.contentArea.$touch()
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "radio-label", attrs: { for: "radio-1" } },
                    [_vm._v("資料請求（PDF）")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "radio" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.contentArea,
                        expression: "contentArea"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      name: "radio-content",
                      placeholder: "",
                      id: "radio-2",
                      value: "資料請求（郵送）"
                    },
                    domProps: {
                      checked: _vm._q(_vm.contentArea, "資料請求（郵送）")
                    },
                    on: {
                      change: [
                        function($event) {
                          _vm.contentArea = "資料請求（郵送）"
                        },
                        function($event) {
                          return _vm.$v.contentArea.$touch()
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "radio-label", attrs: { for: "radio-2" } },
                    [_vm._v("資料請求（郵送）")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "radio" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.contentArea,
                        expression: "contentArea"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      name: "radio-content",
                      placeholder: "",
                      id: "radio-3",
                      value: "お問い合わせ（メール希望）"
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.contentArea,
                        "お問い合わせ（メール希望）"
                      )
                    },
                    on: {
                      change: [
                        function($event) {
                          _vm.contentArea = "お問い合わせ（メール希望）"
                        },
                        function($event) {
                          return _vm.$v.contentArea.$touch()
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "radio-label", attrs: { for: "radio-3" } },
                    [_vm._v("お問い合わせ（メール希望）")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "radio" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.contentArea,
                        expression: "contentArea"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      name: "radio-content",
                      placeholder: "",
                      id: "radio-4",
                      value: "お問い合わせ（電話希望）"
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.contentArea,
                        "お問い合わせ（電話希望）"
                      )
                    },
                    on: {
                      change: [
                        function($event) {
                          _vm.contentArea = "お問い合わせ（電話希望）"
                        },
                        function($event) {
                          return _vm.$v.contentArea.$touch()
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "radio-label", attrs: { for: "radio-4" } },
                    [_vm._v("お問い合わせ（電話希望）")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "radio" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.contentArea,
                        expression: "contentArea"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      name: "radio-content",
                      placeholder: "",
                      id: "radio-5",
                      value: "その他"
                    },
                    domProps: { checked: _vm._q(_vm.contentArea, "その他") },
                    on: {
                      change: [
                        function($event) {
                          _vm.contentArea = "その他"
                        },
                        function($event) {
                          return _vm.$v.contentArea.$touch()
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "radio-label", attrs: { for: "radio-5" } },
                    [_vm._v("その他")]
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.confirmArea,
                  expression: "confirmArea"
                }
              ],
              staticClass: "form-area_content confirmArea"
            },
            [
              _vm._m(7),
              _vm._v(" "),
              _c("dd", { staticClass: "content" }, [
                _c("p", [_vm._v(_vm._s(_vm.contentArea))])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.inputArea,
                  expression: "inputArea"
                }
              ],
              staticClass: "form-area_zip inputArea"
            },
            [
              _c("dt", [
                _vm._v(
                  "\n                        郵便番号\n                    "
                )
              ]),
              _vm._v(" "),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.zipArea,
                      expression: "zipArea"
                    }
                  ],
                  staticClass: "inputText",
                  attrs: {
                    type: "text",
                    name: "input-zip",
                    placeholder: "0000000"
                  },
                  domProps: { value: _vm.zipArea },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.zipArea = $event.target.value
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.confirmArea,
                  expression: "confirmArea"
                }
              ],
              staticClass: "form-area_zip confirmArea"
            },
            [
              _c("dt", [
                _vm._v(
                  "\n                        郵便番号\n                    "
                )
              ]),
              _vm._v(" "),
              _c("dd", [_c("p", [_vm._v(_vm._s(_vm.zipArea))])])
            ]
          ),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.inputArea,
                  expression: "inputArea"
                }
              ],
              staticClass: "form-area_address--01 inputArea"
            },
            [
              _c("dt", [
                _vm._v(
                  "\n                        都道府県\n                    "
                )
              ]),
              _vm._v(" "),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.addArea01,
                      expression: "addArea01"
                    }
                  ],
                  staticClass: "inputText",
                  attrs: {
                    type: "text",
                    name: "input-address-01",
                    placeholder: "〇〇県"
                  },
                  domProps: { value: _vm.addArea01 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.addArea01 = $event.target.value
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.inputArea,
                  expression: "inputArea"
                }
              ],
              staticClass: "form-area_address--02 inputArea"
            },
            [
              _c("dt", [
                _vm._v(
                  "\n                        市区町村\n                    "
                )
              ]),
              _vm._v(" "),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.addArea02,
                      expression: "addArea02"
                    }
                  ],
                  staticClass: "inputText",
                  attrs: {
                    type: "text",
                    name: "input-address-02",
                    placeholder: "〇〇市"
                  },
                  domProps: { value: _vm.addArea02 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.addArea02 = $event.target.value
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.inputArea,
                  expression: "inputArea"
                }
              ],
              staticClass: "form-area_address--03 inputArea"
            },
            [
              _c("dt", [
                _vm._v(
                  "\n                        住所詳細\n                    "
                )
              ]),
              _vm._v(" "),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.addArea03,
                      expression: "addArea03"
                    }
                  ],
                  staticClass: "inputText",
                  attrs: {
                    type: "text",
                    name: "input-address-03",
                    placeholder: "〇〇町1-1-1"
                  },
                  domProps: { value: _vm.addArea03 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.addArea03 = $event.target.value
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.confirmArea,
                  expression: "confirmArea"
                }
              ],
              staticClass: "form-area_address--03 confirmArea"
            },
            [
              _c("dt", [
                _vm._v("\n                        住 所\n                    ")
              ]),
              _vm._v(" "),
              _c("dd", [_c("p", [_vm._v(_vm._s(_vm.addAreaAll))])])
            ]
          ),
          _vm._v(" "),
          _vm.$v.contentArea.$model === "お問い合わせ（電話希望）"
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.inputArea,
                      expression: "inputArea"
                    }
                  ],
                  staticClass: "input-error"
                },
                [
                  _c("span", [
                    _vm._v(
                      "「内容」で「お問い合わせ（電話希望）」を選択した方は必ずご入力ください"
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.inputArea,
                  expression: "inputArea"
                }
              ],
              staticClass: "form-area_tel inputArea"
            },
            [
              _c("dt", [
                _vm._v(
                  "\n                        電話番号\n                    "
                )
              ]),
              _vm._v(" "),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.telArea,
                      expression: "telArea"
                    }
                  ],
                  staticClass: "inputText",
                  attrs: {
                    type: "text",
                    name: "input-address-tel",
                    placeholder: "00000000000"
                  },
                  domProps: { value: _vm.telArea },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.telArea = $event.target.value
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.confirmArea,
                  expression: "confirmArea"
                }
              ],
              staticClass: "form-area_tel confirmArea"
            },
            [
              _c("dt", [
                _vm._v(
                  "\n                        電話番号\n                    "
                )
              ]),
              _vm._v(" "),
              _c("dd", [_c("p", [_vm._v(_vm._s(_vm.telArea))])])
            ]
          ),
          _vm._v(" "),
          _vm.$v.contentArea.$model === "お問い合わせ（メール希望）" ||
          _vm.$v.contentArea.$model === "お問い合わせ（電話希望）"
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.inputArea,
                      expression: "inputArea"
                    }
                  ],
                  staticClass: "input-error"
                },
                [
                  _c("span", [
                    _vm._v(
                      "「内容」で「お問い合わせ」を選択した方は必ずご入力ください"
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.inputArea,
                  expression: "inputArea"
                }
              ],
              staticClass: "form-area_text inputArea"
            },
            [
              _c("dt", [
                _vm._v(
                  "\n                        お問い合わせ内容\n                    "
                )
              ]),
              _vm._v(" "),
              _c("dd", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.textArea,
                      expression: "textArea"
                    }
                  ],
                  staticClass: "inputTextArea",
                  attrs: {
                    name: "textarea-text",
                    placeholder: "お問い合わせ内容をご記入ください"
                  },
                  domProps: { value: _vm.textArea },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.textArea = $event.target.value
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "dl",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.confirmArea,
                  expression: "confirmArea"
                }
              ],
              staticClass: "form-area_text confirmArea"
            },
            [
              _c("dt", [
                _vm._v(
                  "\n                        お問い合わせ内容\n                    "
                )
              ]),
              _vm._v(" "),
              _c("dd", { staticClass: "textarea" }, [
                _c("p", [_vm._v(_vm._s(_vm.textArea))])
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.inputArea,
                expression: "inputArea"
              }
            ],
            staticClass: "confirm"
          },
          [
            _c(
              "a",
              {
                staticClass: "confirm_btn",
                class: [_vm.$v.$invalid ? "" : "active"],
                attrs: { disabled: _vm.$v.$invalid },
                on: { click: _vm.dataConfirm }
              },
              [
                _vm._v(
                  "\n                    入力内容確認画面へ\n                "
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.confirmArea,
                expression: "confirmArea"
              }
            ],
            staticClass: "back"
          },
          [
            _c("a", { staticClass: "back_btn", on: { click: _vm.goBack } }, [
              _vm._v("\n                    内容を修正する\n                ")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.confirmArea,
                expression: "confirmArea"
              }
            ],
            staticClass: "submit"
          },
          [
            _c(
              "a",
              { staticClass: "submit_btn", on: { click: _vm.dataSend } },
              [
                _vm._v(
                  "\n                    この内容で送信する\n                "
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.finishArea,
                expression: "finishArea"
              }
            ],
            staticClass: "top"
          },
          [
            _c("a", { staticClass: "top_btn", on: { click: _vm.goTop } }, [
              _vm._v("\n                    TOPにもどる\n                ")
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dt", [
      _c("span", { staticClass: "required" }, [_vm._v("お名前")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dt", [_c("span", [_vm._v("お名前")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dt", [_c("span", [_vm._v("法人名")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dt", [_c("span", [_vm._v("法人名")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dt", [
      _c("span", { staticClass: "required" }, [_vm._v("Email")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dt", [
      _c("span", { staticClass: "required" }, [_vm._v("Email")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dt", [
      _c("span", { staticClass: "required" }, [_vm._v("内 容")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dt", [
      _c("span", { staticClass: "required" }, [_vm._v("内 容")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }