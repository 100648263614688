<template>
    <section>
        <section class="content-box">
            <div class="heading">
                <div class="heading_inner">
                    <div class="heading_title">
                        <p>
                            よくある質問
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
export default {
    // mounted() {
    //     console.log('Component mounted.')
    // }
};
</script>

<!-- ==================================================
CSS Style
=================================================== -->
<style scoped lang="scss">
// ****************************************
// SP Layout
// ****************************************
@media screen and (max-width: 599px) {
    .content-box {
        margin-top: 40px;
    }
    .heading {
        &_title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 40px;
            background: $keyVisualHeadingTitleBg;
            border: solid 1px $af-blue;
            font-size: 2rem;
            font-weight: bold;
            color: #fff;
            letter-spacing: 3px;
        }
    }
}

// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 1024px) {
    .content-box {
        margin-top: 60px;
    }
    .heading {
        &_title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 40px;
            background: $keyVisualHeadingTitleBg;
            border: solid 1px $af-blue;
            font-size: 2rem;
            font-weight: bold;
            color: #fff;
            letter-spacing: 3px;
        }
    }
}

// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1025px) {
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .heading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 120px;
        @include stripe();
        position: relative;
        &_inner {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 1280px;
            max-width: 1360px;
            width: 100%;
            height: 100%;
            position: relative;
        }
        &_title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 450px;
            height: 50px;
            background: $keyVisualHeadingTitleBg;
            border: solid 1px $af-blue;
            font-size: 2.8rem;
            font-weight: bold;
            color: #fff;
            letter-spacing: 3px;
        }
    }
}
</style>
