var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "contents" }, [
      _c("ul", [
        _c("li", [
          _c("figure", [
            _c("img", {
              attrs: {
                src: "static/img/img_lineup-afr.png",
                alt:
                  "高性能・高耐久性、 次世代の農薬散布用ドローン「アグリフライヤー」"
              }
            }),
            _vm._v(" "),
            _c("figcaption", [
              _c("span", [_vm._v('知能を備えたニューエディション"Type-R"')]),
              _vm._v("「アグリフライヤー type-R」")
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://agri-flyer-r.ier-prod.jp/",
                  target: "_blank"
                }
              },
              [_vm._v("アグリフライヤー type-R サイトへ")]
            )
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("figure", [
            _c("img", {
              attrs: {
                src: "static/img/img_lineup-bf.png",
                alt: "高耐久／測量・空撮用ドローン「ビルドフライヤー」"
              }
            }),
            _vm._v(" "),
            _c("figcaption", [
              _c("span", [_vm._v("高耐久／測量・空撮用ドローン")]),
              _vm._v("「ビルドフライヤー」")
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://build-flyer.ier-prod.jp/",
                  target: "_blank"
                }
              },
              [_vm._v("ビルドフライヤーサイトへ")]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }