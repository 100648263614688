<template>
    <section>
        <section class="heading_image" v-if="$mq==='sp'">
            <div class="index_image">
                <img src="static/img/img_faq-sp.png" alt="FAQ">
            </div>
        </section>
        <section class="heading_image" v-if="$mq==='pc'">
            <div class="index_image">
                <img src="static/img/img_faq-pc.png" alt="FAQ">
            </div>
        </section>
        <section class="content-box content-box--01">
            <div class="heading">
                <h2 class="faq">─ Agri Flyer 機体について ─</h2>
            </div>
            <dl class="faq">
                <dt>
                    <div class="row-box--faq">
                        <div class="headletter" v-if="$mq==='spl'||$mq==='sp'">
                            <object type="image/svg+xml" data="static/img/headletter-q-sp.svg"></object>
                        </div>
                        <div class="headletter" v-if="$mq==='tb'||$mq==='pc'">
                            <img src="static/img/headletter-q-pc.svg">
                        </div>
                        <p>Agri Flyerの特長を教えてください</p>
                    </div>
                </dt>
                <dd>
                    <div class="row-box--faq">
                        <div class="headletter" v-if="$mq==='spl'||$mq==='sp'">
                            <object type="image/svg+xml" data="static/img/headletter-a-sp.svg"></object>
                        </div>
                        <div class="headletter" v-if="$mq==='tb'||$mq==='pc'">
                            <img src="static/img/headletter-a-pc.svg">
                        </div>
                        <p>
                            <ul>
                                <li>マグネシウム合金製の軽量・高耐久性機体</li>
                                <li>専用に開発された日本製モーター＆ドライバー</li>
                                <li>要素部品を効率的に統合、煩雑になりがちなハーネス類をすっきりと整理</li>
                                <li>流量センサーを用いたフィードバック制御による定量散布</li>
                                <li>定量散布と自動一定速飛行による高精度な散布を実現</li>
                                <li>容易な薬剤注入を可能にしたタンク設計。オプションで粒剤散布も可能</li>
                            </ul>
                        </p>
                    </div>
                </dd>
            </dl>
            <dl class="faq">
                <dt>
                    <div class="row-box--faq">
                        <div class="headletter" v-if="$mq==='spl'||$mq==='sp'">
                            <object type="image/svg+xml" data="static/img/headletter-q-sp.svg"></object>
                        </div>
                        <div class="headletter" v-if="$mq==='tb'||$mq==='pc'">
                            <img src="static/img/headletter-q-pc.svg">
                        </div>
                        <p>総重量、飛行時間、散布容量などのスペックを教えてください</p>
                    </div>
                </dt>
                <dd>
                    <div class="row-box--faq">
                        <div class="headletter" v-if="$mq==='spl'||$mq==='sp'">
                            <object type="image/svg+xml" data="static/img/headletter-a-sp.svg"></object>
                        </div>
                        <div class="headletter" v-if="$mq==='tb'||$mq==='pc'">
                            <img src="static/img/headletter-a-pc.svg">
                        </div>
                        <p>性能他、上記スペックの一覧は “スペック” ページでご確認いただけます</p>
                    </div>
                </dd>
            </dl>
            <dl class="faq">
                <dt>
                    <div class="row-box--faq">
                        <div class="headletter" v-if="$mq==='spl'||$mq==='sp'">
                            <object type="image/svg+xml" data="static/img/headletter-q-sp.svg"></object>
                        </div>
                        <div class="headletter" v-if="$mq==='tb'||$mq==='pc'">
                            <img src="static/img/headletter-q-pc.svg">
                        </div>
                        <p>自動運転は可能ですか？</p>
                    </div>
                </dt>
                <dd>
                    <div class="row-box--faq">
                        <div class="headletter" v-if="$mq==='spl'||$mq==='sp'">
                            <object type="image/svg+xml" data="static/img/headletter-a-sp.svg"></object>
                        </div>
                        <div class="headletter" v-if="$mq==='tb'||$mq==='pc'">
                            <img src="static/img/headletter-a-pc.svg">
                        </div>
                        <p>現モデルは半自動散布となります。ホバリング後に、散布補助モード（M＋モード）に切り替えると、前後進時に散布、スイッチ操作で無散布での横移動も可能です。現在、全自動散布飛行を主体とした次期モデルを開発中です</p>
                    </div>
                </dd>
            </dl>
            <div class="heading">
                <h2 class="faq">─ ご購入・運用について ─</h2>
            </div>
            <dl class="faq">
                <dt>
                    <div class="row-box--faq">
                        <div class="headletter" v-if="$mq==='spl'||$mq==='sp'">
                            <object type="image/svg+xml" data="static/img/headletter-q-sp.svg"></object>
                        </div>
                        <div class="headletter" v-if="$mq==='tb'||$mq==='pc'">
                            <img src="static/img/headletter-q-pc.svg">
                        </div>
                        <p>保険や機体補償等について教えてください</p>
                    </div>
                </dt>
                <dd>
                    <div class="row-box--faq">
                        <div class="headletter" v-if="$mq==='spl'||$mq==='sp'">
                            <object type="image/svg+xml" data="static/img/headletter-a-sp.svg"></object>
                        </div>
                        <div class="headletter" v-if="$mq==='tb'||$mq==='pc'">
                            <img src="static/img/headletter-a-pc.svg">
                        </div>
                        <p>機体購入初年度は、自賠責保険の保険料は機体価格に付帯させていただいております。2年目以降は、任意にて自賠責保険にご加入いただけます。また、墜落や衝突などの偶発的な事故により、ドローンに生じた財物損害を補償する「動産総合保険」をご用意しており、任意でご加入いただけます。
                            <ul class="example">
                                <li>①年間保険料：機体時価（補償額）の６％（例）補償額150万円の場合、9万円</li>
                                <li>②支払額：補償額（免責は補償額の１％）（例）補償額150万円の場合、1.5万円が免責</li>
                            </ul>
                           詳細な説明をご希望の方は、お問い合わせフォームにて必要事項をご入力の上ご送信ください。担当者より、詳しくご説明させていただきます。
                        </p>
                    </div>
                </dd>
            </dl>
        </section>
    </section>
</template>

<script>
    export default {

    }
</script>

<!-- ==================================================
CSS Style
=================================================== -->
<style scoped lang="scss">

// ****************************************
// SP Layout
// ****************************************

// common
.row-box--between {
    display: flex;
    flex-direction: column;
    width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
}
.row-box--stretch {
    display: flex;
    flex-direction: column;
    width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
}
.row-box--faq {
    display: flex;
    flex-direction: row;
    width: 100%;
        & > div {
            display: flex;
            align-items: flex-start;
        }
}

.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
    &.emphasis{
        h2{
            text-align: center;
            font-size: 1.6rem;
            line-height: 2.56rem;
            margin-bottom: 2rem;
            letter-spacing: .1rem;
        }
    }
    h2 {
        text-align: center;
        font-size: 1.6rem;
        line-height: 2.56rem;
        margin-bottom: 2rem;
        &.faq{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100px;
            background-image: url("../static/img/img_shilhouette.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }
    h3 {
        font-size: 1.6rem;
        line-height: 2.56rem;
        text-align: center;
        margin: auto;
        &.table-title{
            font-size: 1.6rem;
        }
    }
    h4 {
        font-size: 1.4rem;
        line-height: 2.24rem;
        margin: auto;
        text-align: center;
        text-align: justify;
        text-justify: inter-ideograph;
    }
    &_section{
        margin: 0 auto 2rem;
        text-align: center;
        h2{
            display: inline;
            border-bottom: solid 2px $af-yellowgreen;
            text-align: center;
            font-size: 1.6rem;
            padding-bottom: .5rem;
        }
    }
    &_title {
        font-size: 3.2rem;
        text-align: center;
        &-sub {
            font-size: 1.8rem;
            font-weight: normal;
            color: #007a0c;
            text-align: center;
            margin-top: 20px;
        }
    }
}
.lead {
    margin-top: 35px;
    &_text {
        text-align: center;
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 1.5;
    }
}
.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    &_title {
        font-size: 1.8rem;
        text-align: center;
        &-sub {
            font-size: 1.4rem;
            font-weight: normal;
            color: #007a0c;
            text-align: center;
            margin-top: 8px;
        }
    }
}
.lead {
    margin-top: 20px;
    &_text {
        text-align: center;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.5;
        margin-top: 20px;
        white-space: nowrap;
    }
}
.read-box{
    font-size: 1.6rem;
    line-height: 2.56rem;
    margin-top: 2rem;
}
.option-box{
    border: solid 2px $af-blue;
    background-color: $white;
    width: 100%;
    padding: 2rem;
    margin-top: 1rem;
    border-radius: 8px;
    img{
        width: 100%;
    }
    p{
        font-size: 1.5rem;
        line-height: 2.4rem;
    }
}
.flight-assist{
    width: 100%;
    div{
        width: 100%;
        object{
        width: 100%;
        }
    }
}
// contents
.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0px 20px 40px;
    background: #fff;
}
.content-box {
    // FEATURE01 ********************
    &--01 {
        .images {
            &--01 {
                width: 100%;
                margin-top: 10px;
                &_photo {
                    width: 100%;
                    height: 200px;
                    background: #333;
                }
            }
            &--02 {
                display: flex;
                justify-content: center;
                &_img{
                    width: 100%;
                    img{
                        width: 100%;
                    }
                }
                &_photo {
                    width: 90%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .feature {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            .caption {
                display: flex;
                flex-direction: column;
                align-items: center;
                &_title {
                    margin-top: 12px;
                    width: 200px;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    &-sub {
                        p {
                            font-size: 1.4rem;
                            font-weight: bold;
                            text-align: center;
                            line-height: 1.5;
                            margin-top: 10px;
                        }
                    }
                }
                &_text {
                    line-height: 1.5;
                    margin-top: 15px;
                }
            }
            .link {
                width: 100%;
                margin-top: 30px;
                &_btn {
                    @include btn-detail-sp();
                }
            }
        }
    }
    // FEATURE02 ********************
    &--02 {
        .feature {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 30px;
            .col-box {
                width: 100%;
                background: #fff;
                border: solid 1px #B1D2E7;
                padding: 0 10px 10px;
                margin-top: 20px;
                &:first-child {
                    margin-top: 0;
                }
                .images {
                    &_photo {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                .caption_text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100px;
                    font-size: 1.6rem;
                    font-weight: bold;
                    line-height: 1.6;
                    text-align: center;
                }
            }
        }
        .link {
            width: 100%;
            margin-top: 30px;
            &_btn {
                @include btn-detail-sp();
            }
        }
    }
    // FEATURE03 ********************
    &--03 {
        background-color: $af-feature-mat;
        .images {
            &--01 {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                &_img {
                    width: 80%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            &--02 {
                display: flex;
                justify-content: center;
                margin-top: 40px;
                &_img {
                    width: 100%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    object {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .caption {
            &--01 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                background: #F7F7E8;
                border: solid 2px #82C580;
                border-radius: 12px;
                padding: 20px 20px;
                margin-top: 30px;
                &_title {
                    font-size: 1.6rem;
                    font-weight: bold;
                }
                &_text {
                    font-size: 1.6rem;
                    line-height: 1.6;
                    margin-top: 15px;
                    text-align: justify;
                }
            }
            &--02 {
                margin-top: 30px;
                &_text {
                    font-size: 1.6rem;
                    line-height: 1.8;
                    font-weight: bold;
                    text-align: center;
                    .marker {
                        background:linear-gradient(transparent 50%, #EAFF80 0);
                    }
                }
            }
        }
        .balloon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            background: #fff;
            border: solid 2px #0B4271;
            border-radius: 8px;
            padding: 10px;
            margin-top: 30px;
            &_text {
                font-size: 1.4rem;
                .strong {
                    font-size: 1.8rem;
                    font-weight: bold;
                    color: #E6001C;
                    .mini {
                        font-size: 1.4rem;
                    }
                }
            }
            position: relative;
            @include triangle($width: 20px, $height: 20px, $top: -20px, $left: 50%, $translate: translate(-50%));
        }
        .link {
            width: 100%;
            margin-top: 30px;
            &_btn {
                @include btn-detail-sp();
            }
        }
    }

}

.faq{
    width: 100%;
    dt{
        color: $af-navy;
        font-weight: bold;
        font-size: 1.6rem;
        padding: 1.6rem;
        position: relative;
        line-height: 2.56rem;
        p{
            padding-left: 1rem;
        }
    }
    dd{
        position: relative;
        background-color: $af-lite-green;
        border-radius: 8px;
        font-size: 1.6rem;
        line-height: 2.56rem;
        padding: 1.6rem;
        p{
            padding-left: 1rem;
            ul{
                &.example{
                    background-color: rgba(255, 255, 255, 0.5);
                    margin: 1rem auto;
                    padding: 1.6rem;
                    font-size: 1.4rem;
                    border-radius: 4px;
                }
                li{
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
.headletter{
    object{
        width: auto;
        position: relative;
        top: 2px;
    }
}
.heading_image {
    display: flex;
    justify-content: center;
    .index_image{
        width: 455px;
        img{
            width: 100%;
            height: auto;
        }
    }
}

// ****************************************
// SPL Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 768px) {
    .faq{
        width: 510px;
    }
}

// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .faq{
        width: 800px;
    }
    .headletter {
        img {
        width: 27px;
        height: 36px;
        object-fit: contain;
    }
    }
}

// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1025px) {
    // common
.row-box--between {
    display: flex;
    flex-direction: column;
    width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
}
.row-box--stretch {
    display: flex;
    flex-direction: column;
    width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
}
.row-box--faq {
    display: flex;
    flex-direction: row;
    width: 1178px;
        & > div {
            display: flex;
            align-items: flex-start;
        }
}

.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
    &.emphasis{
        h2{
            text-align: center;
            font-size: 1.6rem;
            line-height: 2.56rem;
            margin-bottom: 2rem;
            letter-spacing: .1rem;
        }
    }
    h2 {
        text-align: center;
        font-size: 2.8rem;
        line-height: 5.12rem;
        margin-bottom: 2rem;
        &.faq{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100px;
            background-image: url("../static/img/img_shilhouette.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }
    h3 {
        font-size: 1.6rem;
        line-height: 2.56rem;
        text-align: center;
        margin: auto;
        &.table-title{
            font-size: 1.6rem;
        }
    }
    h4 {
        font-size: 1.4rem;
        line-height: 2.24rem;
        margin: auto;
        text-align: center;
        text-align: justify;
        text-justify: inter-ideograph;
    }
    &_section{
        margin: 0 auto 2rem;
        text-align: center;
        h2{
            display: inline;
            border-bottom: solid 2px $af-yellowgreen;
            text-align: center;
            font-size: 1.6rem;
            padding-bottom: .5rem;
        }
    }
    &_title {
        font-size: 3.2rem;
        text-align: center;
        &-sub {
            font-size: 1.8rem;
            font-weight: normal;
            color: #007a0c;
            text-align: center;
            margin-top: 20px;
        }
    }
}



// contents
.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1282px;
    padding: 0px 20px 40px;
    background: #fff;
    margin: auto;
}
.content-box {
    // FEATURE01 ********************
    &--01 {
        .images {
            &--01 {
                width: 100%;
                margin-top: 10px;
                &_photo {
                    width: 100%;
                    height: 200px;
                    background: #333;
                }
            }
            &--02 {
                display: flex;
                justify-content: center;
                &_img{
                    width: 100%;
                    img{
                        width: 100%;
                    }
                }
                &_photo {
                    width: 90%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .feature {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            .caption {
                display: flex;
                flex-direction: column;
                align-items: center;
                &_title {
                    margin-top: 12px;
                    width: 200px;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    &-sub {
                        p {
                            font-size: 1.4rem;
                            font-weight: bold;
                            text-align: center;
                            line-height: 1.5;
                            margin-top: 10px;
                        }
                    }
                }
                &_text {
                    line-height: 1.5;
                    margin-top: 15px;
                }
            }
            .link {
                width: 100%;
                margin-top: 30px;
                &_btn {
                    @include btn-detail-sp();
                }
            }
        }
    }
    // FEATURE02 ********************
    &--02 {
        .feature {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 30px;
            .col-box {
                width: 100%;
                background: #fff;
                border: solid 1px #B1D2E7;
                padding: 0 10px 10px;
                margin-top: 20px;
                &:first-child {
                    margin-top: 0;
                }
                .images {
                    &_photo {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                .caption_text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100px;
                    font-size: 1.6rem;
                    font-weight: bold;
                    line-height: 1.6;
                    text-align: center;
                }
            }
        }
        .link {
            width: 100%;
            margin-top: 30px;
            &_btn {
                @include btn-detail-sp();
            }
        }
    }
    // FEATURE03 ********************
    &--03 {
        background-color: $af-feature-mat;
        .images {
            &--01 {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                &_img {
                    width: 80%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            &--02 {
                display: flex;
                justify-content: center;
                margin-top: 40px;
                &_img {
                    width: 100%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    object {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .caption {
            &--01 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                background: #F7F7E8;
                border: solid 2px #82C580;
                border-radius: 12px;
                padding: 20px 20px;
                margin-top: 30px;
                &_title {
                    font-size: 1.6rem;
                    font-weight: bold;
                }
                &_text {
                    font-size: 1.6rem;
                    line-height: 1.6;
                    margin-top: 15px;
                    text-align: justify;
                }
            }
            &--02 {
                margin-top: 30px;
                &_text {
                    font-size: 1.6rem;
                    line-height: 1.8;
                    font-weight: bold;
                    text-align: center;
                    .marker {
                        background:linear-gradient(transparent 50%, #EAFF80 0);
                    }
                }
            }
        }
        .balloon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            background: #fff;
            border: solid 2px #0B4271;
            border-radius: 8px;
            padding: 10px;
            margin-top: 30px;
            &_text {
                font-size: 1.4rem;
                .strong {
                    font-size: 1.8rem;
                    font-weight: bold;
                    color: #E6001C;
                    .mini {
                        font-size: 1.4rem;
                    }
                }
            }
            position: relative;
            @include triangle($width: 20px, $height: 20px, $top: -20px, $left: 50%, $translate: translate(-50%));
        }
        .link {
            width: 100%;
            margin-top: 30px;
            &_btn {
                @include btn-detail-sp();
            }
        }
    }

}

.faq{
    width: 100%;
    p {
        margin: 0;
    }
    dt{
        color: $af-navy;
        font-weight: bold;
        font-size: 2rem;
        padding: 3.2rem;
        position: relative;
        line-height: 3.2rem;
        p{
            padding-left: 1rem;
        }
    }
    dd{
        position: relative;
        background-color: $af-lite-green;
        border-radius: 8px;
        font-size: 2rem;
        line-height: 3.2rem;
        padding: 3.2rem;
        p{
            padding-left: 1rem;
            ul{
                &.example{
                    background-color: rgba(255, 255, 255, 0.5);
                    margin: 1rem auto;
                    padding: 1.6rem 4rem 1rem;
                    font-size: 1.6rem;
                    border-radius: 8px;
                }
                li{
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
.headletter {
    img {
        width: 27px;
        height: 36px;
        object-fit: contain;
    }

}
.heading_image {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    .index_image{
        width: 455px;
        img{
            width: 100%;
            height: auto;
        }
    }
}


}
</style>
