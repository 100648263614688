<template>
    <section>
        <section class="content-box">
            <div class="heading">
                <div class="heading_inner">
                    <div class="heading_title-sub" v-if="$mq === 'pc'">
                        <object data="static/img/img_pagetitle-sfarm.svg"></object>
                    </div>
                    <div class="heading_title">
                        <p>
                            スマート農業とドローン
                        </p>
                    </div>
                </div>
            </div>
            <div class="smart-farm">
                <div class="images_img" v-if="$mq === 'sp' || $mq === 'spl' || $mq === 'tb'">
                    <object data="static/img/img_sfarm-simbol-sp.svg"></object>
                </div>
                <div class="images_img" v-if="$mq === 'pc'">
                    <object data="static/img/img_sfarm-simbol.svg"></object>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
export default {
    // mounted() {
    //     console.log('Component mounted.')
    // }
};
</script>

<!-- ==================================================
CSS Style
=================================================== -->
<style scoped lang="scss">
// ****************************************
// SP Layout
// ****************************************
@media screen and (max-width: 599px) {
.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 356px;
    background: url('../static/img/bgimg_kv-sfarm.png') no-repeat center bottom / cover;
    position: relative;
    padding: 0 20px;
    margin-top: 40px;
}
.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 70px;
    background: rgba(0, 0, 0, 0.2);
    &_title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 256px;
        height: 30px;
        background: #57970c;
        border: solid 1px #aacc63;
        font-size: 1.6rem;
        font-weight: bold;
        color: #fff;
        letter-spacing: 3px;
    }
}
.smart-farm {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .images {
        &_img {
            width: 100%;
            height: auto;
            object {
                width: 100%;
                height: auto;
            }
        }
    }
}
}

// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 1024px) {
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 520px;
        padding: 0;
        margin-top: 60px;
        background: url('../static/img/bgimg_kv-sfarm.png') no-repeat center center / cover;
        position: relative;
    }
    .heading {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100px;
        background: rgba(0, 0, 0, 0.2);
        &_inner {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            position: relative;
        }
        &_title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 400px;
            height: 40px;
            background: #57970c;
            border: solid 1px #aacc63;
            font-size: 2.1rem;
            font-weight: bold;
            color: #fff;
            letter-spacing: 3px;
        }
    }
    .smart-farm {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .images {
            &_img {
                width: 560px;
                height: auto;
                margin-top: 5px;
                margin-left: 10px;
                object {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1025px) {
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 608px;
        padding: 0;
        background: url('../static/img/bgimg_kv-sfarm.png') no-repeat center center / cover;
        position: relative;
    }
    .heading {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 120px;
        @include stripe($backColor: none, $frontColor: #1d3e02);
        &_inner {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 1280px;
            max-width: 1360px;
            width: 100%;
            height: 100%;
            position: relative;
        }
        &_title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 450px;
            height: 50px;
            background: #57970c;
            border: solid 1px #aacc63;
            font-size: 2.8rem;
            font-weight: bold;
            color: #fff;
            letter-spacing: 3px;
            &-sub {
                position: absolute;
                left: 40px;
                top: 50%; // for IE
                transform: translateY(-50%); // for IE
            }
        }
    }
    .smart-farm {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .images {
            &_img {
                width: 710px;
                height: auto;
                object {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
</style>
