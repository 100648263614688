<template>
    <section class="contents">
        <ul>
            <li>
                <figure>
                    <img src="static/img/img_lineup-afr.png" alt="高性能・高耐久性、 次世代の農薬散布用ドローン「アグリフライヤー」">
                    <figcaption><span>知能を備えたニューエディション"Type-R"</span>「アグリフライヤー type-R」</figcaption>
                </figure>
                <div>
                    <a href="https://agri-flyer-r.ier-prod.jp/" target="_blank">アグリフライヤー type-R サイトへ</a>
                </div>
            </li>
            <li>
                <figure>
                    <img src="static/img/img_lineup-bf.png" alt="高耐久／測量・空撮用ドローン「ビルドフライヤー」">
                    <figcaption><span>高耐久／測量・空撮用ドローン</span>「ビルドフライヤー」</figcaption>
                </figure>
                <div>
                    <a href="https://build-flyer.ier-prod.jp/" target="_blank">ビルドフライヤーサイトへ</a>
                </div>
            </li>
        </ul>
    </section>
</template>

<script>
export default {

    data() {
        return {};
    },
    methods: {},
};
</script>

<style scoped lang="scss">
// ****************************************
// SP Layout
// ****************************************
    .contents {
        ul {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 20px;
            margin: 40px auto;
            li {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 40px;
                figure {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    figcaption {
                        span {
                            font-size: 1.6rem;
                            display: block;
                            margin-bottom: 10px;
                        }
                        font-size: 1.8rem;
                        font-weight: bold;
                        text-align: center;
                    }
                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                }
                div {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-top: 40px;
                    a {
                        display: block;
                        width: 100%;
                        background: $keyVisualHeadingTitleBg;
                        box-shadow: 4px 4px 4px -2px #777777;
                        border-radius: 8px;
                        text-align: center;
                        font-size: 1.8rem;
                        font-weight: bold;
                        padding: 20px;
                        margin: auto;
                        &:link,
                        &:visited {
                            color: $white;
                        }
                        &:hover {
                            color: $white;
                            background: lighten($keyVisualHeadingTitleBg, 15%);
                        }
                    }

                }
            }
        }
    }

// ****************************************
// SPL Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 768px) {
    .contents {
        ul {
            width:100%;
            display: flex;
            justify-content: space-between;
            margin: 40px auto;
            li {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                figure {
                    width: 411px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    figcaption {
                        span {
                            font-size: 1.6rem;
                            display: block;
                            margin-bottom: 10px;
                        }
                        font-size: 1.8rem;
                        font-weight: bold;
                        text-align: center;
                    }
                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                }
                div {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-top: 40px;
                    a {
                        display: block;
                        width: 100%;
                        background: $keyVisualHeadingTitleBg;
                        box-shadow: 4px 4px 4px -2px #777777;
                        border-radius: 8px;
                        text-align: center;
                        font-size: 1.8rem;
                        font-weight: bold;
                        padding: 20px;
                        margin: auto;
                        &:link,
                        &:visited {
                            color: $white;
                        }
                        &:hover {
                            color: $white;
                            background: lighten($keyVisualHeadingTitleBg, 15%);
                        }
                    }

                }
            }
        }
    }
}

// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .contents {
        padding-bottom: 420px;
        ul {
            width: 844px;
            display: flex;
            justify-content: space-between;
            margin: 40px auto;
            li {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                figure {
                    width: 411px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    figcaption {
                        span {
                            font-size: 1.6rem;
                            display: block;
                            margin-bottom: 10px;
                        }
                        font-size: 1.8rem;
                        font-weight: bold;
                        text-align: center;
                    }
                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                }
                div {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-top: 40px;
                    a {
                        display: block;
                        width: 100%;
                        background: $keyVisualHeadingTitleBg;
                        box-shadow: 4px 4px 4px -2px #777777;
                        border-radius: 8px;
                        text-align: center;
                        font-size: 1.8rem;
                        font-weight: bold;
                        padding: 20px;
                        margin: auto;
                        &:link,
                        &:visited {
                            color: $white;
                        }
                        &:hover {
                            color: $white;
                            background: lighten($keyVisualHeadingTitleBg, 15%);
                        }
                    }

                }
            }
        }
    }

}

// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1025px) {
    .contents {
        padding-bottom: 200px;
        ul {
            width: 844px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 40px auto;
            padding: 0;
            li {
                width: 411px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                figure {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    figcaption {
                        span {
                            font-size: 1.6rem;
                            display: block;
                            margin-bottom: 10px;
                        }
                        font-size: 1.8rem;
                        font-weight: bold;
                        text-align: center;
                    }
                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                }
                div {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-top: 40px;
                    a {
                        display: block;
                        width: 100%;
                        background: $keyVisualHeadingTitleBg;
                        box-shadow: 4px 4px 4px -2px #777777;
                        border-radius: 8px;
                        text-align: center;
                        font-size: 1.8rem;
                        font-weight: bold;
                        padding: 20px;
                        margin: auto;
                        &:link,
                        &:visited {
                            color: $white;
                        }
                        &:hover {
                            color: $white;
                            background: lighten($keyVisualHeadingTitleBg, 15%);
                        }
                    }

                }
            }
        }
    }
}
</style>
