var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("section", { staticClass: "content-box content-box--01" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("table", { staticClass: "table-spec" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("tr", [
          _vm.$mq === "sp" || _vm.$mq === "spl"
            ? _c("td", [
                _vm._v("\n                    1500mm(W)"),
                _c("br"),
                _vm._v("1650mm(D)"),
                _c("br"),
                _vm._v("500mm(H)\n                ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$mq === "tb" || _vm.$mq === "pc"
            ? _c("td", [_vm._v("1500mm × 1650mm × 500mm")])
            : _vm._e(),
          _vm._v(" "),
          _c("th", [
            _vm._v("機体寸法"),
            _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
            _vm._v("（アーム展開時）")
          ]),
          _vm._v(" "),
          _vm.$mq === "sp" || _vm.$mq === "spl"
            ? _c("td", [
                _vm._v("\n                    1471mm(W)"),
                _c("br"),
                _vm._v("1471mm(D)"),
                _c("br"),
                _vm._v("482mm(H)\n                ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$mq === "tb" || _vm.$mq === "pc"
            ? _c("td", [_vm._v("1471mm×1471mm×482mm")])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("tr", [
          _vm.$mq === "sp" || _vm.$mq === "spl"
            ? _c("td", [
                _vm._v("\n                    720mm(W)"),
                _c("br"),
                _vm._v("870mm(D)"),
                _c("br"),
                _vm._v("500mm(H)\n                ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$mq === "tb" || _vm.$mq === "pc"
            ? _c("td", [_vm._v("720mm×870mm×500mm")])
            : _vm._e(),
          _vm._v(" "),
          _c("th", [
            _vm._v("機体寸法"),
            _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
            _vm._v("（アーム折畳み時）")
          ]),
          _vm._v(" "),
          _vm.$mq === "sp" || _vm.$mq === "spl"
            ? _c("td", [
                _vm._v("780mm(W)"),
                _c("br"),
                _vm._v("780mm(D)"),
                _c("br"),
                _vm._v("482mm")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$mq === "tb" || _vm.$mq === "pc"
            ? _c("td", [_vm._v("780mm×780mm×482mm")])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [
            _vm._v("27*8.8 inch×4個"),
            _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
            _vm._v("（折畳み式）")
          ]),
          _vm._v(" "),
          _c("th", [_vm._v("ローター寸法、個数")]),
          _vm._v(" "),
          _c("td", [
            _vm._v("21*7 inch×8個"),
            _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
            _vm._v("（折畳み式）")
          ])
        ]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _vm._m(5),
        _vm._v(" "),
        _vm._m(6),
        _vm._v(" "),
        _vm._m(7),
        _vm._v(" "),
        _vm._m(8),
        _vm._v(" "),
        _vm._m(9),
        _vm._v(" "),
        _vm._m(10),
        _vm._v(" "),
        _vm._m(11)
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "content-box content-box--02" }, [
      _vm._m(12),
      _vm._v(" "),
      _vm.$mq === "spl" || _vm.$mq === "sp"
        ? _c("div", { staticClass: "col-box" }, [
            _vm._m(13),
            _vm._v(" "),
            _vm._m(14),
            _vm._v(" "),
            _vm._m(15)
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$mq === "tb" || _vm.$mq === "pc"
        ? _c("div", { staticClass: "row-box" }, [
            _vm._m(16),
            _vm._v(" "),
            _vm._m(17)
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "content-box content-box--03" }, [
      _c("div", { staticClass: "row-box--spec" }, [
        _c("div", [
          _vm._m(18),
          _vm._v(" "),
          _c("table", { staticClass: "table-spec--min" }, [
            _c("tr", [
              _c("th", [
                _vm._v("\n                            総重量"),
                _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
                _vm._v("（バッテリ含む）\n                        ")
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("16.9kg（バッテリー含む）")])
            ]),
            _vm._v(" "),
            _vm._m(19),
            _vm._v(" "),
            _c("tr", [
              _c("th", [
                _vm._v("ホバリング"),
                _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
                _vm._v("消費電力")
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("LiPo-6c 16,000mAh × 2")])
            ]),
            _vm._v(" "),
            _vm._m(20),
            _vm._v(" "),
            _c("tr", [
              _c("th", [
                _vm._v("運用限界高度"),
                _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
                _vm._v("（海抜）")
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("2,000m")])
            ]),
            _vm._v(" "),
            _vm._m(21)
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._m(22),
          _vm._v(" "),
          _c("table", { staticClass: "table-spec--min" }, [
            _vm._m(23),
            _vm._v(" "),
            _vm._m(24),
            _vm._v(" "),
            _c("tr", [
              _c("th", [
                _vm._v("ESC"),
                _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
                _vm._v("最大許容電流")
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("120A")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [
                _vm._v("ESC動作"),
                _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
                _vm._v("電圧")
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("50.4V")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [
                _vm._v("ESC駆動"),
                _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
                _vm._v("PWM周波数")
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("600Hz")])
            ]),
            _vm._v(" "),
            _vm._m(25),
            _vm._v(" "),
            _c("tr", [
              _c("th", [
                _vm._v("送信機"),
                _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
                _vm._v("動作周波数")
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("2.4GHz")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [
                _vm._v("送信機"),
                _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
                _vm._v("最大伝送距離")
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("1km（障害物・干渉がない場合）")])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "content-box content-box--03" }, [
      _vm._m(26),
      _vm._v(" "),
      _c("div", { staticClass: "column-list" }, [
        _c("ul", { staticClass: "list-robust" }, [
          _c("li", [
            _vm._v("連続耐久性"),
            _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
            _vm._v("500h以上")
          ]),
          _vm._v(" "),
          _c("li", [_vm._v("発停耐久")]),
          _vm._v(" "),
          _c("li", [_vm._v("着陸耐久")]),
          _vm._v(" "),
          _c("li", [
            _vm._v("コネクタ"),
            _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
            _vm._v("抜差し耐久")
          ])
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "list-robust" }, [
          _c("li", [
            _vm._v("モータ単体"),
            _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
            _vm._v("1,000h以上")
          ]),
          _vm._v(" "),
          _c("li", [_vm._v("開閉耐久")]),
          _vm._v(" "),
          _c("li", [_vm._v("ポンプ耐久")]),
          _vm._v(" "),
          _c("li", [_vm._v("IP試験")])
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "list-performance" }, [
          _c("li", [_vm._v("ブレーキ性能")]),
          _vm._v(" "),
          _c("li", [
            _vm._v("耐風性能"),
            _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
            _vm._v("8m/s")
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v("直進安定性"),
            _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
            _vm._v("上下左右0.5m以内")
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v("モーター"),
            _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
            _vm._v("ヨー角")
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v("ホバリング"),
            _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
            _vm._v("安定性")
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "content-box content-box--04" }, [
      _vm._m(27),
      _vm._v(" "),
      _vm.$mq === "sp" || _vm.$mq === "spl"
        ? _c("div", { staticClass: "images--02" }, [
            _vm._m(28),
            _vm._v(" "),
            _vm._m(29)
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$mq === "tb" || _vm.$mq === "pc"
        ? _c("div", { staticClass: "images--02" }, [
            _vm._m(30),
            _vm._v(" "),
            _vm._m(31)
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h2", { staticClass: "info-emphasis aboutus" }, [
        _c("p", [
          _vm._v("\n                ①〜⑥全6回の試験で、農水協規定値をクリア。"),
          _vm.$mq === "pc" ? _c("br") : _vm._e(),
          _vm._v("安定した散布性能を実現しています\n            ")
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h2", [_vm._v("仕様諸元＆他機種との比較")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "af" }, [_vm._v("IER　“Agri Flyer”")]),
      _vm._v(" "),
      _c("th"),
      _vm._v(" "),
      _c("th", { staticClass: "other" }, [_vm._v("他社同価格帯製品")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("100N／ローター")]),
      _vm._v(" "),
      _c("th", [_vm._v("ローター最大推力")]),
      _vm._v(" "),
      _c("td", [_vm._v("50N／ローター")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("6S Li-Po 16000mAh×2")]),
      _vm._v(" "),
      _c("th", [_vm._v("ローター最大推力")]),
      _vm._v(" "),
      _c("td", [_vm._v("メーカー純正：MG-12000S")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("8.5 ℓ")]),
      _vm._v(" "),
      _c("th", [_vm._v("噴霧タンク容量")]),
      _vm._v(" "),
      _c("td", [_vm._v("10 ℓ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("0.8 ℓ/min")]),
      _vm._v(" "),
      _c("th", [_vm._v("標準散布量")]),
      _vm._v(" "),
      _c("td", [_vm._v("---")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("24.9kg")]),
      _vm._v(" "),
      _c("th", [_vm._v("最大離陸重量")]),
      _vm._v(" "),
      _c("td", [_vm._v("24.9kg")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("8m/sec")]),
      _vm._v(" "),
      _c("th", [_vm._v("最大飛行可能風速")]),
      _vm._v(" "),
      _c("td", [_vm._v("8m/sec")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("～22 min")]),
      _vm._v(" "),
      _c("th", [_vm._v("最大飛行時間")]),
      _vm._v(" "),
      _c("td", [_vm._v("～22 min")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("1000h以上の寿命")]),
      _vm._v(" "),
      _c("th", [_vm._v("モータ・ESC耐久性")]),
      _vm._v(" "),
      _c("td", [_vm._v("100hで交換")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("金属製Mgボディにより紫外線で劣化しない")]),
      _vm._v(" "),
      _c("th", [_vm._v("耐候性")]),
      _vm._v(" "),
      _c("td", [
        _vm._v("カーボン製で樹脂を使用しているため紫外線の影響を受ける")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("200h以上（日本製）")]),
      _vm._v(" "),
      _c("th", [_vm._v("農薬ポンプ耐久性")]),
      _vm._v(" "),
      _c("td", [_vm._v("100hで交換")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h2", [_vm._v("機体寸法図")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--02" }, [
      _c("div", { staticClass: "images--02_img" }, [
        _c("img", {
          attrs: { src: "static/img/img_drawing-top.png", alt: "機体寸法図" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--02" }, [
      _c("div", { staticClass: "images--02_img" }, [
        _c("img", {
          attrs: { src: "static/img/img_drawing-side.png", alt: "機体寸法図" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--02" }, [
      _c("div", { staticClass: "images--02_img" }, [
        _c("img", {
          attrs: {
            src: "static/img/img_drawing-topclose.png",
            alt: "機体寸法図"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--02" }, [
      _c("div", { staticClass: "images--02_img" }, [
        _c("img", {
          attrs: { src: "static/img/img_drawing-top.png", alt: "機体寸法図" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-box" }, [
      _c("div", { staticClass: "images--02_img" }, [
        _c("img", {
          attrs: { src: "static/img/img_drawing-side.png", alt: "機体寸法図" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "images--02_img" }, [
        _c("img", {
          attrs: {
            src: "static/img/img_drawing-topclose.png",
            alt: "機体寸法図"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h2", [_vm._v("飛行パラメータ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("バッテリ")]),
      _vm._v(" "),
      _c("td", [_vm._v("24.9kg")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("最大飛行速度")]),
      _vm._v(" "),
      _c("td", [_vm._v("15km〜20km/h")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("動作環境温度")]),
      _vm._v(" "),
      _c("td", [_vm._v("0〜40℃")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h2", [_vm._v("推進システム")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("モータKV")]),
      _vm._v(" "),
      _c("td", [_vm._v("115rpm/V")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("モータ最大推力")]),
      _vm._v(" "),
      _c("td", [_vm._v("90N/rotor")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("送信機")]),
      _vm._v(" "),
      _c("td", [_vm._v("フタバ 14SG")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h2", [_vm._v("ロバスト性評価＆性能評価項目")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h2", [_vm._v("農薬散布性能（農水協試験時データ）")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--02_img" }, [
      _c("object", {
        attrs: {
          type: "image/svg+xml",
          data: "static/img/chart_spraying-sp.svg"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "caption-spray" }, [
      _c("li", [_vm._v("落下分散試験　※農水協指針 CV値0.3以下")]),
      _vm._v(" "),
      _c("li", [_vm._v("CV値 ＝ 散布面積標準偏差/面積率平均")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--02_img" }, [
      _c("object", {
        attrs: { type: "image/svg+xml", data: "static/img/chart_spraying.svg" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "caption-spray" }, [
      _c("li", [_vm._v("落下分散試験　※農水協指針 CV値0.3以下")]),
      _vm._v(" "),
      _c("li", [_vm._v("CV値 ＝ 散布面積標準偏差/面積率平均")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }