<template>
    <footer class="footer">
        <section
            class="content-box content-box--01"
            v-show="$route.path !== '/inquiry' && $route.path !== '/question'"
        >
            <div class="content-box_inner">
                <div class="inquiry">
                    <router-link class="inquiry_btn" v-show="$mq === 'sp'" to="/inquiry"
                        >お問い合わせはこちらから</router-link
                    >
                    <router-link
                        class="inquiry_btn"
                        v-show="$mq === 'spl' || $mq === 'tb' || $mq === 'pc'"
                        to="/inquiry"
                        >Agri Flyerに関するお問い合わせはこちらから</router-link
                    >
                </div>
            </div>
        </section>
        <section class="content-box content-box--02">
            <div class="content-box_inner">
                <div class="logo">
                    <div class="logo_title">
                        <img src="static/img/AF_logo_w.png" alt="Agri-Flyer ：ロゴ" />
                    </div>
                    <div class="logo_text">
                        <p>IER農業用マルチコプター「アグリフライヤー」</p>
                    </div>
                </div>
                <nav class="footer-navi">
                    <ul>
                        <li v-for="menu in menus" :key="menu.item">
                            <router-link :to="menu.path">{{ menu.name }}</router-link>
                        </li>
                    </ul>
                </nav>
                <div class="com-info">
                    <p class="com-info_text com-info_text--01">
                        開発・製造・販売元：<br v-if="$mq === 'sp'" />
                        <a href="http://ier.co.jp/" target="_blank">（株）石川エナジーリサーチ</a>
                    </p>
                    <p class="com-info_text com-info_text--02">
                        販売代理店：<br v-if="$mq === 'sp'" />
                        <a href="https://www.itsim.com/company/" target="_blank"
                            >（株）アイティーシム</a
                        >
                    </p>
                </div>
                <div class="copyright">
                    <p class="copyright_text">
                        ©2019 Itsim Co., Ltd. All Rights Reserved.
                    </p>
                </div>
            </div>
        </section>
    </footer>
</template>

<script>
export default {
    props: ['menus'],
};
</script>

<!-- ==================================================
CSS Style
=================================================== -->
<style scoped lang="scss">
// ****************************************
// SP Layout
// ****************************************
.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #fff;
    padding: 0 20px;
    &_inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    &--01 {
        width: 100%;
        height: 50px;
        background: #082c4a;
        justify-content: center;
        .inquiry {
            width: 100%;
            &_btn {
                @include btn-inquiry-sp();
            }
        }
    }
    &--02 {
        width: 100%;
        background: #0b4271;
        .logo {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
            &_title {
                width: 170px;
                height: auto;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            &_text {
                margin-top: 20px;
                font-size: 1.4rem;
                color: #fff;
                margin-top: 10px;
            }
        }
        .footer-navi {
            width: 100%;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 120px;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                list-style: none;
                li {
                    a {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        font-size: 1.5rem;
                        color: #fff;
                        padding-left: 10px;
                    }
                    border-left: solid 4px #fff;
                    margin-bottom: 20px;
                }
            }
        }
        .com-info {
            display: flex;
            justify-content: center;
            font-size: 1.2rem;
            line-height: 1.6;
            color: #fff;
            margin-top: 10px;
            &_text {
                text-indent: 0.6em;
                a {
                    &:link,
                    &:visited {
                        color: $lite-gray;
                        text-decoration: underline;
                    }
                    &:hover {
                        color: $skyblue;
                    }
                }
                &--01 {
                    margin-right: 40px;
                }
            }
        }
        .copyright {
            margin: 40px 0 20px;
            &_text {
                font-size: 1rem;
                color: #fff;
            }
        }
    }
}

// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 1023px) {
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        &_inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
        &--01 {
            width: 100%;
            height: 90px;
            background: #082c4a;
            .inquiry {
                width: auto;
                &_btn {
                    @include btn-inquiry($width: 500px, $height: 50px, $fSize: 1.8rem);
                }
            }
        }
        &--02 {
            width: 100vw;
            background: #0b4271;
            .logo {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 40px;
                &_title {
                    width: 220px;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                &_text {
                    margin-top: 20px;
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: #fff;
                }
            }
            .footer-navi {
                width: 100%;
                margin-top: 40px;
                ul {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    list-style: none;
                    li {
                        a {
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            font-size: 1.4rem;
                            color: #fff;
                            padding: 0 15px;
                        }
                        border-left: solid 1px rgba(255, 255, 255, 0.3);
                        &:last-child {
                            border-right: solid 1px rgba(255, 255, 255, 0.3);
                        }
                    }
                }
            }
            .com-info {
                display: flex;
                justify-content: center;
                margin-top: 20px;
                font-size: 1.2rem;
                color: #fff;
                &_text {
                    &--01 {
                        margin-right: 40px;
                    }
                }
            }
            .copyright {
                margin: 30px 0 30px;
                &_text {
                    font-size: 1.1rem;
                    color: #fff;
                }
            }
        }
    }
}

// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1024px) {
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        &_inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 1280px;
            max-width: 1360px;
            width: 100%;
        }
        &--01 {
            width: 100%;
            height: 100px;
            background: #082c4a;
            .inquiry {
                width: auto;
                &_btn {
                    @include btn-inquiry();
                }
            }
        }
        &--02 {
            width: 100vw;
            background: #0b4271;
            .logo {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 50px;
                &_title {
                    width: 280px;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                &_text {
                    margin-top: 20px;
                    font-size: 1.6rem;
                    font-weight: bold;
                    color: #fff;
                    p {
                        margin: 0; // for IE
                    }
                }
            }
            .footer-navi {
                width: 100%;
                margin-top: 50px;
                ul {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    list-style: none;
                    margin: 0; // for IE
                    li {
                        a {
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            font-size: 1.5rem;
                            color: #fff;
                            padding: 0 20px;
                        }
                        border-left: solid 1px rgba(255, 255, 255, 0.3);
                        &:last-child {
                            border-right: solid 1px rgba(255, 255, 255, 0.3);
                        }
                    }
                }
            }
            .com-info {
                display: flex;
                justify-content: center;
                margin-top: 50px;
                font-size: 1.6rem;
                color: #fff;
                &_text {
                    &--01 {
                        margin-right: 40px;
                        margin-top: 0; // for IE
                        margin-bottom: 0; // for IE
                    }
                    &--02 {
                        margin-top: 0; // for IE
                        margin-bottom: 0; // for IE
                    }
                }
            }
            .copyright {
                margin: 50px 0 30px;
                &_text {
                    font-size: 1.4rem;
                    color: #fff;
                    margin-top: 0; // for IE
                    margin-bottom: 0; // for IE
                }
            }
        }
    }
}
</style>
