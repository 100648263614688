<template>
    <section>
        <section class="content-box content-box--01">
            <div class="heading">
                <h2>仕様諸元＆他機種との比較</h2>
            </div>
            <table class="table-spec">
                <tr>
                    <th class="af">IER　“Agri Flyer”</th>
                    <th></th>
                    <th class="other">他社同価格帯製品</th>
                </tr>
                <tr>
                    <td v-if="$mq === 'sp' || $mq === 'spl'">
                        1500mm(W)<br />1650mm(D)<br />500mm(H)
                    </td>
                    <td v-if="$mq === 'tb' || $mq === 'pc'">1500mm × 1650mm × 500mm</td>
                    <th>機体寸法<br v-if="$mq === 'sp' || $mq === 'spl'" />（アーム展開時）</th>
                    <td v-if="$mq === 'sp' || $mq === 'spl'">
                        1471mm(W)<br />1471mm(D)<br />482mm(H)
                    </td>
                    <td v-if="$mq === 'tb' || $mq === 'pc'">1471mm×1471mm×482mm</td>
                </tr>
                <tr>
                    <td v-if="$mq === 'sp' || $mq === 'spl'">
                        720mm(W)<br />870mm(D)<br />500mm(H)
                    </td>
                    <td v-if="$mq === 'tb' || $mq === 'pc'">720mm×870mm×500mm</td>
                    <th>機体寸法<br v-if="$mq === 'sp' || $mq === 'spl'" />（アーム折畳み時）</th>
                    <td v-if="$mq === 'sp' || $mq === 'spl'">780mm(W)<br />780mm(D)<br />482mm</td>
                    <td v-if="$mq === 'tb' || $mq === 'pc'">780mm×780mm×482mm</td>
                </tr>
                <tr>
                    <td>27*8.8 inch×4個<br v-if="$mq === 'sp' || $mq === 'spl'" />（折畳み式）</td>
                    <th>ローター寸法、個数</th>
                    <td>21*7 inch×8個<br v-if="$mq === 'sp' || $mq === 'spl'" />（折畳み式）</td>
                </tr>
                <tr>
                    <td>100N／ローター</td>
                    <th>ローター最大推力</th>
                    <td>50N／ローター</td>
                </tr>
                <tr>
                    <td>6S Li-Po 16000mAh×2</td>
                    <th>ローター最大推力</th>
                    <td>メーカー純正：MG-12000S</td>
                </tr>
                <tr>
                    <td>8.5 ℓ</td>
                    <th>噴霧タンク容量</th>
                    <td>10 ℓ</td>
                </tr>
                <tr>
                    <td>0.8 ℓ/min</td>
                    <th>標準散布量</th>
                    <td>---</td>
                </tr>
                <tr>
                    <td>24.9kg</td>
                    <th>最大離陸重量</th>
                    <td>24.9kg</td>
                </tr>
                <tr>
                    <td>8m/sec</td>
                    <th>最大飛行可能風速</th>
                    <td>8m/sec</td>
                </tr>
                <tr>
                    <td>～22 min</td>
                    <th>最大飛行時間</th>
                    <td>～22 min</td>
                </tr>
                <tr>
                    <td>1000h以上の寿命</td>
                    <th>モータ・ESC耐久性</th>
                    <td>100hで交換</td>
                </tr>
                <tr>
                    <td>金属製Mgボディにより紫外線で劣化しない</td>
                    <th>耐候性</th>
                    <td>カーボン製で樹脂を使用しているため紫外線の影響を受ける</td>
                </tr>
                <tr>
                    <td>200h以上（日本製）</td>
                    <th>農薬ポンプ耐久性</th>
                    <td>100hで交換</td>
                </tr>
            </table>
        </section>
        <section class="content-box content-box--02">
            <div class="heading">
                <h2>機体寸法図</h2>
            </div>
            <div class="col-box" v-if="$mq === 'spl' || $mq === 'sp'">
                <div class="images--02">
                    <div class="images--02_img">
                        <img src="static/img/img_drawing-top.png" alt="機体寸法図" />
                    </div>
                </div>
                <div class="images--02">
                    <div class="images--02_img">
                        <img src="static/img/img_drawing-side.png" alt="機体寸法図" />
                    </div>
                </div>
                <div class="images--02">
                    <div class="images--02_img">
                        <img src="static/img/img_drawing-topclose.png" alt="機体寸法図" />
                    </div>
                </div>
            </div>
            <div class="row-box" v-if="$mq === 'tb' || $mq === 'pc'">
                <div class="images--02">
                    <div class="images--02_img">
                        <img src="static/img/img_drawing-top.png" alt="機体寸法図" />
                    </div>
                </div>
                <div class="col-box">
                    <div class="images--02_img">
                        <img src="static/img/img_drawing-side.png" alt="機体寸法図" />
                    </div>
                    <div class="images--02_img">
                        <img src="static/img/img_drawing-topclose.png" alt="機体寸法図" />
                    </div>
                </div>
            </div>
        </section>
        <section class="content-box content-box--03">
            <div class="row-box--spec">
                <div>
                    <div class="heading">
                        <h2>飛行パラメータ</h2>
                    </div>
                    <table class="table-spec--min">
                        <tr>
                            <th>
                                総重量<br v-if="$mq === 'sp' || $mq === 'spl'" />（バッテリ含む）
                            </th>
                            <td>16.9kg（バッテリー含む）</td>
                        </tr>
                        <tr>
                            <th>バッテリ</th>
                            <td>24.9kg</td>
                        </tr>
                        <tr>
                            <th>ホバリング<br v-if="$mq === 'sp' || $mq === 'spl'" />消費電力</th>
                            <td>LiPo-6c 16,000mAh × 2</td>
                        </tr>
                        <tr>
                            <th>最大飛行速度</th>
                            <td>15km〜20km/h</td>
                        </tr>
                        <tr>
                            <th>運用限界高度<br v-if="$mq === 'sp' || $mq === 'spl'" />（海抜）</th>
                            <td>2,000m</td>
                        </tr>
                        <tr>
                            <th>動作環境温度</th>
                            <td>0〜40℃</td>
                        </tr>
                    </table>
                </div>
                <div>
                    <div class="heading">
                        <h2>推進システム</h2>
                    </div>
                    <table class="table-spec--min">
                        <tr>
                            <th>モータKV</th>
                            <td>115rpm/V</td>
                        </tr>
                        <tr>
                            <th>モータ最大推力</th>
                            <td>90N/rotor</td>
                        </tr>
                        <tr>
                            <th>ESC<br v-if="$mq === 'sp' || $mq === 'spl'" />最大許容電流</th>
                            <td>120A</td>
                        </tr>
                        <tr>
                            <th>ESC動作<br v-if="$mq === 'sp' || $mq === 'spl'" />電圧</th>
                            <td>50.4V</td>
                        </tr>
                        <tr>
                            <th>ESC駆動<br v-if="$mq === 'sp' || $mq === 'spl'" />PWM周波数</th>
                            <td>600Hz</td>
                        </tr>
                        <tr>
                            <th>送信機</th>
                            <td>フタバ 14SG</td>
                        </tr>
                        <tr>
                            <th>送信機<br v-if="$mq === 'sp' || $mq === 'spl'" />動作周波数</th>
                            <td>2.4GHz</td>
                        </tr>
                        <tr>
                            <th>送信機<br v-if="$mq === 'sp' || $mq === 'spl'" />最大伝送距離</th>
                            <td>1km（障害物・干渉がない場合）</td>
                        </tr>
                    </table>
                </div>
            </div>
        </section>
        <section class="content-box content-box--03">
            <div class="heading">
                <h2>ロバスト性評価＆性能評価項目</h2>
            </div>
            <div class="column-list">
                <ul class="list-robust">
                    <li>連続耐久性<br v-if="$mq === 'sp' || $mq === 'spl'" />500h以上</li>
                    <li>発停耐久</li>
                    <li>着陸耐久</li>
                    <li>コネクタ<br v-if="$mq === 'sp' || $mq === 'spl'" />抜差し耐久</li>
                </ul>
                <ul class="list-robust">
                    <li>モータ単体<br v-if="$mq === 'sp' || $mq === 'spl'" />1,000h以上</li>
                    <li>開閉耐久</li>
                    <li>ポンプ耐久</li>
                    <li>IP試験</li>
                </ul>
                <ul class="list-performance">
                    <li>ブレーキ性能</li>
                    <li>耐風性能<br v-if="$mq === 'sp' || $mq === 'spl'" />8m/s</li>
                    <li>直進安定性<br v-if="$mq === 'sp' || $mq === 'spl'" />上下左右0.5m以内</li>
                    <li>モーター<br v-if="$mq === 'sp' || $mq === 'spl'" />ヨー角</li>
                    <li>ホバリング<br v-if="$mq === 'sp' || $mq === 'spl'" />安定性</li>
                </ul>
            </div>
        </section>
        <section class="content-box content-box--04">
            <div class="heading">
                <h2>農薬散布性能（農水協試験時データ）</h2>
            </div>
            <div class="images--02" v-if="$mq === 'sp' || $mq === 'spl'">
                <div class="images--02_img">
                    <object type="image/svg+xml" data="static/img/chart_spraying-sp.svg"></object>
                </div>
                <ul class="caption-spray">
                    <li>落下分散試験　※農水協指針 CV値0.3以下</li>
                    <li>CV値 ＝ 散布面積標準偏差/面積率平均</li>
                </ul>
            </div>
            <div class="images--02" v-if="$mq === 'tb' || $mq === 'pc'">
                <div class="images--02_img">
                    <object type="image/svg+xml" data="static/img/chart_spraying.svg"></object>
                </div>
                <ul class="caption-spray">
                    <li>落下分散試験　※農水協指針 CV値0.3以下</li>
                    <li>CV値 ＝ 散布面積標準偏差/面積率平均</li>
                </ul>
            </div>
            <h2 class="info-emphasis aboutus">
                <p>
                    ①〜⑥全6回の試験で、農水協規定値をクリア。<br
                        v-if="$mq === 'pc'"
                    />安定した散布性能を実現しています
                </p>
            </h2>
        </section>
    </section>
</template>

<script>
export default {};
</script>

<!-- ==================================================
CSS Style
=================================================== -->
<style scoped lang="scss">
// ****************************************
// SP Layout
// ****************************************
// common
.row-box--between {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > div {
        display: flex;
        align-items: center;
    }
}
.row-box--stretch {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > div {
        display: flex;
        align-items: center;
    }
}
.row-box--spec {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > div {
        align-items: center;
    }
}

.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
    &.emphasis {
        h2 {
            text-align: center;
            font-size: 1.6rem;
            line-height: 2.56rem;
            margin-bottom: 2rem;
            letter-spacing: 0.1rem;
        }
    }
    h2 {
        text-align: center;
        font-size: 1.6rem;
        line-height: 2.56rem;
        margin-bottom: 2rem;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: -10px;
            left: 0;
            width: 0px;
            height: 0px;
            margin: auto;
            border-style: solid;
            border-color: $af-blue transparent transparent transparent;
            border-width: 10px 10px 0 10px;
        }
        &.quality {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 120px;
            background-image: url('../static/img/bgimg_quality-sp.png');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }
    h3 {
        font-size: 1.6rem;
        line-height: 2.56rem;
        text-align: center;
        margin: auto;
        &.table-title {
            font-size: 1.6rem;
        }
    }
    h4 {
        font-size: 1.4rem;
        line-height: 2.24rem;
        margin: auto;
        text-align: center;
        text-align: justify;
        text-justify: inter-ideograph;
    }
    &_section {
        margin: 0 auto 2rem;
        text-align: center;
        h2 {
            display: inline;
            border-bottom: solid 2px $af-yellowgreen;
            text-align: center;
            font-size: 1.6rem;
            padding-bottom: 0.5rem;
        }
    }
    &_title {
        font-size: 3.2rem;
        text-align: center;
        &-sub {
            font-size: 1.8rem;
            font-weight: normal;
            color: #007a0c;
            text-align: center;
            margin-top: 20px;
        }
    }
}
.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    &_title {
        font-size: 3.2rem;
        text-align: center;
        &-sub {
            font-size: 1.8rem;
            font-weight: normal;
            color: #007a0c;
            text-align: center;
            margin-top: 20px;
        }
    }
}
.lead {
    margin-top: 35px;
    &_text {
        text-align: center;
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 1.5;
    }
}
.table-spec {
    background-color: $mid-gray;
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
    th {
        color: $white;
        background-color: $smoke-blue;
        width: 30%;
        line-height: 2rem;
        padding: 0.5rem;
        font-size: 1.2rem;
        font-weight: normal;
        &.af {
            background-color: darken($smoke-blue, 0.1);
            font-weight: bold;
        }
        &.other {
            background-color: darken($mid-gray, 0.1);
        }
    }
    td {
        font-size: 1.2rem;
        word-wrap: break-word;
        line-height: 2rem;
        padding: 1rem 0.5rem;
        &:first-child {
            text-align: right;
        }
    }
    tr:nth-child(odd) {
        td:first-child {
            background-color: $smoke-skyblue;
        }
        td:nth-child(3) {
            background-color: $lite-gray;
        }
    }
    tr:nth-child(even) {
        td:first-child {
            background-color: darken($smoke-skyblue, 5%);
        }
        td:nth-child(3) {
            background-color: darken($lite-gray, 5%);
        }
    }
}
.table-spec--min {
    background-color: $mid-gray;
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
    th {
        color: $white;
        background-color: $smoke-blue;
        width: 30%;
        line-height: 2rem;
        padding: 0.5rem;
        font-size: 1.2rem;
        font-weight: normal;
        &.af {
            background-color: darken($smoke-blue, 0.1);
            font-weight: bold;
        }
        &.other {
            background-color: darken($mid-gray, 0.1);
        }
    }
    td {
        font-size: 1.2rem;
        word-wrap: break-word;
        line-height: 2rem;
        padding: 1rem 0.5rem;
        &:first-child {
            text-align: right;
        }
    }
    tr:nth-child(odd) {
        td:first-child {
            background-color: $smoke-skyblue;
        }
        td:nth-child(2) {
            background-color: $lite-gray;
        }
    }
    tr:nth-child(even) {
        td:first-child {
            background-color: darken($smoke-skyblue, 5%);
        }
        td:nth-child(2) {
            background-color: darken($lite-gray, 5%);
        }
    }
}
// contents
.content-box {
    // 仕様諸元＆他機種との比較 ********************
    &--01 {
        padding: 0 20px;
        .images {
            &--01 {
                display: flex;
                justify-content: center;
                margin-top: 35px;
                &_photo {
                    width: 720px;
                    height: 440px;
                    background: #333;
                }
            }
            &--02 {
                &_photo {
                    width: 80%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .feature {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 50px;
            .caption {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 600px;
                margin-left: 50px;
                &_title {
                    margin-top: 25px;
                    width: 320px;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    &-sub {
                        width: 650px;
                        p {
                            font-size: 2.8rem;
                            font-weight: bold;
                            margin-top: 0;
                        }
                    }
                }
                &_text {
                    font-size: 1.6rem;
                    line-height: 1.6;
                    margin-top: 25px;
                }
                .link {
                    margin-top: 25px;
                    &_btn {
                        @include btn-detail();
                    }
                }
            }
        }
    }
    // 図面 ********************
    &--02 {
        .images {
            &--01 {
                display: flex;
                justify-content: center;
                margin-top: 40px;
                &_img {
                    width: 80%;
                    height: auto;
                    display: block;
                    margin: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            &--02 {
                margin-top: 20px;
                &_img {
                    display: block;
                    width: 80%;
                    height: auto;
                    margin: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .feature {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 40px;
            .col-box {
                justify-content: space-between;
                width: 400px;
                height: 412px;
                background: #fff;
                border: solid 1px #b1d2e7;
                padding: 0 10px 10px;
                margin-left: 40px;
                &:first-child {
                    margin-left: 0;
                }
                .caption {
                    &_text {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 132px;
                        font-size: 2rem;
                        font-weight: bold;
                        line-height: 1.6;
                        text-align: center;
                    }
                }
            }
        }
        .link {
            display: flex;
            justify-content: center;
            margin-top: 50px;
            &_btn {
                @include btn-detail();
            }
        }
    }
    // ロバスト性評価 ********************
    &--03 {
        padding: 0 20px;
        .images {
            &--01 {
                display: flex;
                justify-content: center;
                margin-top: 40px;
                &_img {
                    width: 100%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            &--02 {
                margin-top: 80px;
                &_img {
                    width: 100%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    object {
                        width: 100%;
                        display: block;
                        margin: auto;
                    }
                }
            }
        }
        .caption {
            &--01 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 844px;
                height: 298px;
                background: #f7f7e8;
                border: solid 2px #82c580;
                border-radius: 12px;
                padding: 0 30px;
                margin-top: 50px;
                &_title {
                    font-size: 2.4rem;
                    font-weight: bold;
                }
                &_text {
                    font-size: 2rem;
                    line-height: 2;
                    margin-top: 15px;
                    text-align: justify;
                }
            }
            &--02 {
                margin-top: 70px;
                &_text {
                    font-size: 2.8rem;
                    line-height: 1.8;
                    font-weight: bold;
                    text-align: center;
                    .marker {
                        background: linear-gradient(transparent 50%, #eaff80 0);
                    }
                }
            }
        }
        .balloon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 644px;
            height: 74px;
            background: #fff;
            border: solid 3px #0b4271;
            border-radius: 8px;
            margin-top: 50px;
            &_text {
                font-size: 2.6rem;
                .strong {
                    font-size: 3.4rem;
                    font-weight: bold;
                    color: #e6001c;
                    .mini {
                        font-size: 2.6rem;
                    }
                }
            }
            position: relative;
            @include triangle();
        }
        .link {
            display: flex;
            justify-content: center;
            margin-top: 50px;
            &_btn {
                @include btn-detail();
            }
        }
    }
    // 農薬散布性能 ********************
    &--04 {
        padding: 0 20px;
        .images {
            &--01 {
                display: flex;
                justify-content: center;
                margin-top: 40px;
                &_img {
                    width: 100%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    object {
                        width: 100%;
                    }
                }
            }
            &--02 {
                &_img {
                    width: 100%;
                    height: auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    object {
                        width: 100%;
                        display: block;
                        margin: auto;
                    }
                }
            }
        }
        .caption {
            &--01 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 844px;
                height: 298px;
                background: #f7f7e8;
                border: solid 2px #82c580;
                border-radius: 12px;
                padding: 0 30px;
                margin-top: 50px;
                &_title {
                    font-size: 2.4rem;
                    font-weight: bold;
                }
                &_text {
                    font-size: 2rem;
                    line-height: 2;
                    margin-top: 15px;
                    text-align: justify;
                }
            }
            &--02 {
                margin-top: 70px;
                &_text {
                    font-size: 2.8rem;
                    line-height: 1.8;
                    font-weight: bold;
                    text-align: center;
                    .marker {
                        background: linear-gradient(transparent 50%, #eaff80 0);
                    }
                }
            }
        }
        .balloon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 644px;
            height: 74px;
            background: #fff;
            border: solid 3px #0b4271;
            border-radius: 8px;
            margin-top: 50px;
            &_text {
                font-size: 2.6rem;
                .strong {
                    font-size: 3.4rem;
                    font-weight: bold;
                    color: #e6001c;
                    .mini {
                        font-size: 2.6rem;
                    }
                }
            }
            position: relative;
            @include triangle();
        }
        .link {
            display: flex;
            justify-content: center;
            margin-top: 50px;
            &_btn {
                @include btn-detail();
            }
        }
    }
}
.box-frame {
    border: solid 2px #369;
    display: flex;
    max-width: 840px;
    margin: auto;
    img {
        width: 50%;
    }
}

.column-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    ul {
        width: calc(33% - 10px);
        &.list-robust {
            li {
                background-color: $smoke-skyblue;
                border-radius: 4px;
                border: solid 1px darken($smoke-skyblue, 10%);
            }
        }
        &.list-performance {
            li {
                background-color: $af-lite-green;
                border-radius: 4px;
                border: solid 1px darken($af-lite-green, 10%);
            }
        }
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            font-size: 1.2rem;
            line-height: 1.6rem;
            text-align: center;
            margin: 1rem auto;
            padding: 0.5rem;
            width: 100%;
            background-color: #e7e7e7;
        }
    }
}
.caption-spray {
    border: solid 1px $af-blue;
    font-size: 1.4rem;
    padding: 1.4rem;
    border-radius: 8px;
    margin-top: 1rem;
    li {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.4rem;
        color: $af-blue;
    }
}
.info-emphasis {
    display: flex;
    align-items: center;
    width: 100%;
    p {
        font-size: 1.6rem;
        line-height: 2.56rem;
        text-align: left;
        display: block;
        margin: auto;
        padding: 2rem;
    }
}
// ****************************************
// SPL Layout
// ****************************************
@media screen and (min-width: 600px) and (max-width: 768px) {
    // common
    .row-box--between {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .row-box--stretch {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .row-box--spec {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            align-items: center;
        }
    }

    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4rem;
        width: 100%;
        &.emphasis {
            h2 {
                text-align: center;
                font-size: 1.6rem;
                line-height: 2.56rem;
                margin-bottom: 2rem;
                letter-spacing: 0.1rem;
            }
        }
        h2 {
            text-align: center;
            font-size: 2.4rem;
            line-height: 3.84rem;
            margin-bottom: 2rem;
            padding-bottom: 0.5rem;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                right: 0;
                bottom: -10px;
                left: 0;
                width: 0px;
                height: 0px;
                margin: auto;
                border-style: solid;
                border-color: $af-blue transparent transparent transparent;
                border-width: 15px 15px 0 15px;
            }
            &.quality {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 120px;
                background-image: url('../static/img/bgimg_quality-sp.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }
        h3 {
            font-size: 1.6rem;
            line-height: 2.56rem;
            text-align: center;
            margin: auto;
            &.table-title {
                font-size: 1.6rem;
            }
        }
        h4 {
            font-size: 1.4rem;
            line-height: 2.24rem;
            margin: auto;
            text-align: center;
            text-align: justify;
            text-justify: inter-ideograph;
        }
        &_section {
            margin: 0 auto 2rem;
            text-align: center;
            h2 {
                display: inline;
                border-bottom: solid 2px $af-yellowgreen;
                text-align: center;
                font-size: 1.6rem;
                padding-bottom: 0.5rem;
            }
        }
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .lead {
        margin-top: 35px;
        &_text {
            text-align: center;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;
        }
    }
    .table-spec {
        background-color: $mid-gray;
        width: 100%;
        border-collapse: separate;
        border-spacing: 1px;
        th {
            color: $white;
            background-color: $smoke-blue;
            width: 20%;
            line-height: 2.56rem;
            font-size: 1.6rem;
            font-weight: normal;
            padding: 1.6rem;
            &.af {
                background-color: darken($smoke-blue, 0.1);
                font-weight: bold;
            }
            &.other {
                background-color: darken($mid-gray, 0.1);
            }
        }
        td {
            font-size: 1.6rem;
            word-wrap: break-word;
            line-height: 2.56rem;
            padding: 1.6rem;
            &:first-child {
                text-align: right;
            }
        }
        tr:nth-child(odd) {
            td:first-child {
                background-color: $smoke-skyblue;
            }
            td:nth-child(3) {
                background-color: $lite-gray;
            }
        }
        tr:nth-child(even) {
            td:first-child {
                background-color: darken($smoke-skyblue, 5%);
            }
            td:nth-child(3) {
                background-color: darken($lite-gray, 5%);
            }
        }
    }
    .table-spec--min {
        background-color: $mid-gray;
        width: 100%;
        border-collapse: separate;
        border-spacing: 1px;
        th {
            color: $white;
            background-color: $smoke-blue;
            width: 30%;
            line-height: 2.56rem;
            padding: 1.6rem;
            font-size: 1.6rem;
            font-weight: normal;
            &.af {
                background-color: darken($smoke-blue, 0.1);
                font-weight: bold;
            }
            &.other {
                background-color: darken($mid-gray, 0.1);
            }
        }
        td {
            font-size: 1.6rem;
            word-wrap: break-word;
            line-height: 2.56rem;
            padding: 1.6rem;
            &:first-child {
                text-align: right;
            }
        }
        tr:nth-child(odd) {
            td:first-child {
                background-color: $smoke-skyblue;
            }
            td:nth-child(2) {
                background-color: $lite-gray;
            }
        }
        tr:nth-child(even) {
            td:first-child {
                background-color: darken($smoke-skyblue, 5%);
            }
            td:nth-child(2) {
                background-color: darken($lite-gray, 5%);
            }
        }
    }
    // contents
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1282px;
        padding: 40px 20px 40px;
        background: #fff;
        margin: auto;
    }
    .content-box {
        // 仕様諸元＆他機種との比較 ********************
        &--01 {
            padding: 0 20px;
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 35px;
                    &_photo {
                        width: 720px;
                        height: 440px;
                        background: #333;
                    }
                }
                &--02 {
                    &_photo {
                        width: 80%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .feature {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 50px;
                .caption {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 600px;
                    margin-left: 50px;
                    &_title {
                        margin-top: 25px;
                        width: 320px;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        &-sub {
                            width: 650px;
                            p {
                                font-size: 2.8rem;
                                font-weight: bold;
                                margin-top: 0;
                            }
                        }
                    }
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.6;
                        margin-top: 25px;
                    }
                    .link {
                        margin-top: 25px;
                        &_btn {
                            @include btn-detail();
                        }
                    }
                }
            }
        }
        // 図面 ********************
        &--02 {
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        width: 80%;
                        height: auto;
                        display: block;
                        margin: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                &--02 {
                    margin-top: 20px;
                    &_img {
                        display: block;
                        width: 80%;
                        height: auto;
                        margin: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .feature {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 40px;
                .col-box {
                    justify-content: space-between;
                    width: 400px;
                    height: 412px;
                    background: #fff;
                    border: solid 1px #b1d2e7;
                    padding: 0 10px 10px;
                    margin-left: 40px;
                    &:first-child {
                        margin-left: 0;
                    }
                    .caption {
                        &_text {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 132px;
                            font-size: 2rem;
                            font-weight: bold;
                            line-height: 1.6;
                            text-align: center;
                        }
                    }
                }
            }
            .link {
                display: flex;
                justify-content: center;
                margin-top: 50px;
                &_btn {
                    @include btn-detail();
                }
            }
        }
        // ロバスト性評価 ********************
        &--03 {
            padding: 0 20px;
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                &--02 {
                    margin-top: 80px;
                    &_img {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        object {
                            width: 100%;
                            display: block;
                            margin: auto;
                        }
                    }
                }
            }
            .caption {
                &--01 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 844px;
                    height: 298px;
                    background: #f7f7e8;
                    border: solid 2px #82c580;
                    border-radius: 12px;
                    padding: 0 30px;
                    margin-top: 50px;
                    &_title {
                        font-size: 2.4rem;
                        font-weight: bold;
                    }
                    &_text {
                        font-size: 2rem;
                        line-height: 2;
                        margin-top: 15px;
                        text-align: justify;
                    }
                }
                &--02 {
                    margin-top: 70px;
                    &_text {
                        font-size: 2.8rem;
                        line-height: 1.8;
                        font-weight: bold;
                        text-align: center;
                        .marker {
                            background: linear-gradient(transparent 50%, #eaff80 0);
                        }
                    }
                }
            }
            .balloon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 644px;
                height: 74px;
                background: #fff;
                border: solid 3px #0b4271;
                border-radius: 8px;
                margin-top: 50px;
                &_text {
                    font-size: 2.6rem;
                    .strong {
                        font-size: 3.4rem;
                        font-weight: bold;
                        color: #e6001c;
                        .mini {
                            font-size: 2.6rem;
                        }
                    }
                }
                position: relative;
                @include triangle();
            }
            .link {
                display: flex;
                justify-content: center;
                margin-top: 50px;
                &_btn {
                    @include btn-detail();
                }
            }
        }
        // 農薬散布性能 ********************
        &--04 {
            padding: 0 20px;
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        object {
                            width: 100%;
                        }
                    }
                }
                &--02 {
                    width: 80%;
                    &_img {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        object {
                            width: 100%;
                            display: block;
                            margin: auto;
                        }
                    }
                }
            }
            .caption {
                &--01 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 844px;
                    height: 298px;
                    background: #f7f7e8;
                    border: solid 2px #82c580;
                    border-radius: 12px;
                    padding: 0 30px;
                    margin-top: 50px;
                    &_title {
                        font-size: 2.4rem;
                        font-weight: bold;
                    }
                    &_text {
                        font-size: 2rem;
                        line-height: 2;
                        margin-top: 15px;
                        text-align: justify;
                    }
                }
                &--02 {
                    margin-top: 70px;
                    &_text {
                        font-size: 2.8rem;
                        line-height: 1.8;
                        font-weight: bold;
                        text-align: center;
                        .marker {
                            background: linear-gradient(transparent 50%, #eaff80 0);
                        }
                    }
                }
            }
            .balloon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 644px;
                height: 74px;
                background: #fff;
                border: solid 3px #0b4271;
                border-radius: 8px;
                margin-top: 50px;
                &_text {
                    font-size: 2.6rem;
                    .strong {
                        font-size: 3.4rem;
                        font-weight: bold;
                        color: #e6001c;
                        .mini {
                            font-size: 2.6rem;
                        }
                    }
                }
                position: relative;
                @include triangle();
            }
            .link {
                display: flex;
                justify-content: center;
                margin-top: 50px;
                &_btn {
                    @include btn-detail();
                }
            }
        }
    }
    .box-frame {
        border: solid 2px #369;
        display: flex;
        max-width: 840px;
        margin: auto;
        img {
            width: 50%;
        }
    }

    .column-list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        ul {
            width: calc(33% - 10px);
            &.list-robust {
                li {
                    background-color: $smoke-skyblue;
                    border-radius: 4px;
                    border: solid 1px darken($smoke-skyblue, 10%);
                }
            }
            &.list-performance {
                li {
                    background-color: $af-lite-green;
                    border-radius: 4px;
                    border: solid 1px darken($af-lite-green, 10%);
                }
            }
            li {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                font-size: 1.6rem;
                font-weight: bold;
                line-height: 2.56rem;
                text-align: center;
                margin: 1rem auto;
                padding: 0.5rem;
                width: 100%;
                background-color: #e7e7e7;
            }
        }
    }
    .caption-spray {
        border: solid 1px $af-blue;
        font-size: 1.6rem;
        padding: 1.6rem;
        border-radius: 8px;
        margin-top: 3rem;
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3.2rem;
            color: $af-blue;
        }
    }
    .info-emphasis {
        display: flex;
        align-items: center;
        width: 100%;
        p {
            font-size: 2.4rem;
            line-height: 3.84rem;
            text-align: center;
            display: block;
            margin: 4rem auto;
            padding: 2rem;
        }
    }
}
// ****************************************
// TB Layout
// ****************************************
@media screen and (min-width: 769px) and (max-width: 1024px) {
    // common
    .row-box--between {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .row-box--stretch {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .row-box--spec {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            align-items: center;
        }
    }

    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4rem;
        width: 100%;
        &.emphasis {
            h2 {
                text-align: center;
                font-size: 1.6rem;
                line-height: 2.56rem;
                margin-bottom: 2rem;
                letter-spacing: 0.1rem;
            }
        }
        h2 {
            text-align: center;
            font-size: 2.4rem;
            line-height: 3.84rem;
            margin-bottom: 2rem;
            padding-bottom: 0.5rem;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                right: 0;
                bottom: -10px;
                left: 0;
                width: 0px;
                height: 0px;
                margin: auto;
                border-style: solid;
                border-color: $af-blue transparent transparent transparent;
                border-width: 15px 15px 0 15px;
            }
            &.quality {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 120px;
                background-image: url('../static/img/bgimg_quality-sp.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }
        h3 {
            font-size: 1.6rem;
            line-height: 2.56rem;
            text-align: center;
            margin: auto;
            &.table-title {
                font-size: 1.6rem;
            }
        }
        h4 {
            font-size: 1.4rem;
            line-height: 2.24rem;
            margin: auto;
            text-align: center;
            text-align: justify;
            text-justify: inter-ideograph;
        }
        &_section {
            margin: 0 auto 2rem;
            text-align: center;
            h2 {
                display: inline;
                border-bottom: solid 2px $af-yellowgreen;
                text-align: center;
                font-size: 1.6rem;
                padding-bottom: 0.5rem;
            }
        }
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .lead {
        margin-top: 35px;
        &_text {
            text-align: center;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;
        }
    }
    .table-spec {
        background-color: $mid-gray;
        width: 100%;
        border-collapse: separate;
        border-spacing: 1px;
        th {
            color: $white;
            background-color: $smoke-blue;
            width: 20%;
            line-height: 2.56rem;
            font-size: 1.6rem;
            font-weight: normal;
            padding: 1.6rem;
            &.af {
                background-color: darken($smoke-blue, 0.1);
                font-weight: bold;
            }
            &.other {
                background-color: darken($mid-gray, 0.1);
            }
        }
        td {
            font-size: 1.6rem;
            word-wrap: break-word;
            line-height: 2.56rem;
            padding: 1.6rem;
            &:first-child {
                text-align: right;
            }
        }
        tr:nth-child(odd) {
            td:first-child {
                background-color: $smoke-skyblue;
            }
            td:nth-child(3) {
                background-color: $lite-gray;
            }
        }
        tr:nth-child(even) {
            td:first-child {
                background-color: darken($smoke-skyblue, 5%);
            }
            td:nth-child(3) {
                background-color: darken($lite-gray, 5%);
            }
        }
    }
    .table-spec--min {
        background-color: $mid-gray;
        width: 100%;
        border-collapse: separate;
        border-spacing: 1px;
        th {
            color: $white;
            background-color: $smoke-blue;
            width: 30%;
            line-height: 2.56rem;
            padding: 1.6rem;
            font-size: 1.6rem;
            font-weight: normal;
            &.af {
                background-color: darken($smoke-blue, 0.1);
                font-weight: bold;
            }
            &.other {
                background-color: darken($mid-gray, 0.1);
            }
        }
        td {
            font-size: 1.6rem;
            word-wrap: break-word;
            line-height: 2.56rem;
            padding: 1.6rem;
            &:first-child {
                text-align: right;
            }
        }
        tr:nth-child(odd) {
            td:first-child {
                background-color: $smoke-skyblue;
            }
            td:nth-child(2) {
                background-color: $lite-gray;
            }
        }
        tr:nth-child(even) {
            td:first-child {
                background-color: darken($smoke-skyblue, 5%);
            }
            td:nth-child(2) {
                background-color: darken($lite-gray, 5%);
            }
        }
    }
    // contents
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1282px;
        padding: 40px 20px 40px;
        background: #fff;
        margin: auto;
    }
    .content-box {
        // 仕様諸元＆他機種との比較 ********************
        &--01 {
            padding: 0 20px;
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 35px;
                    &_photo {
                        width: 720px;
                        height: 440px;
                        background: #333;
                    }
                }
                &--02 {
                    &_photo {
                        width: 80%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .feature {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 50px;
                .caption {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 600px;
                    margin-left: 50px;
                    &_title {
                        margin-top: 25px;
                        width: 320px;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        &-sub {
                            width: 650px;
                            p {
                                font-size: 2.8rem;
                                font-weight: bold;
                                margin-top: 0;
                            }
                        }
                    }
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.6;
                        margin-top: 25px;
                    }
                    .link {
                        margin-top: 25px;
                        &_btn {
                            @include btn-detail();
                        }
                    }
                }
            }
        }
        // 図面 ********************
        &--02 {
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        width: 80%;
                        height: auto;
                        display: block;
                        margin: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                &--02 {
                    margin-top: 20px;
                    &_img {
                        display: block;
                        width: 80%;
                        height: auto;
                        margin: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .feature {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 40px;
                .col-box {
                    justify-content: space-between;
                    width: 400px;
                    height: 412px;
                    background: #fff;
                    border: solid 1px #b1d2e7;
                    padding: 0 10px 10px;
                    margin-left: 40px;
                    &:first-child {
                        margin-left: 0;
                    }
                    .caption {
                        &_text {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 132px;
                            font-size: 2rem;
                            font-weight: bold;
                            line-height: 1.6;
                            text-align: center;
                        }
                    }
                }
            }
            .link {
                display: flex;
                justify-content: center;
                margin-top: 50px;
                &_btn {
                    @include btn-detail();
                }
            }
        }
        // ロバスト性評価 ********************
        &--03 {
            padding: 0 20px;
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                &--02 {
                    margin-top: 80px;
                    &_img {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        object {
                            width: 100%;
                            display: block;
                            margin: auto;
                        }
                    }
                }
            }
            .caption {
                &--01 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 844px;
                    height: 298px;
                    background: #f7f7e8;
                    border: solid 2px #82c580;
                    border-radius: 12px;
                    padding: 0 30px;
                    margin-top: 50px;
                    &_title {
                        font-size: 2.4rem;
                        font-weight: bold;
                    }
                    &_text {
                        font-size: 2rem;
                        line-height: 2;
                        margin-top: 15px;
                        text-align: justify;
                    }
                }
                &--02 {
                    margin-top: 70px;
                    &_text {
                        font-size: 2.8rem;
                        line-height: 1.8;
                        font-weight: bold;
                        text-align: center;
                        .marker {
                            background: linear-gradient(transparent 50%, #eaff80 0);
                        }
                    }
                }
            }
            .balloon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 644px;
                height: 74px;
                background: #fff;
                border: solid 3px #0b4271;
                border-radius: 8px;
                margin-top: 50px;
                &_text {
                    font-size: 2.6rem;
                    .strong {
                        font-size: 3.4rem;
                        font-weight: bold;
                        color: #e6001c;
                        .mini {
                            font-size: 2.6rem;
                        }
                    }
                }
                position: relative;
                @include triangle();
            }
            .link {
                display: flex;
                justify-content: center;
                margin-top: 50px;
                &_btn {
                    @include btn-detail();
                }
            }
        }
        // 農薬散布性能 ********************
        &--04 {
            padding: 0 20px;
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        object {
                            width: 100%;
                        }
                    }
                }
                &--02 {
                    width: 80%;
                    &_img {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        object {
                            width: 100%;
                            display: block;
                            margin: auto;
                        }
                    }
                }
            }
            .caption {
                &--01 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 844px;
                    height: 298px;
                    background: #f7f7e8;
                    border: solid 2px #82c580;
                    border-radius: 12px;
                    padding: 0 30px;
                    margin-top: 50px;
                    &_title {
                        font-size: 2.4rem;
                        font-weight: bold;
                    }
                    &_text {
                        font-size: 2rem;
                        line-height: 2;
                        margin-top: 15px;
                        text-align: justify;
                    }
                }
                &--02 {
                    margin-top: 70px;
                    &_text {
                        font-size: 2.8rem;
                        line-height: 1.8;
                        font-weight: bold;
                        text-align: center;
                        .marker {
                            background: linear-gradient(transparent 50%, #eaff80 0);
                        }
                    }
                }
            }
            .balloon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 644px;
                height: 74px;
                background: #fff;
                border: solid 3px #0b4271;
                border-radius: 8px;
                margin-top: 50px;
                &_text {
                    font-size: 2.6rem;
                    .strong {
                        font-size: 3.4rem;
                        font-weight: bold;
                        color: #e6001c;
                        .mini {
                            font-size: 2.6rem;
                        }
                    }
                }
                position: relative;
                @include triangle();
            }
            .link {
                display: flex;
                justify-content: center;
                margin-top: 50px;
                &_btn {
                    @include btn-detail();
                }
            }
        }
    }
    .box-frame {
        border: solid 2px #369;
        display: flex;
        max-width: 840px;
        margin: auto;
        img {
            width: 50%;
        }
    }

    .column-list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        ul {
            width: calc(33% - 10px);
            &.list-robust {
                li {
                    background-color: $smoke-skyblue;
                    border-radius: 4px;
                    border: solid 1px darken($smoke-skyblue, 10%);
                }
            }
            &.list-performance {
                li {
                    background-color: $af-lite-green;
                    border-radius: 4px;
                    border: solid 1px darken($af-lite-green, 10%);
                }
            }
            li {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                font-size: 1.6rem;
                font-weight: bold;
                line-height: 2.56rem;
                text-align: center;
                margin: 1rem auto;
                padding: 0.5rem;
                width: 100%;
                background-color: #e7e7e7;
            }
        }
    }
    .caption-spray {
        border: solid 1px $af-blue;
        font-size: 1.6rem;
        padding: 1.6rem;
        border-radius: 8px;
        margin-top: 3rem;
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3.2rem;
            color: $af-blue;
        }
    }
    .info-emphasis {
        display: flex;
        align-items: center;
        width: 100%;
        p {
            font-size: 2.4rem;
            line-height: 3.84rem;
            text-align: center;
            display: block;
            margin: 4rem auto;
            padding: 2rem;
        }
    }
}
// ****************************************
// PC Layout
// ****************************************
@media screen and (min-width: 1025px) {
    // common
    .row-box--between {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .row-box--stretch {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .row-box--spec {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            align-items: center;
        }
    }

    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4rem;
        width: 100%;
        &.emphasis {
            h2 {
                text-align: center;
                font-size: 1.6rem;
                line-height: 2.56rem;
                margin-bottom: 2rem;
                letter-spacing: 0.1rem;
            }
        }
        h2 {
            text-align: center;
            font-size: 2.8rem;
            line-height: 4.48rem;
            margin-bottom: 2rem;
            padding-bottom: 0.5rem;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                right: 0;
                bottom: -10px;
                left: 0;
                width: 0px;
                height: 0px;
                margin: auto;
                border-style: solid;
                border-color: $af-blue transparent transparent transparent;
                border-width: 15px 15px 0 15px;
            }
            &.quality {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 120px;
                background-image: url('../static/img/bgimg_quality-sp.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }
        h3 {
            font-size: 1.6rem;
            line-height: 2.56rem;
            text-align: center;
            margin: auto;
            &.table-title {
                font-size: 1.6rem;
            }
        }
        h4 {
            font-size: 1.4rem;
            line-height: 2.24rem;
            margin: auto;
            text-align: center;
            text-align: justify;
            text-justify: inter-ideograph;
        }
        &_section {
            margin: 0 auto 2rem;
            text-align: center;
            h2 {
                display: inline;
                border-bottom: solid 2px $af-yellowgreen;
                text-align: center;
                font-size: 1.6rem;
                padding-bottom: 0.5rem;
            }
        }
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        &_title {
            font-size: 3.2rem;
            text-align: center;
            &-sub {
                font-size: 1.8rem;
                font-weight: normal;
                color: #007a0c;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .lead {
        margin-top: 35px;
        &_text {
            text-align: center;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;
        }
    }
    .table-spec {
        background-color: $mid-gray;
        width: 100%;
        border-collapse: separate;
        border-spacing: 1px;
        th {
            color: $white;
            background-color: $smoke-blue;
            width: 20%;
            line-height: 2.56rem;
            font-size: 1.6rem;
            font-weight: normal;
            padding: 1.6rem;
            &.af {
                background-color: darken($smoke-blue, 0.1);
                font-weight: bold;
            }
            &.other {
                background-color: darken($mid-gray, 0.1);
            }
        }
        td {
            font-size: 1.6rem;
            word-wrap: break-word;
            line-height: 2.56rem;
            padding: 1.6rem;
            &:first-child {
                text-align: right;
            }
        }
        tr:nth-child(odd) {
            td:first-child {
                background-color: $smoke-skyblue;
            }
            td:nth-child(3) {
                background-color: $lite-gray;
            }
        }
        tr:nth-child(even) {
            td:first-child {
                background-color: darken($smoke-skyblue, 5%);
            }
            td:nth-child(3) {
                background-color: darken($lite-gray, 5%);
            }
        }
    }
    .table-spec--min {
        background-color: $mid-gray;
        width: 100%;
        border-collapse: separate;
        border-spacing: 1px;
        th {
            color: $white;
            background-color: $smoke-blue;
            width: 30%;
            line-height: 2.56rem;
            padding: 1.6rem;
            font-size: 1.6rem;
            font-weight: normal;
            &.af {
                background-color: darken($smoke-blue, 0.1);
                font-weight: bold;
            }
            &.other {
                background-color: darken($mid-gray, 0.1);
            }
        }
        td {
            font-size: 1.6rem;
            word-wrap: break-word;
            line-height: 2.56rem;
            padding: 1.6rem;
            &:first-child {
                text-align: right;
            }
        }
        tr:nth-child(odd) {
            td:first-child {
                background-color: $smoke-skyblue;
            }
            td:nth-child(2) {
                background-color: $lite-gray;
            }
        }
        tr:nth-child(even) {
            td:first-child {
                background-color: darken($smoke-skyblue, 5%);
            }
            td:nth-child(2) {
                background-color: darken($lite-gray, 5%);
            }
        }
    }
    // contents
    .content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1280px;
        padding: 40px 20px 40px;
        background: #fff;
        margin: auto;
    }
    .content-box {
        // 仕様諸元＆他機種との比較 ********************
        &--01 {
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 35px;
                    &_photo {
                        width: 720px;
                        height: 440px;
                        background: #333;
                    }
                }
                &--02 {
                    &_photo {
                        width: 80%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .feature {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 50px;
                .caption {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 600px;
                    margin-left: 50px;
                    &_title {
                        margin-top: 25px;
                        width: 320px;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        &-sub {
                            width: 650px;
                            p {
                                font-size: 2.8rem;
                                font-weight: bold;
                                margin-top: 0;
                            }
                        }
                    }
                    &_text {
                        font-size: 1.6rem;
                        line-height: 1.6;
                        margin-top: 25px;
                    }
                    .link {
                        margin-top: 25px;
                        &_btn {
                            @include btn-detail();
                        }
                    }
                }
            }
        }
        // 図面 ********************
        &--02 {
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        width: 80%;
                        height: auto;
                        display: block;
                        margin: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                &--02 {
                    margin-top: 20px;
                    &_img {
                        display: block;
                        width: 80%;
                        height: auto;
                        margin: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .feature {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 40px;
                .col-box {
                    justify-content: space-between;
                    width: 400px;
                    height: 412px;
                    background: #fff;
                    border: solid 1px #b1d2e7;
                    padding: 0 10px 10px;
                    margin-left: 40px;
                    &:first-child {
                        margin-left: 0;
                    }
                    .caption {
                        &_text {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 132px;
                            font-size: 2rem;
                            font-weight: bold;
                            line-height: 1.6;
                            text-align: center;
                        }
                    }
                }
            }
            .link {
                display: flex;
                justify-content: center;
                margin-top: 50px;
                &_btn {
                    @include btn-detail();
                }
            }
        }
        // ロバスト性評価 ********************
        &--03 {
            padding: 0 20px;
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                &--02 {
                    margin-top: 80px;
                    &_img {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        object {
                            width: 100%;
                            display: block;
                            margin: auto;
                        }
                    }
                }
            }
            .caption {
                &--01 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 844px;
                    height: 298px;
                    background: #f7f7e8;
                    border: solid 2px #82c580;
                    border-radius: 12px;
                    padding: 0 30px;
                    margin-top: 50px;
                    &_title {
                        font-size: 2.4rem;
                        font-weight: bold;
                    }
                    &_text {
                        font-size: 2rem;
                        line-height: 2;
                        margin-top: 15px;
                        text-align: justify;
                    }
                }
                &--02 {
                    margin-top: 70px;
                    &_text {
                        font-size: 2.8rem;
                        line-height: 1.8;
                        font-weight: bold;
                        text-align: center;
                        .marker {
                            background: linear-gradient(transparent 50%, #eaff80 0);
                        }
                    }
                }
            }
            .balloon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 644px;
                height: 74px;
                background: #fff;
                border: solid 3px #0b4271;
                border-radius: 8px;
                margin-top: 50px;
                &_text {
                    font-size: 2.6rem;
                    .strong {
                        font-size: 3.4rem;
                        font-weight: bold;
                        color: #e6001c;
                        .mini {
                            font-size: 2.6rem;
                        }
                    }
                }
                position: relative;
                @include triangle();
            }
            .link {
                display: flex;
                justify-content: center;
                margin-top: 50px;
                &_btn {
                    @include btn-detail();
                }
            }
        }
        // 農薬散布性能 ********************
        &--04 {
            padding: 0 20px;
            .images {
                &--01 {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    &_img {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        object {
                            width: 100%;
                        }
                    }
                }
                &--02 {
                    width: 850px;
                    &_img {
                        width: 100%;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        object {
                            width: 100%;
                            display: block;
                            margin: auto;
                        }
                    }
                }
            }
            .caption {
                &--01 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 844px;
                    height: 298px;
                    background: #f7f7e8;
                    border: solid 2px #82c580;
                    border-radius: 12px;
                    padding: 0 30px;
                    margin-top: 50px;
                    &_title {
                        font-size: 2.4rem;
                        font-weight: bold;
                    }
                    &_text {
                        font-size: 2rem;
                        line-height: 2;
                        margin-top: 15px;
                        text-align: justify;
                    }
                }
                &--02 {
                    margin-top: 70px;
                    &_text {
                        font-size: 2.8rem;
                        line-height: 1.8;
                        font-weight: bold;
                        text-align: center;
                        .marker {
                            background: linear-gradient(transparent 50%, #eaff80 0);
                        }
                    }
                }
            }
            .balloon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 644px;
                height: 74px;
                background: #fff;
                border: solid 3px #0b4271;
                border-radius: 8px;
                margin-top: 50px;
                &_text {
                    font-size: 2.6rem;
                    .strong {
                        font-size: 3.4rem;
                        font-weight: bold;
                        color: #e6001c;
                        .mini {
                            font-size: 2.6rem;
                        }
                    }
                }
                position: relative;
                @include triangle();
            }
            .link {
                display: flex;
                justify-content: center;
                margin-top: 50px;
                &_btn {
                    @include btn-detail();
                }
            }
        }
    }
    .box-frame {
        border: solid 2px #369;
        display: flex;
        max-width: 840px;
        margin: auto;
        img {
            width: 50%;
        }
    }

    .column-list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        ul {
            width: calc(33% - 10px);
            &.list-robust {
                li {
                    background-color: $smoke-skyblue;
                    border-radius: 4px;
                    border: solid 1px darken($smoke-skyblue, 10%);
                }
            }
            &.list-performance {
                li {
                    background-color: $af-lite-green;
                    border-radius: 4px;
                    border: solid 1px darken($af-lite-green, 10%);
                }
            }
            li {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                font-size: 1.6rem;
                font-weight: bold;
                line-height: 2.56rem;
                text-align: center;
                margin: 1rem auto;
                padding: 0.5rem;
                width: 100%;
                background-color: #e7e7e7;
            }
        }
    }
    .caption-spray {
        border: solid 1px $af-blue;
        font-size: 1.6rem;
        padding: 1.6rem;
        border-radius: 8px;
        margin-top: 3rem;
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3.2rem;
            color: $af-blue;
        }
    }
    .info-emphasis {
        display: flex;
        align-items: center;
        width: 100%;
        p {
            font-size: 3.2rem;
            line-height: 5.12rem;
            text-align: center;
            display: block;
            margin: 4rem auto;
            padding: 2rem;
        }
    }
}
</style>
