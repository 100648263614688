var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "section",
      {
        staticClass: "content-box content-box--01",
        attrs: { id: "section-01" }
      },
      [
        _c("div", { staticClass: "content-box--01_inner" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "lead" }, [
            _c("p", { staticClass: "lead_text" }, [
              _vm._v("\n                        自動車開発技術から生まれた、"),
              _vm.$mq === "sp" || _vm.$mq === "spl" || _vm.$mq === "tb"
                ? _c("br")
                : _vm._e(),
              _vm._v(
                "\n                        高性能、高耐久性の農薬散布用ドローンです！\n                    "
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "area-compvideo" },
            [_c("CompVideo", { attrs: { videopath: "" } })],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "feature" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "caption" }, [
              _c("div", { staticClass: "caption_title-sub" }, [
                _c("p", [
                  _vm._v(
                    "\n                                IER農業用マルチコプター"
                  ),
                  _vm.$mq === "sp" ? _c("br") : _vm._e(),
                  _vm._v("「アグリフライヤー」\n                            ")
                ])
              ]),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "caption_text" }, [
                _c("p", [
                  _vm._v(
                    "\n                                時間と労力のかかる農薬散布作業。"
                  ),
                  _vm.$mq === "pc" ? _c("br") : _vm._e(),
                  _vm._v(
                    "\n                                日本の農業では、高齢化に伴いこれからの作業の省力化が急務となっています。"
                  ),
                  _vm.$mq === "pc" ? _c("br") : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$mq === "pc",
                          expression: "$mq === 'pc'"
                        }
                      ]
                    },
                    [
                      _vm._v(
                        "\n                                    最新のドローンを農薬散布に活用することで圧倒的な省力化を実現可能。"
                      ),
                      _vm.$mq === "pc" ? _c("br") : _vm._e(),
                      _vm._v(
                        "\n                                    IERのAgri-Flyerなら、1ヘクタールの散布をわずか15分で行うことができます。\n                                "
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "link" },
                [
                  _c(
                    "router-link",
                    { staticClass: "link_btn", attrs: { to: "/feature" } },
                    [_vm._v("Agri Flyerの特長")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      {
        staticClass: "content-box content-box--02",
        attrs: { id: "section-02" }
      },
      [
        _c("div", { staticClass: "content-box--02_inner" }, [
          _vm._m(4),
          _vm._v(" "),
          _c("div", { staticClass: "lead" }, [
            _c("p", { staticClass: "lead_text" }, [
              _vm._v(
                "\n                        現場での困りごとを解決する機能を網羅、"
              ),
              _vm.$mq === "sp" || _vm.$mq === "spl" || _vm.$mq === "tb"
                ? _c("br")
                : _vm._e(),
              _vm._v(
                "\n                        しかも安価で高いパフォーマンスを実現！\n                    "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "feature" }, [
            _c("div", { staticClass: "col-box" }, [
              _c("p", { staticClass: "caption_text" }, [
                _vm._v(
                  "\n                            簡単折りたたみ機構でコンパクトに"
                ),
                _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
                _vm._v(
                  "\n                            現場では工具不要で飛行状態に\n                        "
                )
              ]),
              _vm._v(" "),
              _vm._m(5)
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-box" }, [
              _c("p", { staticClass: "caption_text" }, [
                _vm._v(
                  "\n                            重心変化を抑え安定した飛行を実現、"
                ),
                _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
                _vm._v(
                  "\n                            タンク下へのポンプの配置で"
                ),
                _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
                _vm._v(
                  "\n                            薬剤を最後まで使い切ることが可能\n                        "
                )
              ]),
              _vm._v(" "),
              _vm._m(6)
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-box" }, [
              _c("p", { staticClass: "caption_text" }, [
                _vm._v(
                  "\n                            樹脂製に比べ、紫外線劣化の少ない"
                ),
                _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
                _vm._v(
                  "\n                            マグネシウム合金製ボディを採用"
                ),
                _vm.$mq === "sp" || _vm.$mq === "spl" ? _c("br") : _vm._e(),
                _vm._v(
                  "\n                            農業用としての高い耐久性を実現\n                        "
                )
              ]),
              _vm._v(" "),
              _vm._m(7)
            ])
          ]),
          _vm._v(" "),
          _vm.$mq === "sp" || _vm.$mq === "spl"
            ? _c("div", { staticClass: "graph" }, [_vm._m(8)])
            : _vm._e(),
          _vm._v(" "),
          _vm.$mq === "tb" || _vm.$mq === "pc"
            ? _c("div", { staticClass: "graph" }, [_vm._m(9)])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "link" },
            [
              _c(
                "router-link",
                { staticClass: "link_btn", attrs: { to: "/feature" } },
                [_vm._v("Agri Flyerの特長")]
              )
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      {
        staticClass: "content-box content-box--03",
        attrs: { id: "section-03" }
      },
      [
        _c("div", { staticClass: "content-box--03_inner" }, [
          _vm._m(10),
          _vm._v(" "),
          _c("div", { staticClass: "lead" }, [
            _c("p", { staticClass: "lead_text" }, [
              _vm._v("\n                        スマート農業 "),
              _vm.$mq === "spl" ? _c("br") : _vm._e(),
              _vm._v("＝ 「先端技術」 × 「農業技術」\n                    ")
            ])
          ]),
          _vm._v(" "),
          _vm.$mq === "pc"
            ? _c("div", { staticClass: "images--01" }, [_vm._m(11)])
            : _vm._e(),
          _vm._v(" "),
          _vm.$mq === "sp"
            ? _c("div", { staticClass: "images--01" }, [_vm._m(12)])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(13),
          _vm._v(" "),
          _vm.$mq === "tb" || _vm.$mq === "pc"
            ? _c("div", { staticClass: "images--02" }, [_vm._m(14)])
            : _vm._e(),
          _vm._v(" "),
          _vm.$mq === "sp" || _vm.$mq === "spl"
            ? _c("div", { staticClass: "images--02" }, [_vm._m(15)])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "caption--02" }, [
            _c("p", { staticClass: "caption--02_text" }, [
              _c("span", { staticClass: "marker" }, [
                _vm._v("5年後には、ドローンによる空散が"),
                _vm.$mq === "sp" || _vm.$mq === "spl" || _vm.$mq === "tb"
                  ? _c("br")
                  : _vm._e(),
                _vm._v("50%のシェアとなることが予想されるなど、"),
                _c("br"),
                _vm._v(
                  "\n                            現在、もっとも期待されている"
                ),
                _vm.$mq === "sp" || _vm.$mq === "spl" || _vm.$mq === "tb"
                  ? _c("br")
                  : _vm._e(),
                _vm._v("ソリューションのひとつになっています。")
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "link" },
            [
              _c(
                "router-link",
                { staticClass: "link_btn", attrs: { to: "/smart" } },
                [_vm._v("スマート農業をくわしく")]
              )
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", { staticClass: "banner-sp" }, [
      _c("a", { attrs: { href: "https://agri-flyer-r.ier-prod.jp/" } }, [
        _c("img", {
          attrs: {
            src: "static/img/typer-banner-sp.png",
            alt: "知性を備えたニューエディション “type-R” 誕生"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h2", { staticClass: "heading_title" }, [_vm._v("どんなマシン？")]),
      _vm._v(" "),
      _c("h3", { staticClass: "heading_title-sub" }, [
        _vm._v("What's Agri Flyer?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images" }, [
      _c("div", { staticClass: "images_img" }, [
        _c("img", {
          attrs: {
            src: "static/img/img_af-std.png",
            alt: "Agri-Flyer：イラスト"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "caption_title" }, [
      _c("object", {
        attrs: { type: "image/svg+xml", data: "static/img/img_aflogo-min.svg" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h2", { staticClass: "heading_title" }, [_vm._v("どこがすごいの？")]),
      _vm._v(" "),
      _c("h3", { staticClass: "heading_title-sub" }, [
        _vm._v("What's amazing?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images_img" }, [
      _c("img", {
        attrs: {
          src: "static/img/img_column-transform.jpg",
          alt: "Agri-Flyer：折りたたみ"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images_img" }, [
      _c("img", {
        attrs: {
          src: "static/img/img_column-tank.png",
          alt: "Agri-Flyer：ポンプ"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images_img" }, [
      _c("img", {
        attrs: {
          src: "static/img/img_column-magnesium.png",
          alt: "Agri-Flyer：マグネシウムボディ"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images" }, [
      _c("div", { staticClass: "images_img" }, [
        _c("object", {
          attrs: {
            type: "image/svg+xml",
            data: "static/img/chart_runningcost-sp.svg"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images" }, [
      _c("div", { staticClass: "images_img" }, [
        _c("object", {
          attrs: {
            type: "image/svg+xml",
            data: "static/img/chart_runningcost-wide.svg"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h2", { staticClass: "heading_title" }, [
        _vm._v("スマート農業って？")
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "heading_title-sub" }, [
        _vm._v("What's Smart Farm?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--01_img" }, [
      _c("object", { attrs: { data: "static/img/chart_smartfarm-wide.svg" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--01_img" }, [
      _c("object", { attrs: { data: "static/img/chart_sfarm-vertical.svg" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "caption--01" }, [
      _c("p", { staticClass: "caption--01_title" }, [
        _vm._v(
          "\n                        「スマート農業」とは\n                    "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "caption--01_text" }, [
        _vm._v(
          "\n                        最先端のロボット技術や人工知能（AI）を活用した、新しい時代の農業のスタイルです。農家の高齢化や担い手不足が深刻な問題となる中、政府でも2019年のり、国内50箇所のモデル農場で、自動運転トラクターを使っての農地開拓の実証実験に乗り出すなど、実用に向けた動きが加速しています。そのひとつ、ドローンを利用した農薬散布が、非常に注目されています。\n                    "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--02_img" }, [
      _c("object", { attrs: { data: "static/img/chart_5years.svg" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "images--02_img" }, [
      _c("object", { attrs: { data: "static/img/chart_5years-vertical.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }